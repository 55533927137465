import { Button } from 'antd';
import i18n from 'i18n';
import { OmsOrderDetail } from 'types/oms';
import { SHOP_MAP } from 'commons/options';
import printOrderReceipt from 'utils/print-order-receipt';
import connectToPosPrinter from 'utils/connect-to-pos-printer';
import usePosPrinter from 'commons/hooks/use-pos-printer';

const PrintReceiptButton = ({
  omsOrderDetail,
  onPrintReceiptByBrowser,
}: {
  omsOrderDetail: OmsOrderDetail;
  onPrintReceiptByBrowser: () => void;
}) => {
  const { createdFrom } = omsOrderDetail;
  const { posPrinterInfo } = usePosPrinter();

  // 目前只开发了日本银座店的小票功能
  if (createdFrom !== SHOP_MAP.GINZA) {
    return null;
  }

  return (
    <Button
      loading={posPrinterInfo.status === 'connecting'}
      type="primary"
      onClick={async () => {
        try {
          await connectToPosPrinter();
          printOrderReceipt({ orderId: omsOrderDetail.id });
        } catch (error) {
          onPrintReceiptByBrowser();
        }
      }}
    >
      {i18n.t('print_receipt')}
    </Button>
  );
};

export default PrintReceiptButton;
