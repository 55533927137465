import {
  Table,
  Form,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  Card,
  Radio,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getDailyStatistics } from 'apis/report';
import LOCALS from 'commons/locals';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useToggle } from 'react-use';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import {
  DailyStatisticsType,
  OrderStatisticsType,
  PurchaseReportType,
  ConsignmentReportType,
} from 'types/report';
import i18n from 'i18n';
import findCascaderOptionById from 'utils/findCascaderOptionById';
import { PaymentMethod } from 'constants/RecyclingConsignment';
import { thousands } from 'utils/tools';
import { SYS_USER_SHOP_OPTION_LIST } from 'commons/options';
import classNames from 'classnames';

const Statement = () => {
  const [form] = Form.useForm<{
    date: string;
    createdFrom: number;
  }>();
  const [dailyData, setDailyData] = useState<DailyStatisticsType>();
  const [loading, toggleLoading] = useToggle(false);
  const { staffSelectOptions, productCategoryCascaderOptions } =
    useAppSelector(selectGlobalInfo);

  const renderStatistics = useCallback(
    (items: { name: JSX.Element; value: number }[]) => {
      const md_clos = items.length;
      console.log('🚀  renderStatistics  md_clos:', md_clos);
      return (
        <div className="mb-4 mt-4">
          <Row gutter={[16, 16]} className="flex items-center">
            <Col flex="auto">
              <Card
                bordered={false}
                size="small"
                bodyStyle={{ padding: '12px' }}
              >
                <div
                  className={classNames(
                    `w-full grid gap-2 grid-cols-${md_clos}`,
                  )}
                  style={{
                    gridTemplateColumns: `repeat(${md_clos}, minmax(0, 1fr))`,
                  }}
                >
                  {items.map((d, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center flex-col"
                    >
                      <div className="text-sm text-gray-500">{d.name}</div>
                      <div className="text-base font-medium">
                        {thousands(d.value)}
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      );
    },
    [],
  );
  const salesStats = useMemo(() => {
    const summary = dailyData?.orderStatistics?.summary;
    if (!summary) return null;

    const items = [
      {
        name: <Trans i18nKey={LOCALS.total_customers} />,
        value: summary.totalCustomers,
      },
      {
        name: <Trans i18nKey={LOCALS.total_products} />,
        value: summary.totalProducts,
      },
      {
        name: <Trans i18nKey={LOCALS.total_sales_quantity} />,
        value: summary.totalSalesQuantity,
      },
      {
        name: <Trans i18nKey={LOCALS.total_pay_amount_statistics} />,
        value: summary.totalPayAmount,
      },
      {
        name: <Trans i18nKey={LOCALS.total_order_amount} />,
        value: summary.totalOrderAmount,
      },
      {
        name: <Trans i18nKey={LOCALS.total_discount_amount} />,
        value: summary.totalDiscountAmount,
      },
      {
        name: <Trans i18nKey={LOCALS.total_tax_amount} />,
        value: summary.totalTaxAmount,
      },
    ];

    return renderStatistics(items);
  }, [dailyData?.orderStatistics?.summary, renderStatistics]);

  const purchaseStats = useMemo(() => {
    const statistics = dailyData?.purchaseReport?.statistics;
    if (!statistics) return null;
    const items = [
      {
        name: <Trans i18nKey={LOCALS.purchase_total_purchase_quantity} />,
        value: statistics.totalPurchaseQuantity,
      },
      {
        name: <Trans i18nKey={LOCALS.purchase_total_cost_price} />,
        value: statistics.totalCostPrice,
      },
    ];

    return renderStatistics(items);
  }, [dailyData?.purchaseReport?.statistics, renderStatistics]);

  const consignmentStats = useMemo(() => {
    const statistics = dailyData?.consignmentReport?.statistics;
    if (!statistics) return null;
    const items = [
      {
        name: <Trans i18nKey={LOCALS.consignment_total_purchase_quantity} />,
        value: statistics.totalPurchaseQuantity,
      },
      {
        name: <Trans i18nKey={LOCALS.consignment_total_cost_price} />,
        value: statistics.totalCostPrice,
      },
      {
        name: <Trans i18nKey={LOCALS.consignment_total_price} />,
        value: statistics.totalPrice,
      },
    ];

    return renderStatistics(items);
  }, [dailyData?.consignmentReport?.statistics, renderStatistics]);

  const fetchData = useCallback(
    (date?: string, createdFrom?: number) => {
      const toDay = dayjs().startOf('day').format();
      toggleLoading(true);
      getDailyStatistics({ date: date || toDay, createdFrom })
        .then((res) => {
          setDailyData(res);
        })
        .finally(() => {
          toggleLoading(false);
        });
    },
    [toggleLoading],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const salesColumns: ColumnsType<OrderStatisticsType> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.sales_date} />,
        dataIndex: 'date',
        key: 'date',
        render: (date: OrderStatisticsType['date']) =>
          dayjs(date).format('YYYY-MM-DD'),
        width: 110,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_customer_id} />,
        dataIndex: 'memberId',
        key: 'memberId',
        render: (memberId: OrderStatisticsType['memberId']) => (
          <a href={`/ums/member-view/${memberId}`}>{memberId}</a>
        ),
        width: 80,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_product_id} />,
        dataIndex: 'productIds',
        key: 'productIds',
        render: (productIds: OrderStatisticsType['productIds']) => (
          <div className="space-x-2">
            {productIds?.map((id) => (
              <a href={`/pms/product-edit/${id}`} key={id}>
                {id}
              </a>
            ))}
          </div>
        ),
        width: 80,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_quantity_sold} />,
        dataIndex: 'salesQuantity',
        key: 'salesQuantity',
        width: 100,
      },
      {
        // Todo 字段暂无
        title: <Trans i18nKey={LOCALS.sales_unit_price} />,
        dataIndex: 'payAmount1',
        key: 'payAmount1',
        width: 80,
      },
      {
        title: <Trans i18nKey={LOCALS['sales_sales_amount_(subtotal)']} />,
        dataIndex: 'payAmountActualCurrency',
        key: 'payAmountActualCurrency',
        render: (
          payAmountActualCurrency: OrderStatisticsType['payAmountActualCurrency'],
        ) => thousands(payAmountActualCurrency),
        width: 150,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_discount_promotion} />,
        dataIndex: 'discountAmount',
        key: 'discountAmount',
        width: 160,
        render: (discountAmount: OrderStatisticsType['discountAmount']) =>
          thousands(discountAmount),
      },
      {
        title: <Trans i18nKey={LOCALS.gross_profit} />,
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        width: 160,
        render: (grossProfit: OrderStatisticsType['grossProfit']) =>
          thousands(grossProfit),
      },
      {
        title: <Trans i18nKey={LOCALS.sales_total} />,
        dataIndex: 'totalAmountActualCurrency',
        key: 'totalAmountActualCurrency',
        width: 110,
        render: (
          totalAmountActualCurrency: OrderStatisticsType['totalAmountActualCurrency'],
        ) => thousands(totalAmountActualCurrency),
      },
      {
        title: <Trans i18nKey={LOCALS.sales_tax} />,
        dataIndex: 'taxAmount',
        key: 'taxAmount',
        render: (taxAmount: OrderStatisticsType['taxAmount']) =>
          thousands(taxAmount),
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_payment_method} />,
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        render: (paymentMethod: OrderStatisticsType['paymentMethod']) =>
          paymentMethod.toString(),
        width: 110,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_responsible} />,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 110,
        render: (createdBy: OrderStatisticsType['createdBy']) =>
          staffSelectOptions.find((d) => d.value === createdBy)?.label,
      },
      {
        title: <Trans i18nKey={LOCALS.sales_notes} />,
        dataIndex: 'note',
        key: 'note',
        width: 110,
      },
    ];
  }, [staffSelectOptions]);

  const purchaseColumns: ColumnsType<PurchaseReportType> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.purchase_date} />,
        dataIndex: 'date',
        key: 'date',
        render: (date: PurchaseReportType['date']) =>
          dayjs(date).format('YYYY-MM-DD'),
        width: 110,
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_product_id} />,
        dataIndex: 'productId',
        key: 'productId',
        render: (productId: PurchaseReportType['productId']) => (
          <a href={`/pms/product-edit/${productId}`}>{productId}</a>
        ),
        width: 80,
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_quantity_purchased} />,
        dataIndex: 'purchaseQuantity',
        key: 'purchaseQuantity',
        width: 100,
      },
      {
        title: <Trans i18nKey={LOCALS['purchase_purchase_price_(unit)']} />,
        dataIndex: 'costPrice',
        key: 'costPrice',
        width: 100,
        render: (costPrice: PurchaseReportType['costPrice']) =>
          thousands(costPrice),
      },
      // {
      //   // Todo 字段暂无
      //   title: (
      //     <Trans i18nKey={LOCALS['purchase_purchase_amount_(subtotal)']} />
      //   ),
      //   dataIndex: 'payAmount22',
      //   key: 'payAmount22'
      // },
      {
        // Todo 字段暂无
        title: <Trans i18nKey={LOCALS.purchase_point} />,
        dataIndex: 'points',
        key: 'points',
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_supplier} />,
        dataIndex: 'supplierId',
        key: 'supplierId',
        width: 130,
        render: (supplierId: PurchaseReportType['supplierId']) => (
          <a href={`/ums/member-view/${supplierId}`}>{supplierId}</a>
        ),
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_category} />,
        dataIndex: 'categoryId',
        key: 'categoryId',
        width: 100,
        render: (categoryId: PurchaseReportType['categoryId']) => {
          const target = findCascaderOptionById(
            categoryId,
            productCategoryCascaderOptions,
          );
          return target?.label;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_payment_method} />,
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        width: 120,
        render: (paymentMethod: PurchaseReportType['paymentMethod']) =>
          PaymentMethod.find((d) => d.value === paymentMethod)?.label,
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_responsible} />,
        dataIndex: 'owner',
        key: 'owner',
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.purchase_notes} />,
        dataIndex: 'remark',
        key: 'remark',
        width: 120,
      },
    ];
  }, [productCategoryCascaderOptions]);

  const consignmentColumns: ColumnsType<ConsignmentReportType> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.consignment_date} />,
        dataIndex: 'date',
        key: 'date',
        render: (date: ConsignmentReportType['date']) =>
          dayjs(date).format('YYYY-MM-DD'),
        width: 120,
      },

      {
        title: <Trans i18nKey={LOCALS.consignment_product_id} />,
        dataIndex: 'productId',
        key: 'productId',
        render: (productId: ConsignmentReportType['productId']) => (
          <a href={`/pms/product-edit/${productId}`}>{productId}</a>
        ),
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_quantity_consigned} />,
        dataIndex: 'purchaseQuantity',
        key: 'purchaseQuantity',
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_consignor} />,
        dataIndex: 'consignor',
        key: 'consignor',
        width: 120,
        render: (consignor: ConsignmentReportType['consignor']) => (
          <a href={`/ums/member-view/${consignor}`}>{consignor}</a>
        ),
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_contract_price} />,
        dataIndex: 'costPrice',
        key: 'costPrice',
        render: (costPrice: ConsignmentReportType['costPrice']) =>
          thousands(costPrice),
        width: 120,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_selling_price} />,
        dataIndex: 'price',
        key: 'price',
        width: 120,
        render: (price: ConsignmentReportType['price']) => thousands(price),
      },
      {
        // Todo 字段暂无
        title: <Trans i18nKey={LOCALS.consignment_point} />,
        dataIndex: 'point',
        key: 'point',
        width: 100,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_sales_status} />,
        dataIndex: 'publishStatus',
        key: 'publishStatus',
        width: 100,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_payment_method} />,
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        width: 100,
        render: (paymentMethod: ConsignmentReportType['paymentMethod']) =>
          PaymentMethod.find((d) => d.value === paymentMethod)?.label,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_responsible} />,
        dataIndex: 'owner',
        key: 'owner',
        width: 100,
      },
      {
        title: <Trans i18nKey={LOCALS.consignment_notes} />,
        dataIndex: 'remark',
        key: 'remark',
        width: 100,
      },
    ];
  }, []);

  const onFinish = useCallback(async () => {
    const { date, createdFrom } = form.getFieldsValue();
    fetchData(dayjs(date).startOf('day').format(), createdFrom);
  }, [fetchData, form]);

  return (
    <div>
      <div className="w-full flex">
        <div className="">
          <Form
            form={form}
            layout={'inline'}
            onFinish={onFinish}
            initialValues={{ date: dayjs().startOf('day') }}
          >
            <Form.Item name="date" label={<Trans i18nKey={LOCALS.date} />}>
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="createdFrom"
              label={<Trans i18nKey={LOCALS.date} />}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={SYS_USER_SHOP_OPTION_LIST}
              ></Radio.Group>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  <Trans i18nKey={LOCALS.search} />
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.resetFields();
                    fetchData();
                  }}
                >
                  <Trans i18nKey={LOCALS.reset} />
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="mb-8">
        <div className="text-xl">{i18n.t('sales_daily_report')}</div>
        <Table
          loading={loading}
          pagination={false}
          columns={salesColumns}
          dataSource={dailyData?.orderStatistics?.orders}
          rowKey={(_, index) => index || 0}
          scroll={{ x: 'max-content' }}
        ></Table>
        {salesStats}
      </div>
      <div className="mb-8">
        <div className="text-xl">{i18n.t('purchase_daily_report')}</div>
        <Table
          loading={loading}
          pagination={false}
          columns={purchaseColumns}
          dataSource={dailyData?.purchaseReport.records}
          rowKey={(_, index) => index || 0}
          scroll={{ x: 'max-content' }}
        ></Table>
        {purchaseStats}
      </div>
      <div className="mb-8">
        <div className="text-xl">{i18n.t('consignment_daily_report')}</div>
        <Table
          loading={loading}
          pagination={false}
          columns={consignmentColumns}
          dataSource={dailyData?.consignmentReport.records}
          rowKey={(_, index) => index || 0}
          scroll={{ x: 'max-content' }}
        ></Table>
        {consignmentStats}
      </div>
    </div>
  );
};

export default Statement;
