import { ginzaxiaomaApiRequest } from 'apis';

const commonApi = {
  photoRoomRemoveBackground(dto: { url: string; addShadow: boolean }) {
    return ginzaxiaomaApiRequest.post<Promise<string>>(
      '/admin/common/photo-room/remove-background-url',
      dto
    );
  },

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return ginzaxiaomaApiRequest.post<{
      url: string;
    }>('/admin/common/upload-file', formData);
  },
};

export default commonApi;
