import Avatar from "react-avatar"
import {useSelector} from "react-redux"

export const SessionInfo = () => {
  const customer = useSelector(state => state.imManagerSettings.selectedCustomer)

  return (
    <div className="p-4 flex justify-start h-[7%] mb-0">
      <Avatar
        size={40}
        name={customer?.userName || "unknown User"}
        round={true}
        className="mr-[10px] cursor-default"
      />
      <div>
        <p className="text-sm mb-2 cursor-default">{customer?.userName || "unknown User"}</p>
        <p className="text-gray-500 cursor-default">ID:&nbsp;{customer?.userId}</p>
      </div>
    </div>
  )
}
