import {DownloadIcon} from "./assets/IMChatIcons.jsx"
import PropTypes from "prop-types"
import {handleDownload} from "./helpers/downloadHelper.js"
import  { useEffect } from 'react';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export const ImageMessages = ({imMessageBlockList, personType}) => {

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]', {
      Carousel : {
        transition: "fade"
      },
      Toolbar: {
        display: {
          left: ["infobar"],
          middle: [
            "zoomIn",
            "zoomOut",
            "rotateCCW",
            "rotateCW",
            "flipX",
            "flipY",
          ],
          right: ["download", "close"],
        },
      },
    });

    return () => {
      Fancybox.destroy();
    };
  }, []);

  const getGridColumns = () => {
    if (imMessageBlockList?.length === 1) return 'grid-cols-1 grid-rows-1'
    if (imMessageBlockList?.length === 2) return 'grid-cols-2'
    return 'grid-cols-3'
  }

  const getSpanClasses = () => {
    if (imMessageBlockList?.length === 1) return 'col-span-3 row-span-3 h-[250px]'
    return 'h-[80px] w-[80px]'
  }

  return (
    <div className={`flex items-center gap-2 my-2 max-w-md ${personType === "manager" ? "flex-row" : "flex-row-reverse"}`}>
      <button
        onClick={() => handleDownload(imMessageBlockList)}
        className="relative bg-blue-500 rounded-full w-[28px] h-[28px] flex items-center justify-center text-xl cursor-pointer">
        <DownloadIcon/>
      </button>
      <div className={`gallery grid gap-2 ${getGridColumns()}`}>
        {imMessageBlockList?.map((image, index) => (
          <a
            href={image.url}
            data-fancybox="gallery"
            data-download-src={image.url}
            key={image.id}
            className={getSpanClasses()}
          >
            <img
              loading="lazy"
              src={image.url}
              alt={`photo-${index}`}
              className="object-cover h-full w-full rounded-lg hover:cursor-pointer"
            />
          </a>
        ))}
      </div>
    </div>
  )
}

ImageMessages.propTypes = {
  imMessageBlockList: PropTypes.array.isRequired,
  personType: PropTypes.string.isRequired,
}