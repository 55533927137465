import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userInfoReducer from './slices/userInfoSlice';
import globalInfoReducer from './slices/globalInfoSlice';
import { imManagerChatApi } from "./im-chat-stores/imManagerChatApi.js";
import imManagerSettingsSlice from "./im-chat-stores/imManagerSettingsSlice.js";

export const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    globalInfo: globalInfoReducer,
    [imManagerChatApi.reducerPath]: imManagerChatApi.reducer,
    imManagerSettings: imManagerSettingsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(imManagerChatApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
