import { useRef, useEffect, memo, CSSProperties, useMemo } from 'react';
import JsBarcode from 'jsbarcode';
import { PriceTagInfo, currencyMap } from 'types/pms';
import { LangageAttribute } from 'constant/pms';
import { ReplaceAll } from 'utils/handleObject';
import { useDrag } from 'react-dnd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import useBrandList from 'commons/hooks/use-brand-list';
import { findLabelByValue } from 'commons/options';
import i18n from 'i18n';
import { CURRENCY_MAP } from 'commons/options';

interface Props {
  data: PriceTagInfo;
  size?: 'default' | 'large';
  type?: string;
  isDropped?: boolean;
  isRef?: boolean;
  style?: CSSProperties;
  setDragging?: (data: boolean) => void;
  parentDragging: boolean;
}

const ItemClass =
  'mb-[4pt] w-full text-[6pt] leading-[8pt] font-light tracking-normal inline-flex flex-row overflow-hidden box-border';
const ItemLabelClass = 'font-HiraginoMinchoPron box-border text-black';
const ItemLabelSpanClass =
  'text-black font-HiraginoMinchoPron text-justify box-border';

const PriceTag = ({
  data = {},
  size = 'default',
  type,
  isRef,
  style,
  setDragging,
  parentDragging,
}: Props) => {
  const barCodeRef = useRef(null);
  const { BrandList, DefaultBrand } = useBrandList();

  useEffect(() => {
    if (data && barCodeRef.current) {
      const options = {
        fontSize: 16,
        height: 50,
      };
      JsBarcode(barCodeRef.current, data?.id || '88888888', options);
    }
  }, [data, size]);

  const [{ isDragging }, drag]: any = useDrag(
    () =>
      ({
        type,
        item: { ...data },
        collect: (monitor: { isDragging: () => any }) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
          isDragging: monitor.isDragging(),
        }),
        end: (item: { name: any }, monitor: { getDropResult: () => any }) => {
          const dropResult = monitor.getDropResult();
          if (item && dropResult) {
            console.log(`You dropped ${item.name}`);
          }
        },
      } as any),
    [type]
  );

  /** 拖拽中，抛出上层做处理 */
  useEffect(() => {
    if (setDragging) setDragging(isDragging);
  }, [isDragging, setDragging]);

  const getDatePrice = useMemo(() => {
    let out = '';
    if (data.createTime) {
      const time = dayjs(data.createTime);
      const DD = time.format('DD');
      out = `${time.format('MM')}${DD[0]}-${DD[1]}${time.format('YY')}`;
    }

    if (data.costPrice) {
      out = `${out}${data.costPrice.toString().split('').reverse().join('')}`;
    }

    return out;
  }, [data.createTime, data.costPrice]);

  // 价格符号
  const currency = useMemo(() => {
    if (data?.currency === CURRENCY_MAP.HKD) {
      return 'HK$';
    } else if (data?.currency === CURRENCY_MAP.SGD) {
      return 'SG$';
    } else {
      return '￥';
    }
  }, [data?.currency]);

  return (
    <div ref={isRef ? drag : null} data-testid="box" className="text-black">
      {data && data.shopId ? (
        <>
          {/* 固定区域 */}
          <div
            className={classNames(
              'font-Georgia text-[10pt] font-bold tracking-normal text-center uppercase mb-[4pt]'
            )}
          >
            {findLabelByValue(data.brandName, BrandList, DefaultBrand)}
          </div>

          {/* 标题 */}
          <div className="font-Georgia text-[10pt] font-bold break-words text-center">
            {data.name || '-'}
            {data.nameAppend && <> {data.nameAppend || ''}</>}
          </div>

          {/* 小标题 */}
          <div className="font-HiraginoMinchoPron text-[6pt] font-normal tracking-normal text-center my-[8pt]">
            {data.rank || '-'}
          </div>

          {/* 信息展示 */}
          <div className="m-0 mx-[4%] flex justify-center box-border">
            <div className="inline-flex flex-col box-border">
              {/* 材质 */}
              <div className={ItemClass}>
                <div className={ItemLabelClass}>
                  {LangageAttribute.material[(data?.shopId as number) || 1]}:{' '}
                  <span className={ItemLabelSpanClass}>
                    {ReplaceAll(data.material as string) || '-'}
                  </span>
                </div>
              </div>
              {/* 颜色 */}
              <div className={ItemClass}>
                <div className={ItemLabelClass}>
                  {LangageAttribute.color[(data?.shopId as number) || 1]}:{' '}
                  <span className={ItemLabelSpanClass}>
                    {ReplaceAll(data.color as string) || '-'}
                  </span>
                </div>
              </div>
              {/* 金属 */}
              <div className={ItemClass}>
                <div className={ItemLabelClass}>
                  {LangageAttribute.hardware[(data?.shopId as number) || 1]}:{' '}
                  <span className={ItemLabelSpanClass}>
                    {ReplaceAll(data.hardware as string) || '-'}
                  </span>
                </div>
              </div>
              {/* 刻印 */}
              <div className={ItemClass} style={{ marginBottom: 0 }}>
                <div className={ItemLabelClass}>
                  {LangageAttribute.stamp[(data?.shopId as number) || 1]}:{' '}
                  <span className={ItemLabelSpanClass}>
                    {ReplaceAll(data.stamp as string) || '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* 价格 */}
          <div className="my-[4pt] text-center box-border">
            <div className="inline-flex flex-col items-end box-border">
              {data.referencePrice ? (
                <div className="font-HiraginoMinchoPron text-[6pt] tracking-normal w-full text-center">
                  {i18n.t('reference_price')}: {currency}
                  {data.referencePrice?.toLocaleString() || '0'}
                </div>
              ) : null}
              <div className="font-HiraginoMinchoPron text-[11pt] font-bold tracking-normal text-center">
                {currencyMap[`${data.shopId}`]}
                &nbsp;
                {data.price?.toLocaleString() || '0'}
              </div>
              {`${data.shopId}` === '1' && (
                <div className="font-HiraginoMinchoPron text-[5pt] font-bold tracking-normal text-left leading-[5px]">
                  (税込)
                </div>
              )}
            </div>
          </div>

          {/* 条形码 */}
          <div className="flex justify-center box-border">
            <svg className="h-[30pt] box-border max-w-full" ref={barCodeRef} />
          </div>

          {/* mmd-dyy{price in opposite} */}
          {!!getDatePrice && (
            <div className="text-center text-[8pt]">{getDatePrice}</div>
          )}

          {data.sourceType === 1 && (
            <div className="font-HiraginoMinchoPron text-[6pt] text-right pr-[8pt]">
              C
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
export default memo(PriceTag);
