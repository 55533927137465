import image1 from 'assets/images/sop/order-create-sop/mob/image1.png';
import image2 from 'assets/images/sop/order-create-sop/mob/image2.png';
import image3 from 'assets/images/sop/order-create-sop/mob/image3.png';
import image4 from 'assets/images/sop/order-create-sop/mob/image4.png';
import image5 from 'assets/images/sop/order-create-sop/mob/image5.png';
import image6 from 'assets/images/sop/order-create-sop/mob/image6.png';
import image7 from 'assets/images/sop/order-create-sop/mob/image7.png';
import image8 from 'assets/images/sop/order-create-sop/mob/image8.png';
import image9 from 'assets/images/sop/order-create-sop/mob/image9.png';
import image10 from 'assets/images/sop/order-create-sop/mob/image10.png';
import image11 from 'assets/images/sop/order-create-sop/mob/image11.png';
import image12 from 'assets/images/sop/order-create-sop/mob/image12.png';
import image13 from 'assets/images/sop/order-create-sop/mob/image13.png';
import image14 from 'assets/images/sop/order-create-sop/mob/image14.png';
import image15 from 'assets/images/sop/order-create-sop/mob/image15.png';
import image16 from 'assets/images/sop/order-create-sop/mob/image16.png';
import image17 from 'assets/images/sop/order-create-sop/mob/image17.png';
import image18 from 'assets/images/sop/order-create-sop/mob/image18.png';
import image19 from 'assets/images/sop/order-create-sop/mob/image19.png';
import image20 from 'assets/images/sop/order-create-sop/mob/image20.png';
import image21 from 'assets/images/sop/order-create-sop/mob/image21.png';
import image22 from 'assets/images/sop/order-create-sop/mob/image22.png';
import image23 from 'assets/images/sop/order-create-sop/mob/image23.png';
import { useRef, useEffect, useState } from 'react';
import { Drawer } from 'antd';

const OrderCreateSopMOB = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'IMG') {
        setUrl((target as HTMLImageElement).src);
        setIsModalOpen(true);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleClick);
    }

    // 清理事件监听器
    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, []);
  return (
    <div>
      <div
        className="m-auto w-[21cm]"
        ref={containerRef}
        dangerouslySetInnerHTML={{
          __html: `<html>

          <head>
              <meta content="text/html; charset=UTF-8" http-equiv="content-type">
              <style type="text/css">
                  ul.lst-kix_ksi9a0xhf27h-8 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-7 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-5 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-4 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-3 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-2 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-1 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_ksi9a0xhf27h-0 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-8 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-7 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-4 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-5 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-2 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-3 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-0 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xkhh3bpiwctb-1 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-5 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-7 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-8 {
                      list-style-type: none
                  }
          
                  .lst-kix_xq6bhbt664li-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_xq6bhbt664li-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_xq6bhbt664li-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_xq6bhbt664li-0>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_s07i0b8v61lz-5>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_s07i0b8v61lz-7 {
                      list-style-type: none
                  }
          
                  .lst-kix_inkt32i09fdq-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_inkt32i09fdq-4>li:before {
                      content: "0025cb   "
                  }
          
                  ul.lst-kix_s07i0b8v61lz-8 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_s07i0b8v61lz-5 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_s07i0b8v61lz-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_s07i0b8v61lz-3 {
                      list-style-type: none
                  }
          
                  .lst-kix_inkt32i09fdq-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_inkt32i09fdq-5>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_s07i0b8v61lz-4 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_s07i0b8v61lz-1 {
                      list-style-type: none
                  }
          
                  .lst-kix_s07i0b8v61lz-4>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_s07i0b8v61lz-8>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_s07i0b8v61lz-2 {
                      list-style-type: none
                  }
          
                  .lst-kix_xkhh3bpiwctb-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_s07i0b8v61lz-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_inkt32i09fdq-0>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_inkt32i09fdq-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_inkt32i09fdq-8>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_s07i0b8v61lz-0 {
                      list-style-type: none
                  }
          
                  .lst-kix_xkhh3bpiwctb-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_xkhh3bpiwctb-8>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_s07i0b8v61lz-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_inkt32i09fdq-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_s07i0b8v61lz-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_xq6bhbt664li-4>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_xq6bhbt664li-5>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_s07i0b8v61lz-0>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_xq6bhbt664li-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_xq6bhbt664li-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_xq6bhbt664li-8>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_irmidf2g3p9-0 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-4 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-3 {
                      list-style-type: none
                  }
          
                  .lst-kix_xkhh3bpiwctb-0>li:before {
                      content: "0025cf   "
                  }
          
                  ul.lst-kix_irmidf2g3p9-2 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-1 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-8 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-7 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_irmidf2g3p9-5 {
                      list-style-type: none
                  }
          
                  .lst-kix_xkhh3bpiwctb-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_xkhh3bpiwctb-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_xkhh3bpiwctb-4>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_xkhh3bpiwctb-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_xkhh3bpiwctb-5>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_s07i0b8v61lz-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_inkt32i09fdq-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_s07i0b8v61lz-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_irmidf2g3p9-4>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_irmidf2g3p9-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_irmidf2g3p9-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_irmidf2g3p9-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_irmidf2g3p9-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_irmidf2g3p9-0>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_irmidf2g3p9-8>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_irmidf2g3p9-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_irmidf2g3p9-5>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-1>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-2>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-0>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-5>li:before {
                      content: "0025a0   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-7>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-3>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-6>li:before {
                      content: "0025cf   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-4>li:before {
                      content: "0025cb   "
                  }
          
                  .lst-kix_ksi9a0xhf27h-8>li:before {
                      content: "0025a0   "
                  }
          
                  ul.lst-kix_xq6bhbt664li-0 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-1 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-2 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-3 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_xq6bhbt664li-4 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-7 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-8 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-3 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-4 {
                      list-style-type: none
                  }
          
                  li.li-bullet-0:before {
                      margin-left: -18pt;
                      white-space: nowrap;
                      display: inline-block;
                      min-width: 18pt
                  }
          
                  ul.lst-kix_inkt32i09fdq-5 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-6 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-0 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-1 {
                      list-style-type: none
                  }
          
                  ul.lst-kix_inkt32i09fdq-2 {
                      list-style-type: none
                  }
          
                  ol {
                      margin: 0;
                      padding: 0
                  }
          
                  table td,
                  table th {
                      padding: 0
                  }
          
                  .c4 {
                      color: #ff0000;
                      font-weight: 400;
                      text-decoration: none;
                      vertical-align: baseline;
                      font-size: 11pt;
                      font-family: "STFangsong";
                      font-style: normal
                  }
          
                  .c1 {
                      color: #000000;
                      font-weight: 400;
                      text-decoration: none;
                      vertical-align: baseline;
                      font-size: 12pt;
                      font-family: "STFangsong";
                      font-style: normal
                  }
          
                  .c3 {
                      color: #000000;
                      font-weight: 400;
                      text-decoration: none;
                      vertical-align: baseline;
                      font-size: 11pt;
                      font-family: "STFangsong";
                      font-style: normal
                  }
          
                  .c2 {
                      color: #9900ff;
                      font-weight: 400;
                      text-decoration: none;
                      vertical-align: baseline;
                      font-size: 11pt;
                      font-family: "STFangsong";
                      font-style: normal
                  }
          
                  .c11 {
                      padding-top: 0pt;
                      padding-bottom: 4pt;
                      line-height: 1.75;
                      orphans: 2;
                      widows: 2;
                      text-align: center
                  }
          
                  .c6 {
                      padding-top: 0pt;
                      padding-bottom: 4pt;
                      line-height: 1.75;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  .c16 {
                      padding-top: 0pt;
                      padding-bottom: 0pt;
                      line-height: 1.15;
                      orphans: 2;
                      widows: 2;
                      text-align: center
                  }
          
                  .c32 {
                      padding-top: 0pt;
                      padding-bottom: 0pt;
                      line-height: 1.15;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  .c25 {
                      padding-top: 0pt;
                      padding-bottom: 6pt;
                      line-height: 1.75;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  .c39 {
                      padding-top: 16pt;
                      padding-bottom: 4pt;
                      line-height: 1.75;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  .c15 {
                      text-decoration: none;
                      vertical-align: baseline;
                      font-size: 11pt;
                      font-style: normal
                  }
          
                  .c0 {
                      font-size: 12pt;
                      font-family: "STFangsong";
                      color: #ff0000;
                      font-weight: 700
                  }
          
                  .c13 {
                      -webkit-text-decoration-skip: none;
                      color: #1155cc;
                      text-decoration: underline;
                      text-decoration-skip-ink: none
                  }
          
                  .c19 {
                      color: #434343;
                      font-weight: 400;
                      font-size: 14pt;
                      font-family: "Arial"
                  }
          
                  .c18 {
                      text-decoration: none;
                      vertical-align: baseline;
                      font-style: normal
                  }
          
                  .c38 {
                      color: #ff0000;
                      font-weight: 400;
                      font-family: "Arial"
                  }
          
                  .c5 {
                      font-family: "STFangsong";
                      color: #ff0000;
                      font-weight: 400
                  }
          
                  .c9 {
                      background-color: #ffff00;
                      font-family: "STFangsong";
                      font-weight: 700
                  }
          
                  .c7 {
                      font-size: 12pt;
                      font-family: "STFangsong";
                      font-weight: 700
                  }
          
                  .c8 {
                      font-size: 12pt;
                      font-family: "STFangsong";
                      font-weight: 400
                  }
          
                  .c20 {
                      background-color: #ffffff;
                      max-width: 451.4pt;
                      padding: 72pt 72pt 72pt 72pt
                  }
          
                  .c12 {
                      font-family: "STFangsong";
                      color: #ff0000;
                      font-weight: 700
                  }
          
                  .c23 {
                      color: #000000;
                      font-weight: 400;
                      font-family: "Arial"
                  }
          
                  .c14 {
                      font-weight: 400;
                      font-family: "STFangsong"
                  }
          
                  .c36 {
                      font-weight: 700;
                      font-family: "Arial"
                  }
          
                  .c22 {
                      color: #434343;
                      font-size: 14pt
                  }
          
                  .c30 {
                      color: #000000;
                      font-size: 16pt
                  }
          
                  .c24 {
                      color: inherit;
                      text-decoration: inherit
                  }
          
                  .c26 {
                      font-weight: 700;
                      font-family: "STFangsong"
                  }
          
                  .c35 {
                      padding: 0;
                      margin: 0
                  }
          
                  .c28 {
                      color: #000000;
                      font-size: 26pt
                  }
          
                  .c33 {
                      color: #000000;
                      font-size: 14pt
                  }
          
                  .c27 {
                      margin-left: 36pt;
                      padding-left: 0pt
                  }
          
                  .c10 {
                      page-break-after: avoid
                  }
          
                  .c17 {
                      height: 11pt
                  }
          
                  .c37 {
                      color: #000000
                  }
          
                  .c31 {
                      color: #999999
                  }
          
                  .c29 {
                      text-indent: 36pt
                  }
          
                  .c34 {
                      font-size: 14pt
                  }
          
                  .c21 {
                      background-color: #ffff00
                  }
          
                  .c40 {
                      font-size: 12pt
                  }
          
                  .title {
                      padding-top: 0pt;
                      color: #000000;
                      font-size: 26pt;
                      padding-bottom: 3pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  .subtitle {
                      padding-top: 0pt;
                      color: #666666;
                      font-size: 15pt;
                      padding-bottom: 16pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  li {
                      font-size: 11pt;
                      font-family: "Arial"
                  }
          
                  p {
                      margin: 0;
                      color: #000000;
                      font-size: 11pt;
                      font-family: "Arial"
                  }
          
                  h1 {
                      padding-top: 20pt;
                      color: #000000;
                      font-size: 20pt;
                      padding-bottom: 6pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  h2 {
                      padding-top: 18pt;
                      color: #000000;
                      font-size: 16pt;
                      padding-bottom: 6pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  h3 {
                      padding-top: 16pt;
                      color: #434343;
                      font-size: 14pt;
                      padding-bottom: 4pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  h4 {
                      padding-top: 14pt;
                      color: #666666;
                      font-size: 12pt;
                      padding-bottom: 4pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  h5 {
                      padding-top: 12pt;
                      color: #666666;
                      font-size: 11pt;
                      padding-bottom: 4pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
          
                  h6 {
                      padding-top: 12pt;
                      color: #666666;
                      font-size: 11pt;
                      padding-bottom: 4pt;
                      font-family: "Arial";
                      line-height: 1.15;
                      page-break-after: avoid;
                      font-style: italic;
                      orphans: 2;
                      widows: 2;
                      text-align: left
                  }
              </style>
          </head>
          
          <body class="c20 doc-content">
              <p class="c11 c10 title" id="h.ekowdo8wpaon"><span class="c14">如何创建</span><span class="c5">销售订单</span></p>
              <p class="c6 c17 c29"><span class="c3"></span></p>
              <p class="c6"><span class="c26 c34">应用场景说明：</span><span class="c1">后台创建订单一般用于线上工作人员帮助客人创建订单，</span></p>
              <p class="c6 c17"><span class="c1"></span></p>
              <h2 class="c6 c10" id="h.2n57sw45oszw"><span class="c26">创建订单步骤</span></h2>
              <p class="c6"><span class="c14">1.1、打开后台：</span><span class="c13 c14"><a class="c24"
                          href="https://www.google.com/url?q=https://admin-shop.ginzaxiaoma.com/%25EF%25BC%2589%25EF%25BC%258C&amp;sa=D&amp;source=editors&amp;ust=1718789332900951&amp;usg=AOvVaw2Npg9Po33vkTF_N2WKghHS">https://admin-shop.ginzaxiaoma.com/</a></span><span
                      class="c14">，如果无账号、无权限请联络IT部门</span></p>
              <p class="c6"><span class="c14">1.2、在侧边栏</span><span class="c12">「订单-创建订单」</span><span
                      class="c14">打开创建订单页面如下图</span><span class="c12">「图1」</span><span class="c26">、</span><span
                      class="c15 c12">「图2」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 495.65px; height: 528.82px;"><img
                          alt="" src="${image5}"
                          style="width: 495.65px; height: 528.82px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图1</span><span class="c4">：侧边栏-创建订单】</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 395.46px; height: 846.50px;"><img
                          alt="" src="${image16}"
                          style="width: 395.46px; height: 846.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图2</span><span class="c4">：创建订单页面】</span></p>
              <h3 class="c6 c10" id="h.xolgmqb30uka"><span class="c18 c14 c22">第1步：会员选择</span></h3>
              <p class="c6"><span class="c8">2.1、输入需要创建订单客人的</span><span class="c0">手机号/邮箱</span><span
                      class="c8">，需要确保客人已注册为会员。已经成为会员的客人，输入邮箱前缀/手机号码会在下拉框中出现客人邮箱，直接选择即可。如下图</span><span
                      class="c0">「图3」</span><span class="c7">、</span><span class="c0">「图4」</span><span class="c7">、</span><span
                      class="c0">「图5」</span><span class="c18 c7 c37">。</span></p>
              <p class="c6"><span class="c8">如果输入手机号码/邮箱没有查询到用户信息怎么办？</span><span class="c7">可参考</span><span class="c13 c8"><a
                          class="c24" href="#h.7aggdzj33oqt">「其他3：创建订单式客人没注册怎么办」</a></span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 261.00px; height: 564.00px;"><img
                          alt="" src="${image4}"
                          style="width: 261.00px; height: 564.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图3</span><span class="c4">：会员选择模块】</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 309.65px; height: 671.70px;"><img
                          alt="" src="${image14}"
                          style="width: 309.65px; height: 671.70px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图4</span><span class="c4">：输入手机号码查找会员】</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 395.02px; height: 855.50px;"><img
                          alt="" src="${image13}"
                          style="width: 395.02px; height: 855.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图5</span><span class="c4">：输入邮箱查找会员】</span></p>
              <p class="c6"><span class="c8">2.2、 会员选择好之后选择客户的</span><span class="c0">收货地址</span><span class="c8">，如下图</span><span
                      class="c0">「图6」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 260.00px; height: 566.00px;"><img
                          alt="" src="${image11}"
                          style="width: 260.00px; height: 566.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c5">【</span><span class="c0">图6</span><span class="c4">：选择收货地址】</span></p>
              <p class="c11 c17"><span class="c3"></span></p>
              <h3 class="c6 c10" id="h.nxdb2475wrws"><span class="c26">第2步：商品选择</span></h3>
              <p class="c6"><span class="c1">3.1、输入客人需要下单的商品商品编号查找商品，下拉框会出现商品的基本信息。包括商品编号、币种、价格、在库地、上架状态（1为上架，0为下架）。</span></p>
              <p class="c6"><span class="c8">3.2、假设搜索不到商品，代表商品没有被创建，可以参考文末解决方案</span><span class="c8 c13"><a class="c24"
                          href="#h.nakzi6b311bx">「其他2：创建订单时候商品没创建怎么办？」。</a></span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 395.29px; height: 861.50px;"><img
                          alt="" src="${image1}"
                          style="width: 395.29px; height: 861.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图7</span><span class="c4">：商品选择】</span></p>
              <p class="c6"><span class="c8">3.3、商品选择好为如下</span><span class="c0">「图8」</span><span class="c1">状态：</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 236.32px; height: 514.77px;"><img
                          alt="" src="${image8}"
                          style="width: 236.32px; height: 514.77px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图8</span><span class="c4">：选择好商品的展示示例】</span></p>
              <p class="c16 c17"><span class="c2"></span></p>
              <h3 class="c6 c10" id="h.9ch3jvrdwp4v"><span class="c18 c22 c26">第3步：支付设置</span></h3>
              <p class="c6"><span class="c14">4.1、支付设置模块，</span><span
                      class="c12">可以使用积分（日币）、优惠金额（在库地币种）、优惠券（code）、设置支付状态</span><span
                      class="c3">。积分可以在上面「会员选择」的环节直接查看剩余积分。输入对应的积分和优惠金额以及输入优惠券Code。支付状态为必选项且无默认值，当选择待支付时当前订单可以进行多笔支付设置，如果选择已支付则当前订单不可以再进行多笔支付的设置。</span>
              </p>
              <p class="c6 c17"><span class="c3"></span></p>
              <p class="c6"><span class="c9">注意：</span><span class="c14 c21">在提交订单前请确认清楚优惠金额、订单金额对应的币种和金额。</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 382.26px; height: 831.50px;"><img
                          alt="" src="${image17}"
                          style="width: 382.26px; height: 831.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【图9：支付设置】</span></p>
              <p class="c6 c17"><span class="c3"></span></p>
              <p class="c6"><span class="c8">4.2、如需要多笔支付，可点</span><span class="c0">「设置多笔支付」</span><span class="c7">如</span><span
                      class="c0">「图12」</span><span class="c8">创建多笔支付订单</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 364.15px; height: 793.52px;"><img
                          alt="" src="${image10}"
                          style="width: 364.15px; height: 793.52px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图12</span><span class="c4">：设置多笔支付按钮】</span></p>
              <p class="c16 c17"><span class="c4"></span></p>
              <p class="c16 c17"><span class="c2"></span></p>
              <p class="c6"><span class="c1">可以创建2～5笔进行分开支付。2笔的情况，会用总金额减去首笔金额，分拆为2笔。</span></p>
              <p class="c6"><span class="c1">3～5笔的情况，会用总金额减去首笔金额，剩余金额平均分拆为3～5笔。</span></p>
              <p class="c6"><span class="c8">以下以3笔，首笔金额1000000日币进行示例，如</span><span class="c0">「图14」</span><span
                      class="c7">、</span><span class="c0">「图15」</span><span class="c1"><br></span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 299.65px; height: 647.70px;"><img
                          alt="" src="${image19}"
                          style="width: 299.65px; height: 647.70px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图14</span><span class="c5">：设置多笔支付】</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 386.15px; height: 838.99px;"><img
                          alt="" src="${image15}"
                          style="width: 386.15px; height: 838.99px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图15</span><span class="c4">：设置好多笔支付展示的多笔金额列表】</span></p>
              <p class="c6 c17"><span class="c3"></span></p>
              <h3 class="c10 c39" id="h.2ndgltcl6nv1"><span class="c18 c22 c36">第4步：订单来源设置</span></h3>
              <p class="c32"><span>如下图默认来源为网站，可手动更改</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 260.00px; height: 608.00px;"><img
                          alt="" src="${image6}"
                          style="width: 260.00px; height: 608.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c11"><span class="c0 c18">【图：订单来源设置】</span></p>
              <p class="c6 c17"><span class="c1"></span></p>
              <p class="c6"><span class="c8">确认好上述信息，即可点击完成创建订单，如</span><span class="c0 c18">「图16」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 370.06px; height: 805.77px;"><img
                          alt="" src="${image21}"
                          style="width: 370.06px; height: 805.77px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图16</span><span class="c4">：二次确认是否创建订单】</span></p>
              <p class="c16 c17"><span class="c2"></span></p>
              <p class="c6"><span class="c8">提交成功后会提示</span><span class="c0">「创建订单成功」</span><span
                      class="c8">，同时产生2段信息。如</span><span class="c0 c18">「图17」</span></p>
              <ul class="c35 lst-kix_xq6bhbt664li-0 start">
                  <li class="c6 c27 li-bullet-0"><span class="c15 c14 c31">跳转订单详情页：营业员可以使用，用于查看客人订单详情。</span></li>
                  <li class="c6 c27 li-bullet-0"><span class="c14 c31">链接部分：可以复制给客人，客人可以快速登陆网站进行支付。</span></li>
              </ul>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 320.65px; height: 699.71px;"><img
                          alt="" src="${image9}"
                          style="width: 320.65px; height: 699.71px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图17</span><span class="c4">：创建订单成功】</span></p>
              <h3 class="c6 c10" id="h.9nyqrfsb3lb7"><span class="c18 c19">第5步：查看订单详情</span></h3>
              <p class="c6"><span class="c14">5.1、点击</span><span class="c12">「跳转订单详情页」</span><span
                      class="c14">，可以查看客人订单。包括订单的状态、操作、基础信息、收件人信息、商品信息、费用信息、多笔支付情况、积分历史、操作历史。如</span><span
                      class="c15 c12">「图18」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 413.64px; height: 765.54px;"><img
                          alt="" src="${image22}"
                          style="width: 413.64px; height: 765.54px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图18</span><span class="c4">：二次确认是否创建订单】</span></p>
              <h2 class="c10 c25" id="h.9safa398hjv5"><span class="c18 c26 c33">其他1：客人如何支付</span></h2>
              <p class="c6"><span class="c8">复制创建订单成功下面的链接，或在客人订单页面点</span><span class="c0">「复制订单链接」</span><span
                      class="c8">如</span><span class="c0">「图17」</span><span class="c1">。</span></p>
              <p class="c6"><span class="c8">发给客人，客人登陆账号可以直接进入支付页面。客人看到的页面如</span><span class="c0">「图19」</span><span
                      class="c1">，如果是多笔支付会进行拆分，黄色高亮位置和下方按钮是当前支付的这一笔金额</span></p>
              <p class="c6 c17"><span class="c3"></span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 355.19px; height: 612.47px;"><img
                          alt="" src="${image18}"
                          style="width: 355.19px; height: 612.47px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图19</span><span class="c4">：二次确认是否创建订单】</span></p>
              <p class="c16 c17"><span class="c2"></span></p>
              <p class="c6"><span
                      class="c14">支付页面默认选中信用卡，展示的信息为信用卡信息、订单信息（日期、编号、状态）、收货地址信息，如果客人希望更换其他支付方式，可点击下图中的“箭头”如</span><span
                      class="c12">「图20」</span><span class="c14">，可用的支付方式有：信用卡、paypal、alipay、银联、wechat、银行转账、虚拟币支付</span><span
                      class="c4 c21">「注意：根据订单中商品的库存地不同支付选项会有所不同」。</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 631.60px; height: 633.70px;"><img
                          alt="" src="${image23}"
                          style="width: 631.60px; height: 633.70px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图20</span><span class="c4">：二次确认是否创建订单】</span></p>
              <p class="c16 c17"><span class="c4"></span></p>
              <p class="c16 c17"><span class="c4"></span></p>
              <h2 class="c6 c10" id="h.nakzi6b311bx"><span class="c18 c26 c33">其他2：创建订单时候商品没创建怎么办？</span></h2>
              <p class="c6 c29"><span class="c1">可在商品-商品列表页面，临时创建一个商品，之后可以联络出品负责人（张）进行信息补充。具体操作如下：</span></p>
              <p class="c6"><span class="c8">2.1 打开商品列表页面</span><span class="c0 c18">「图21」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 325.98px; height: 712.50px;"><img
                          alt="" src="${image20}"
                          style="width: 325.98px; height: 712.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图21</span><span class="c4">：商品列表入口】</span></p>
              <p class="c6"><span class="c8">2.2 点击</span><span class="c0">「添加」</span><span class="c8">如</span><span
                      class="c0 c18">「图22」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 375.89px; height: 819.77px;"><img
                          alt="" src="${image2}"
                          style="width: 375.89px; height: 819.77px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图22</span><span class="c5">：添加商品按钮】</span></p>
              <p class="c6"><span class="c1">2.3 输入必填信息</span></p>
              <p class="c6"><span class="c1">包括商品编号、分类、价格、成色。库存地和币种会自动根据编号进行选择，确认一下即可。</span></p>
              <p class="c6"><span class="c1">填写完成这些就可以创建好商品。如客人需要照片可在照片群临时找一张照片上传。</span></p>
              <p class="c6"><span class="c8">剩余的信息如果需要补充，可以联络出品负责人（张）进行补充。</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 601.33px;"><img
                          alt="" src="${image3}"
                          style="width: 601.70px; height: 601.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图23</span><span class="c4">：添加商品】</span></p>
              <p class="c6 c17"><span class="c3"></span></p>
              <h2 class="c6 c10" id="h.7aggdzj33oqt"><span class="c18 c26 c33">其他3：创建订单时客人没注册怎么办？</span></h2>
              <p class="c6"><span class="c14">与客人沟通请客人自行在网站</span><span class="c12">「https://ginzaxiaoma.com/index」</span><span
                      class="c3">上进行注册</span></p>
              <p class="c6"><span class="c3">若客人不会操作，后台可帮助客人创建会员</span></p>
              <p class="c6"><span class="c14">后台操作：在侧边栏找到</span><span class="c12">「会员」---&gt;「注册新规会员」</span><span
                      class="c14">如</span><span class="c12 c15">「图24」</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 331.65px; height: 717.89px;"><img
                          alt="" src="${image12}"
                          style="width: 331.65px; height: 717.89px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图24</span><span class="c4">：创建会员入口】</span></p>
              <p class="c6"><span class="c14">根据</span><span class="c12">「图25」</span><span class="c14">打红色“</span><span
                      class="c5">*</span><span class="c3">”的项填写客人的注册信息，提交成功后将提示成功</span></p>
              <p class="c11"><span
                      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 633.33px;"><img
                          alt="" src="${image7}"
                          style="width: 601.70px; height: 633.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                          title=""></span></p>
              <p class="c16"><span class="c5">【</span><span class="c0">图25</span><span class="c5">：创建会员】</span></p>
              <p class="c16 c17"><span class="c3"></span></p>
          </body>
          
          </html>`,
        }}
      ></div>

      <Drawer
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={'100%'}
      >
        <div className="w-full h-full">
          <img src={url} />
        </div>
      </Drawer>
    </div>
  );
};

export default OrderCreateSopMOB;
