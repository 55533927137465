import { useCallback, useRef, useState } from 'react';
import { Button, Divider, Form, Modal, message } from 'antd';
import { OmsRecycleOrderCreateDTO } from 'types/oms';
import UserInfo from '../components/user-info';
import ProductPriceInfo from '../components/product-price-info';
import PayInfo from '../components/pay-info';
import { fetchRecycleOrderCreate, fetchRecycleOrderDetail } from 'apis/oms';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import {
  getDempyouProduct,
  getDempyouOrderInfo,
  getDempyouToPrint,
  ProductType,
  PrintParamType,
} from 'utils/getDempyouParam';
import { UserType } from '../components/user-info';
import { getProductLabel } from 'utils/getProductLabel';
import {
  removeRecyclingConsignmentStore,
  ITEM_NAME_MAP,
} from '../components/utils';

interface OrderResult {
  orderSn: string;
  id: string;
}

const CreateOrderContract = ({ type }: { type: number }) => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  const [form0] = Form.useForm<OmsRecycleOrderCreateDTO>();
  const [form1] = Form.useForm<OmsRecycleOrderCreateDTO>();
  const [form2] = Form.useForm<OmsRecycleOrderCreateDTO>();

  const [userInfo, setUserInfo] = useState<UserType>();

  const payloadRef = useRef<OmsRecycleOrderCreateDTO>({ type });
  const {
    staffSelectOptions,
    countryOptions,
    productCategoryCascaderOptions,
    colorSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    accessorySelectOptions,
  } = useAppSelector(selectGlobalInfo);

  const success = () => {
    Promise.all([
      form0.validateFields(),
      form1.validateFields(),
      form2.validateFields(),
    ])
      .then(() => {
        getCreate();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ItemName =
    type === 1
      ? ITEM_NAME_MAP.CONSIGNMENT_CONTRACT
      : ITEM_NAME_MAP.RECYCLING_CONTRACT;

  const getCreate = useCallback(() => {
    const { memberId } = payloadRef.current;
    if (!memberId) {
      message.error(i18n.t('please_select_a_member'));
      return;
    }
    fetchRecycleOrderCreate({ ...payloadRef.current })
      .then((d) => {
        removeRecyclingConsignmentStore(ItemName);
        const dParse = JSON.parse(d.data) as OrderResult[];
        modal.confirm({
          title: i18n.t('successful_operation'),
          content: (
            <div>
              {dParse.map((d) => (
                <div key={d.id}>
                  <a href={`/rrs/recycling-consignment-detail/${d.id}`}>
                    {d.orderSn}
                  </a>
                </div>
              ))}
            </div>
          ),
          onOk: async () => {
            const prodList: ProductType[] = [];
            let orderInfo: PrintParamType | undefined = undefined;
            let index = 0;
            for (const { id } of dParse) {
              const { data } = await fetchRecycleOrderDetail({ id });
              const attrOption = {
                productCategoryCascaderOptions,
                colorSelectOptions,
                rankSelectOptions,
                stampSelectOptions,
                materialCascaderOptionsMap,
                hardwareSelectOptions,
                accessorySelectOptions,
              };
              const attrProd = {
                attrAccessory: data.omsRecycleOrderProduct?.accessory,
                attrColor: data.omsRecycleOrderProduct?.attrColor,
                attrHardware: data.omsRecycleOrderProduct?.attrHardware,
                attrMaterial: data.omsRecycleOrderProduct?.attrMaterial,
                attrSize: data.omsRecycleOrderProduct?.attrSize,
                attrStamp: data.omsRecycleOrderProduct?.attrStamp,
                productCategoryId:
                  data.omsRecycleOrderProduct?.productCategoryId,
              };
              const label = getProductLabel(attrOption, attrProd);
              // 「商品名　尺寸　素材　色　刻印　金具」
              const name = `${label.productCategorySelectLabelList} ${label.materialSelectLabelList} ${label.colorSelectLabelList} ${label.stampSelectLabel} ${label.hardwareSelectLabel}`;
              prodList.push(getDempyouProduct(data, { name })!);
              if (index === 0) {
                orderInfo = await getDempyouOrderInfo(data, {
                  staffSelectOptions,
                  countryOptions,
                });
              }
              index++;
            }
            if (prodList && orderInfo) {
              getDempyouToPrint({
                productList: prodList,
                printParam: orderInfo,
                prints: true,
              });
              navigate(`/rrs/recycling-consignment-list`);
            }
          },
          okText: '伝票印刷',
          onCancel: () => {
            navigate(`/rrs/recycling-consignment-list`);
          },
        });
      })
      .catch((d) => {});
  }, [
    ItemName,
    accessorySelectOptions,
    colorSelectOptions,
    countryOptions,
    hardwareSelectOptions,
    materialCascaderOptionsMap,
    modal,
    navigate,
    productCategoryCascaderOptions,
    rankSelectOptions,
    staffSelectOptions,
    stampSelectOptions,
  ]);

  return (
    <div>
      <div className="flex justify-center">
        <div className="text-2xl font-medium text-center">
          {type === 1
            ? i18n.t('consignmentContractOrder')
            : i18n.t('recyclingContractOrder')}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-[80%]">
          <div className="mb-4">
            <Divider orientation="center">
              <div className="text-2xl">{i18n.t('member_info')}</div>
            </Divider>
            <UserInfo
              form={form0}
              orderType={type}
              type="contract"
              onChange={({ user, ...rest }) => {
                payloadRef.current = { ...payloadRef.current, ...rest };
                setUserInfo(user);
              }}
            />
          </div>
          {/*  */}
          <div className="mb-4">
            <Divider orientation="center">
              <div className="text-2xl">{i18n.t('product_info')}</div>
            </Divider>
            <ProductPriceInfo
              form={form1}
              onChange={(data) => {
                payloadRef.current = { ...payloadRef.current, ...data };
              }}
              type="contract"
              orderType={type}
            />
          </div>
          {/*  */}
          <div className="mb-4">
            <Divider orientation="center">
              <div className="text-2xl">{i18n.t('payment_information')}</div>
            </Divider>
            <PayInfo
              form={form2}
              type="contract"
              orderType={type}
              onChange={(data) => {
                payloadRef.current = { ...payloadRef.current, ...data };
              }}
              userInfo={userInfo}
            />
          </div>
        </div>
      </div>

      <div className="text-center space-x-2">
        <Button type="primary" onClick={() => success()}>
          {i18n.t('submit')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form0.resetFields();
            form1.resetFields();
            form2.resetFields();
            removeRecyclingConsignmentStore(ItemName);
            window.location.reload();
          }}
        >
          {i18n.t('reset')}
        </Button>
      </div>

      {contextHolder}
    </div>
  );
};

export default CreateOrderContract;
