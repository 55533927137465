import { useState, useEffect } from 'react'
import {Badge, Spin} from 'antd'
import {
  useCreateMessageMutation,
  useUploadAttachmentMutation
} from "../../store/im-chat-stores/imManagerChatApi.js"
import {AttachmentIcon, CreateMessageIconActive, CreateMessageIconMuted} from "./assets/IMChatIcons.jsx"
import {filesize} from "filesize"
import PropTypes from "prop-types"
import {useSelector} from "react-redux"
import {LoadingOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next"
import AttachmentUploadComponent from './AttachmentUploadComponent';

export const MessageForm = ({ inputRef, inputValue, setInputValue }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [attachments, setAttachments] = useState({ documents: [], photos: [] })
  const [errorMessage, setErrorMessage] = useState('')
  const [uploadAttachment] = useUploadAttachmentMutation()
  const [uploadTextMessage] = useCreateMessageMutation()
  const session = useSelector(state => state.imManagerSettings.selectedSession)
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    const trimmedInput = inputValue.trim()
    let error = ''

    if (trimmedInput.length > 250) {
      error = 'Message exceeds 250 characters.'
      setErrorMessage(error)
    } else if (errorMessage !== 'Some files were too large. Limit is 10MB each') {
      setErrorMessage('')
    }

    setIsButtonDisabled(!(trimmedInput.length > 0 && trimmedInput.length <= 250 || attachments.photos.length > 0 || attachments.documents.length > 0) || !!error)
  }, [inputValue, attachments])

  const handleAttachmentUpload = (docs, from) => {
    const files = Array.from(docs)
    const maxFileSize = 10 * 1024 * 1024

    const validFiles = files.filter(file => file.size <= maxFileSize)
    const invalidFiles = files.filter(file => file.size > maxFileSize)

    if (invalidFiles.length > 0) {
      setErrorMessage(`Some files were too large. Limit is 10MB each`)
    } else {
      setErrorMessage('')
    }

    if (from === 'document-upload') {
      setAttachments({ documents: validFiles.slice(0, 1), photos: [] })
    } else if (from === 'photo-upload') {
      setAttachments({ documents: [], photos: validFiles.slice(0, 9) })
    }
  }

  const createMessage = async (blockList) => {
    await uploadTextMessage({
      session: session,
      data: {
        blockList: blockList
      }
    }).unwrap()
  }
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)

  const uploadFiles = async (photos) => {
    let uploadPromises = []

    photos.forEach((photo) => {
      uploadPromises.push(uploadAttachment({file: photo}).unwrap())
    })

    let response = await Promise.allSettled(uploadPromises)

    let blockList = response
      .filter(response => response.status === 'fulfilled')
      .map(response => ({
        type: 'file',
        url: response.value.url,
        contentType: response.value.mimetype,
        originalName: response.value.originalname,
        sizeString: filesize(response.value.size, {standard: 'jedec'})
      }))

    if(blockList?.length > 0) {
      await createMessage(blockList)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(inputValue.trim() || attachments.photos.length > 0 || attachments.documents.length > 0) {
      try {
        setLoading(true)
        if (isButtonDisabled) return
        if(attachments.photos.length) {
          await uploadFiles(attachments.photos)
        }
        else if (attachments.documents.length) {
          await uploadFiles(attachments.documents)
        }
        else if (inputValue.trim().length > 0 && inputValue.trim().length <= 250) {
          let blockList = [
            {
              type: 'text',
              value: inputValue.trim()
            }
          ]
          await createMessage(blockList)
        }
        setInputValue('')
        setAttachments({ documents: [], photos: [] })
        setErrorMessage('')
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  const attachmentCount = attachments?.documents?.length + attachments?.photos?.length

  return (
    <div className="px-2 bg-white">
      <form className="flex justify-between items-end" onSubmit={handleSubmit}>
        <button
          type="button"
          disabled={loading}
          className="relative bg-transparent"
          onClick={() => {
            setAttachments({ documents: [], photos: [] })
            setErrorMessage('')
            setOpenAttachmentModal(prevState => !prevState)
          }}
        >
          <Badge className="mt-2" count={attachmentCount} color="#1677FF">
            <div className="flex-none p-2 border-none">
              <AttachmentIcon />
            </div>
          </Badge>
        </button>
        <AttachmentUploadComponent
          onClose={() => setOpenAttachmentModal(false)}
          handleAttachmentUpload={handleAttachmentUpload}
          opened={openAttachmentModal}
        />

        <textarea
          ref={inputRef}
          placeholder={t('write_your_message')}
          className="grow ml-4 p-2 border rounded-lg resize-none min-h-[80px] max-h-[300px] overflow-x-hidden focus:outline-none"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          rows={1}
        />
        <button className="flex-none p-2 bg-transparent" type="submit" disabled={isButtonDisabled || loading}>
          {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />}
          {!loading && (isButtonDisabled ? <CreateMessageIconMuted /> : <CreateMessageIconActive />)}
        </button>
      </form>
      {errorMessage && <p className="text-red-500 text-sm mt-1 ml-14">{errorMessage}</p>}
      {!errorMessage && <p className="text-gray-400 text-sm ml-14 mt-1">{t('press_enter_to_send')}</p>}
    </div>
  )
}

MessageForm.propTypes = {
  inputValue: PropTypes.string.isRequired,
}