import image1 from 'assets/images/sop/checkout_counter_sop/pc_cn/image1.png';
import image2 from 'assets/images/sop/checkout_counter_sop/pc_cn/image2.png';
import image3 from 'assets/images/sop/checkout_counter_sop/pc_cn/image3.png';
import image4 from 'assets/images/sop/checkout_counter_sop/pc_cn/image4.png';
import image5 from 'assets/images/sop/checkout_counter_sop/pc_cn/image5.png';
import image6 from 'assets/images/sop/checkout_counter_sop/pc_cn/image6.png';
import image7 from 'assets/images/sop/checkout_counter_sop/pc_cn/image7.png';
import image8 from 'assets/images/sop/checkout_counter_sop/pc_cn/image8.png';
import image9 from 'assets/images/sop/checkout_counter_sop/pc_cn/image9.png';
import image10 from 'assets/images/sop/checkout_counter_sop/pc_cn/image10.png';
import image11 from 'assets/images/sop/checkout_counter_sop/pc_cn/image11.png';
import image12 from 'assets/images/sop/checkout_counter_sop/pc_cn/image12.png';
import image13 from 'assets/images/sop/checkout_counter_sop/pc_cn/image13.png';
import image14 from 'assets/images/sop/checkout_counter_sop/pc_cn/image14.png';
import image15 from 'assets/images/sop/checkout_counter_sop/pc_cn/image15.png';
import image16 from 'assets/images/sop/checkout_counter_sop/pc_cn/image16.png';

const ja_Text = `<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c1 {
            border-right-style: solid;
            padding-top: 0pt;
            border-top-width: 0pt;
            border-bottom-color: #000000;
            border-right-width: 0pt;
            padding-left: 0pt;
            border-left-color: #000000;
            padding-bottom: 0pt;
            line-height: 1.15;
            border-right-color: #000000;
            border-left-width: 0pt;
            border-top-style: solid;
            margin-right: 42pt;
            background-color: #ffffff;
            border-left-style: solid;
            border-bottom-width: 0pt;
            border-top-color: #000000;
            border-bottom-style: solid;
            orphans: 2;
            widows: 2;
            text-align: center;
            padding-right: 0pt
        }

        .c10 {
            border-right-style: solid;
            padding-top: 0pt;
            border-top-width: 0pt;
            border-bottom-color: #000000;
            border-right-width: 0pt;
            padding-left: 0pt;
            border-left-color: #000000;
            padding-bottom: 4pt;
            line-height: 1.75;
            border-right-color: #000000;
            border-left-width: 0pt;
            border-top-style: solid;
            margin-right: 42pt;
            background-color: #ffffff;
            border-left-style: solid;
            border-bottom-width: 0pt;
            border-top-color: #000000;
            border-bottom-style: solid;
            orphans: 2;
            widows: 2;
            text-align: center;
            padding-right: 0pt
        }

        .c4 {
            border-right-style: solid;
            padding-top: 0pt;
            border-top-width: 0pt;
            border-bottom-color: #000000;
            border-right-width: 0pt;
            padding-left: 0pt;
            border-left-color: #000000;
            padding-bottom: 4pt;
            line-height: 1.75;
            border-right-color: #000000;
            border-left-width: 0pt;
            border-top-style: solid;
            margin-right: 42pt;
            background-color: #ffffff;
            border-left-style: solid;
            border-bottom-width: 0pt;
            border-top-color: #000000;
            border-bottom-style: solid;
            orphans: 2;
            widows: 2;
            text-align: left;
            padding-right: 0pt
        }

        .c13 {
            border-right-style: solid;
            border-top-width: 0pt;
            border-bottom-color: #000000;
            border-right-width: 0pt;
            padding-left: 0pt;
            border-left-color: #000000;
            border-right-color: #000000;
            border-left-width: 0pt;
            border-top-style: solid;
            margin-right: 42pt;
            background-color: #ffffff;
            border-left-style: solid;
            border-bottom-width: 0pt;
            border-top-color: #000000;
            border-bottom-style: solid;
            padding-right: 0pt
        }

        .c9 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c2 {
            color: #ff0000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c6 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c8 {
            color: #ff0000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c3 {
            color: #ff0000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c15 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-style: normal
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c21 {
            padding-top: 0pt;
            padding-bottom: 3pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c12 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c17 {
            background-color: #ffff00;
            font-weight: 700;
            font-size: 11pt;
            font-family: "STFangsong"
        }

        .c14 {
            font-size: 12pt;
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 700
        }

        .c23 {
            text-decoration: none;
            vertical-align: baseline;
            font-size: 26pt;
            font-style: normal
        }

        .c11 {
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 400
        }

        .c7 {
            font-size: 12pt;
            font-family: "STFangsong";
            font-weight: 400
        }

        .c16 {
            font-size: 26pt;
            font-family: "STFangsong";
            font-weight: 400
        }

        .c19 {
            font-size: 14pt;
            font-family: "STFangsong";
            font-weight: 700
        }

        .c20 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c18 {
            margin-left: -72pt;
            text-indent: 72pt
        }

        .c22 {
            background-color: #ffff00
        }

        .c5 {
            height: 11pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c20 doc-content">
    <p class="c13 c21"><span class="c16">レジ機能の使い方</span></p>
    <p class="c4"><span class="c19">適用シーン：店舗で消費者のために販売注文を作成する際に使用します。</span></p>
    <p class="c4"><span class="c2">注文作成手順：</span></p>
    <p class="c4"><span class="c2">1.1、管理画面を開く：https://admin-shop.ginzaxiaoma.com/。アカウントや権限がない場合はIT部門に連絡してください。</span>
    </p>
    <p class="c4"><span class="c2">1.2、サイドバーの「注文 - レジ」をクリックし、以下の画像のようにレジページを開きます（「図1」）。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 264.00px;"><img
                alt="" src="${image3}"
                style="width: 601.70px; height: 264.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c11">【</span><span class="c14">図1：サイドバー - レジ</span><span class="c3">】</span></p>
    <p class="c0"><span class="c9">第1步：会員を選択</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span
            class="c9">1.1、注文を作成する客の電話番号またはメールアドレスを入力します。客がすでに会員として登録されていることを確認してください。会員である客の場合、メールのプレフィックスまたは電話番号を入力すると、ドロップダウンメニューに客のメールアドレスが表示されます。直接選択してください。以下の画像をご参照ください（「図5」）。</span>
    </p>
    <p class="c0"><span class="c9">1.2、以下の画像（「図6」）は、会員を選択した後に表示される会員情報です。</span></p>
    <p class="c0"><span class="c9">1.3、特別な注意事項：客が会員登録を希望しない場合は、会員情報を入力せずに注文を提出することもできます。</span></p>
    <p class="c0"><span
            class="c9">注意：電話番号またはメールアドレスを入力してもユーザー情報が見つからない場合はどうすればよいですか？「その他2：注文作成時に客が登録していない場合」を参照してください。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 272.00px;"><img
                alt="" src="${image10}"
                style="width: 601.70px; height: 272.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c11">【</span><span class="c14">図5：会員の検索</span><span class="c3">】</span></p>
    <p class="c10 c18"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 258.67px;"><img
                alt="" src="${image2}"
                style="width: 601.70px; height: 258.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c2">【図6：会員情報の表示】</span></p>
    <p class="c0"><span class="c9">第2步：商品を選択</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span
            class="c9">2.1、客が注文したい商品の商品番号を入力して商品を検索します。ドロップダウンメニューには商品の基本情報が表示されます。これには商品番号、通貨、価格、在庫地、販売状況（1は販売中、0は販売停止中）が含まれます。以下の画像をご参照ください（「図7」、「図8」）。</span>
    </p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span
            class="c9">2.2、商品が検索に表示されない場合、商品が作成されていない可能性があります。この場合は、文末の解決策「その他2：注文作成時に商品が作成されていない場合」を参照してください。</span>
    </p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 258.67px;"><img
                alt="" src="${image8}"
                style="width: 601.70px; height: 258.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図7：商品検索</span><span class="c3">】</span></p>
    <p class="c1"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 268.00px;"><img
                alt="" src="${image9}"
                style="width: 601.70px; height: 268.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c3">【図8：商品情報の表示】</span></p>
    <p class="c4"><span class="c7">2.3、商品が選択されると、以下の画像（「図9」）のような状態になります：</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 256.00px;"><img
                alt="" src="${image11}"
                style="width: 601.70px; height: 256.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図9：商品選択後の状態</span><span class="c3">】</span></p>
    <p class="c0"><span class="c9">その他の説明</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;店舗：</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">1.1、現在ログインしている従業員アカウントが管理者などの店舗に所属していない役職の場合、最初のステップで特定の店舗を手動で選択する必要があります。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">1.2、現在ログインしている従業員アカウントが店舗に所属している場合、その店舗がデフォルトで選択され、変更することはできません。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;担当者：</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">現在ログインしているシステムアカウントに所属する従業員が担当者として設定され、変更することはできません。</span></p>
    <p class="c0 c13"><span class="c6">&nbsp; &nbsp; &nbsp; &nbsp; </span></p>
    <p class="c0"><span class="c9">第3步：支払い設定</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">3.1、支払い設定モジュールでは、価格の変更、支払い方法と金額の設定、そして前金と前金なしの設定が可能です。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">3.2、価格変更：以下の画像「図11」のように、価格変更ポップアップで使用可能なポイント、クーポン、および割引額を設定できます。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">3.3、支払い方法：以下の画像「図13」、「図14」のように、支払い方法には現金、銀行振込、クレジットカード、支付宝（Alipay）、その他があります。</span>
    </p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span
            class="c9">3.5、前金/前金なし：ここで設定するのは、注文の「タイプ」を前金に選択した場合、その注文のタイプは前金注文となります。前金注文では、注文作成時に部分金額のみを支払い、残金は顧客と協議の上、約定された時期に回収できます。</span>
    </p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">注意：注文を提出する前に、注文金額、残金、および通貨を確認してください。「図10」を参照ください。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 262.67px;"><img
                alt="" src="${image6}"
                style="width: 601.70px; height: 262.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c8">【図10：注文金額の確認】</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 238.67px;"><img
                alt="" src="${image16}"
                style="width: 601.70px; height: 238.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c8">【図11：価格変更ポップアップ】</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 252.00px;"><img
                alt="" src="${image1}"
                style="width: 601.70px; height: 252.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c8">【図13：支払い方法選択】</span></p>
    <p class="c4"><span class="c7">3.6、支払いが成功すると、自動的にレシートのプレビューおよび印刷ページに移動します。以下の画像（「図14」）をご参照ください。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 324.00px;"><img
                alt="" src="${image12}"
                style="width: 601.70px; height: 324.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c10"><span class="c8">【図15：レシートのプレビューおよび印刷ページ】</span></p>
    <p class="c0"><span class="c9">その他1：注文作成時に商品が作成されていない場合</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">商品の作成は「商品 -
            商品一覧」ページで一時的に行うことができます。その後、商品の情報を補足するために担当者（張）に連絡してください。具体的な操作は以下の通りです。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">1.1 商品一覧ページを開く（「図15」）。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 274.67px;"><img
                alt="" src="${image4}"
                style="width: 601.70px; height: 274.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図15：商品一覧ページ</span><span class="c3">】</span></p>
    <p class="c4"><span class="c7">1.2 「追加」をクリックします（「図16」参照）。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 266.67px;"><img
                alt="" src="${image7}"
                style="width: 601.70px; height: 266.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図16：商品追加</span><span class="c3">】</span></p>
    <p class="c0"><span class="c9">1.3 必須情報を入力します。</span></p>
    <p class="c0"><span class="c9">商品番号、カテゴリー、価格、状態を含みます。商品番号に基づいて在庫地と通貨が自動的に選択されるので、確認してください。</span></p>
    <p class="c0"><span class="c9">これらを入力して商品を作成します。客が写真を必要とする場合、写真グループから一時的に写真を見つけてアップロードします。</span></p>
    <p class="c0"><span class="c9">残りの情報については、必要に応じて出品責任者（張）に連絡して補足してください。</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 265.33px;"><img
                alt="" src="${image5}"
                style="width: 601.70px; height: 265.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図17：商品追加</span><span class="c3">】</span></p>
    <p class="c0"><span class="c9">その他2：注文作成時に客が登録していない場合</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">客に自らウェブサイト（https://ginzaxiaoma.com/index）で登録してもらうように依頼します。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">客が操作方法がわからない場合は、管理画面から会員登録を代行することができます。</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span class="c9">管理画面での操作手順：</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
    <p class="c0"><span
            class="c9">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;サイドバーの「会員」セクションを見つけ、「新規会員登録」をクリックします（「図18」参照）。</span>
    </p>
    <p class="c0"><span
            class="c9">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;または、注文作成ページで「追加」ボタンをクリックして直接登録することもできます（「図19」参照）。</span>
    </p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 257.33px;"><img
                alt="" src="${image13}"
                style="width: 601.70px; height: 257.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図18：新規会員登録</span><span class="c3">】</span></p>
    <p class="c10"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 266.67px;"><img
                alt="" src="${image15}"
                style="width: 601.70px; height: 266.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c11">【</span><span class="c14">図19：会員登録入口</span><span class="c3">】</span></p>
    <p class="c1"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 268.00px;"><img
                alt="" src="${image14}"
                style="width: 601.70px; height: 268.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c1"><span class="c2">【図20：会員情報の入力】</span></p>
    <p class="c0 c5"><span class="c9"></span></p>
</body>

</html>`;
export default ja_Text;
