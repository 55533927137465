import Avatar from 'react-avatar'
import PropTypes from "prop-types"
import ReactTimeAgo from 'react-time-ago';
import {sessionContent} from "./helpers/sessionContentHelper.js"
import {CheckMarkIcon} from "./assets/IMChatIcons.jsx"
import { resetMessageList, setSelectedSession } from '../../store/im-chat-stores/imManagerSettingsSlice.js';
import {useDispatch, useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import { DoubleCheckIcon } from './assets/IMChatIcons.jsx';

export const SessionDetails = ({session}) => {
  const dispatch = useDispatch()
  const { t : translation} = useTranslation()
  const selectedSession = useSelector(state => state.imManagerSettings.selectedSession)
  const { lastMessage, personType } = sessionContent(session, translation)
  const locale = useSelector(state => state.imManagerSettings.locale)

  const handleClick = () => {
    if(session?.id !== selectedSession?.id) {
      dispatch(resetMessageList())
      dispatch(setSelectedSession(session))
    }
  }
  const date = new Date(session?.latestImMessage?.createdAt || session?.createdAt).toLocaleDateString();

  return (
    <div
      onClick={handleClick}
      className={`mb-2 p-[12px] rounded-lg hover:bg-white bg-[#EBEDF7] hover:shadow-md flex items-center space-x-2 cursor-pointer ${selectedSession?.id === session?.id ? 'bg-white shadow-md' : ''}`}
    >
      <Avatar
        size={40}
        name={session?.userName || 'Unknown User'}
        round={true}
        className="w-[15%] bg-green-700"
      />

      <div className="grow flex flex-col space-y-2">
        <div className="flex justify-between items-center space-x-2">
          <p className="mb-0 text-[14px]">
            <ReactTimeAgo
              date={new Date(session?.latestImMessage?.createdAt || session?.createdAt)}
              locale={locale}
            />
          </p>
          <p className="mb-0 text-[12px] text-[#C5C8D9]">
            {date}
          </p>
        </div>
        <div className="flex justify-between items-center space-x-4 overflow-x-hidden">
          <p
            className="text-[12px] mb-0 text-[#797D91] overflow-hidden truncate max-w-[120px] ">
            {lastMessage}
          </p>
          <p className="text-[12px] mb-0">
            {session?.unRead > 0 && <div className="ml-1 h-2 w-2 bg-blue-500 rounded-full"></div>}
            {session?.unRead === 0 && (personType === 'user' ? <CheckMarkIcon />: <DoubleCheckIcon />)}
          </p>
        </div>
      </div>
    </div>
  )
}

SessionDetails.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    unRead: PropTypes.number.isRequired,
    latestImMessage: PropTypes.object,
    createdAt: PropTypes.string,
  }).isRequired
}
