import { FmsAccountManagement } from 'types/fms';
import { ginzaxiaomaApiRequest } from '.';
import { UserInfo } from 'types/user';

export const financialManagementAddOrUpdateAccount = (
  data: FmsAccountManagement
) => {
  return ginzaxiaomaApiRequest.post(
    '/admin/payment-account/create-or-update',
    data
  );
};

export const financialManagementAccountList = (storeId: UserInfo['shop']) => {
  return ginzaxiaomaApiRequest.get<FmsAccountManagement[]>(
    '/admin/payment-account/list',
    {
      params: {
        storeId,
      },
    }
  );
};
