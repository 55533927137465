import image1 from 'assets/images/sop/order-create-sop/pc_ja/image1.png';
import image2 from 'assets/images/sop/order-create-sop/pc_ja/image2.png';
import image3 from 'assets/images/sop/order-create-sop/pc_ja/image3.png';
import image4 from 'assets/images/sop/order-create-sop/pc_ja/image4.png';
import image5 from 'assets/images/sop/order-create-sop/pc_ja/image5.png';
import image6 from 'assets/images/sop/order-create-sop/pc_ja/image6.png';
import image7 from 'assets/images/sop/order-create-sop/pc_ja/image7.png';
import image8 from 'assets/images/sop/order-create-sop/pc_ja/image8.png';
import image9 from 'assets/images/sop/order-create-sop/pc_ja/image9.png';
import image10 from 'assets/images/sop/order-create-sop/pc_ja/image10.png';
import image11 from 'assets/images/sop/order-create-sop/pc_ja/image11.png';
import image12 from 'assets/images/sop/order-create-sop/pc_ja/image12.png';
import image13 from 'assets/images/sop/order-create-sop/pc_ja/image13.png';
import image14 from 'assets/images/sop/order-create-sop/pc_ja/image14.png';
import image15 from 'assets/images/sop/order-create-sop/pc_ja/image15.png';
import image16 from 'assets/images/sop/order-create-sop/pc_ja/image16.png';
import image17 from 'assets/images/sop/order-create-sop/pc_ja/image17.png';
import image18 from 'assets/images/sop/order-create-sop/pc_ja/image18.png';
import image19 from 'assets/images/sop/order-create-sop/pc_ja/image19.png';
import image20 from 'assets/images/sop/order-create-sop/pc_ja/image20.png';
import image21 from 'assets/images/sop/order-create-sop/pc_ja/image21.png';
import image22 from 'assets/images/sop/order-create-sop/pc_ja/image22.png';

const ja_Text = `<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_t7rsyog2c8l0-8 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-7 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-6 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-5 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-4 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-3 {
            list-style-type: none
        }

        .lst-kix_l81gyr3r1f63-3>li:before {
            content: "0025cf   "
        }

        .lst-kix_t7rsyog2c8l0-0>li:before {
            content: "0025cf   "
        }

        .lst-kix_l81gyr3r1f63-2>li:before {
            content: "0025a0   "
        }

        .lst-kix_l81gyr3r1f63-1>li:before {
            content: "0025cb   "
        }

        .lst-kix_l81gyr3r1f63-0>li:before {
            content: "0025cf   "
        }

        .lst-kix_t7rsyog2c8l0-8>li:before {
            content: "0025a0   "
        }

        .lst-kix_t7rsyog2c8l0-7>li:before {
            content: "0025cb   "
        }

        ul.lst-kix_l81gyr3r1f63-2 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-1 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-4 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-3 {
            list-style-type: none
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ul.lst-kix_l81gyr3r1f63-0 {
            list-style-type: none
        }

        .lst-kix_t7rsyog2c8l0-1>li:before {
            content: "0025cb   "
        }

        .lst-kix_l81gyr3r1f63-4>li:before {
            content: "0025cb   "
        }

        .lst-kix_l81gyr3r1f63-5>li:before {
            content: "0025a0   "
        }

        .lst-kix_t7rsyog2c8l0-2>li:before {
            content: "0025a0   "
        }

        .lst-kix_l81gyr3r1f63-6>li:before {
            content: "0025cf   "
        }

        ul.lst-kix_l81gyr3r1f63-6 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-5 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-8 {
            list-style-type: none
        }

        ul.lst-kix_l81gyr3r1f63-7 {
            list-style-type: none
        }

        .lst-kix_t7rsyog2c8l0-3>li:before {
            content: "0025cf   "
        }

        .lst-kix_l81gyr3r1f63-8>li:before {
            content: "0025a0   "
        }

        ul.lst-kix_t7rsyog2c8l0-2 {
            list-style-type: none
        }

        ul.lst-kix_t7rsyog2c8l0-1 {
            list-style-type: none
        }

        .lst-kix_l81gyr3r1f63-7>li:before {
            content: "0025cb   "
        }

        ul.lst-kix_t7rsyog2c8l0-0 {
            list-style-type: none
        }

        .lst-kix_t7rsyog2c8l0-4>li:before {
            content: "0025cb   "
        }

        .lst-kix_t7rsyog2c8l0-6>li:before {
            content: "0025cf   "
        }

        .lst-kix_t7rsyog2c8l0-5>li:before {
            content: "0025a0   "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c23 {
            margin-left: 36pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c3 {
            color: #ff0000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c12 {
            color: #9900ff;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c5 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c21 {
            color: #434343;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c27 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c18 {
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c42 {
            padding-top: 0pt;
            padding-bottom: 6pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c4 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c36 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c6 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c16 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c30 {
            color: #434343;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-style: normal
        }

        .c13 {
            font-size: 12pt;
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 700
        }

        .c19 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c29 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .c7 {
            font-size: 12pt;
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 400
        }

        .c10 {
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 400
        }

        .c28 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c9 {
            font-size: 12pt;
            font-family: "STFangsong";
            font-weight: 400
        }

        .c11 {
            background-color: #ffff00;
            font-family: "STFangsong";
            font-weight: 700
        }

        .c20 {
            font-weight: 400;
            font-size: 11pt;
            font-family: "Arial"
        }

        .c40 {
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c37 {
            background-color: #ffff00;
            color: #000000
        }

        .c15 {
            color: inherit;
            text-decoration: inherit
        }

        .c31 {
            font-size: 14pt;
            font-family: "STFangsong"
        }

        .c32 {
            padding: 0;
            margin: 0
        }

        .c26 {
            font-weight: 400;
            font-family: "STFangsong"
        }

        .c25 {
            font-size: 12pt;
            font-family: "STFangsong"
        }

        .c17 {
            page-break-after: avoid
        }

        .c39 {
            font-size: 26pt
        }

        .c24 {
            font-weight: 700
        }

        .c35 {
            font-weight: 400
        }

        .c22 {
            text-indent: 36pt
        }

        .c41 {
            background-color: #ffff00
        }

        .c33 {
            font-family: "STFangsong"
        }

        .c34 {
            font-size: 16pt
        }

        .c14 {
            color: #ff0000
        }

        .c8 {
            height: 11pt
        }

        .c38 {
            height: 16pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c28 doc-content">
    <p class="c6 c17 title" id="h.ekowdo8wpaon"><span class="c26">How to create a sales order</span></p>
    <p class="c4 c8 c22"><span class="c5"></span></p>
    <p class="c4"><span class="c31 c24">Application scenario description: </span><span class="c31 c35">Creating orders
            in the backend is used by staff to help customers create online orders.</span></p>
    <p class="c4 c8"><span class="c19 c9"></span></p>
    <h2 class="c4 c17" id="h.2n57sw45oszw"><span class="c24 c33">Steps to create an order</span></h2>
    <p class="c4"><span class="c26">1.1、Open the backend：</span><span class="c29 c26"><a class="c15"
                href="https://www.google.com/url?q=https://admin-shop.ginzaxiaoma.com/%25EF%25BC%2589%25EF%25BC%258C&amp;sa=D&amp;source=editors&amp;ust=1718791079800636&amp;usg=AOvVaw1DozmuVdm9lTVgD9TCjvnu">https://admin-shop.ginzaxiaoma.com/</a></span><span
            class="c26">，If you do not have an account or access, please contact the IT department.</span></p>
    <p class="c4"><span class="c26">1.2、In the sidebar, go to "</span><span class="c10">Orders - Create
            Order</span><span class="c26">" to open the create order page as shown in the images below ("</span><span
            class="c10">Figure 1</span><span class="c26">", "</span><span class="c10">Figure 2</span><span
            class="c26">").</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 277.33px;"><img
                alt="" src="${image16}"
                style="width: 601.70px; height: 277.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure 1</span><span class="c3">：Sidebar - Create
            Order】</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 277.33px;"><img
                alt="" src="${image16}"
                style="width: 601.70px; height: 277.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure 2</span><span class="c3">：Create Order Page】</span>
    </p>
    <h3 class="c4 c17" id="h.xolgmqb30uka"><span class="c26">Step 1: Member Selection</span></h3>
    <p class="c4"><span class="c9">2.1、Enter the guest's </span><span class="c7">phone number/email</span><span
            class="c9">&nbsp;to create an order, ensuring the guest is a registered member. For guests who are already
            members, entering the email prefix/phone number will display their email in the dropdown menu for direct
            selection. See figures "</span><span class="c7">Figure 3</span><span class="c9">", "</span><span
            class="c7">Figure 4</span><span class="c9">", "</span><span class="c7">Figure 5</span><span
            class="c9">".</span></p>
    <p class="c4"><span class="c9">2.2、 After selecting the member, choose the customer's shipping address as shown in
            figure "</span><span class="c7">Figure 6</span><span class="c9">".</span></p>
    <p class="c4"><span class="c19 c9">2.3、Special note: If a customer prefers not to register as a user, they can still
            submit an order without filling out the membership information.</span></p>
    <p class="c4"><span class="c9">What should I do if there is no user information found when entering the phone
            number/email? You can refer to [instructions/guidelines].</span><span class="c9 c29"><a class="c15"
                href="#h.7aggdzj33oqt">「Other 3: What to do if the customer is not registered when creating an
                order?」</a></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 281.33px;"><img
                alt="" src="${image9}"
                style="width: 601.70px; height: 281.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure3</span><span class="c3">：Member Selection
            Module】</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 276.00px;"><img
                alt="" src="${image5}"
                style="width: 601.70px; height: 276.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure4</span><span class="c3">：Enter the phone number to
            search for the member.】</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 277.33px;"><img
                alt="" src="${image22}"
                style="width: 601.70px; height: 277.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure5</span><span class="c3">：Enter the email to search
            for the member.】</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 308.00px;"><img
                alt="" src="${image2}"
                style="width: 601.70px; height: 308.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c10">【</span><span class="c13">Figure6</span><span class="c3">：Select delivery
            address】</span></p>
    <p class="c6 c8"><span class="c5"></span></p>
    <h3 class="c4 c17" id="h.nxdb2475wrws"><span class="c24 c33">Step 2: Product Selection</span></h3>
    <p class="c4"><span class="c19 c9">3.1、Enter the product code the guest wishes to order to search for the product.
            The dropdown menu will display basic information about the product, including the product code, currency,
            price, location in stock, and shelf status (1 for on shelf, 0 for off shelf).</span></p>
    <p class="c4"><span class="c9">3.2、If no results are found in the search for a product, it indicates that the
            product has not been created. Please refer to the solution at the end of the document.</span><span
            class="c29 c9"><a class="c15" href="#h.nakzi6b311bx">「Other 2: What to do if the product hasn't been created
                when creating an order?」。</a></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 289.33px;"><img
                alt="" src="${image7}"
                style="width: 601.70px; height: 289.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure7</span><span class="c3">：Product Selection】</span>
    </p>
    <p class="c4"><span class="c9">3.3、Once the product selection is completed, it should appear as shown in
            "</span><span class="c7">Figure 8</span><span class="c19 c9">"</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 280.00px;"><img
                alt="" src="${image17}"
                style="width: 601.70px; height: 280.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure8</span><span class="c3">：An example of selected
            products displayed】</span></p>
    <p class="c0 c8"><span class="c12"></span></p>
    <h3 class="c4 c17" id="h.9ch3jvrdwp4v"><span class="c30 c24 c33">Step 3: Payment Setup</span></h3>
    <p class="c4"><span class="c5">4.1、The payment setup module allows the use of points (in JPY) and discount amount
            (in local currency). Points can be checked directly during the "Member Selection" step for remaining
            balance. Enter the corresponding points and discount amount, or directly input the coupon code.</span></p>
    <p class="c4"><span class="c5">Payment status: Pending payment, Paid. The "Split Payment" button at the bottom is
            only displayed when "Pending payment" is selected.</span></p>
    <p class="c4"><span class="c11">Note: Please carefully confirm the currency and amount corresponding to the discount
            and order total before submitting the order, as shown in "</span><span class="c11 c14">Figure 9</span><span
            class="c18 c37">".</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 309.33px;"><img
                alt="" src="${image8}"
                style="width: 601.70px; height: 309.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c13">【Figure 9: Payment Setup】</span></p>
    <p class="c4"><span class="c9">4.2、If multiple payments are needed, click "</span><span class="c7">Set Multiple
            Payments</span><span class="c9">" as shown in "Figure 10" to create multiple payment orders as in
            "</span><span class="c7">Figure 11</span><span class="c9">".</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 314.67px;"><img
                alt="" src="${image18}"
                style="width: 601.70px; height: 314.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 10: Multiple Payment Setup Button</span><span
            class="c3">】</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 317.33px;"><img
                alt="" src="${image20}"
                style="width: 601.70px; height: 317.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 11: Multiple Payment Setup</span><span
            class="c3">】</span></p>
    <p class="c0 c8"><span class="c12"></span></p>
    <p class="c27"><span class="c19 c9">You can create 2 to 5 separate payments. In the case of 2 payments, the total
            amount will be deducted by the first payment amount and split into 2 payments.</span></p>
    <p class="c27"><span class="c19 c9">For 3 to 5 payments, the total amount minus the first payment amount will be
            evenly split into 3 to 5 payments.</span></p>
    <p class="c27"><span class="c9">Below is an example with 3 payments, where the first payment amount is 1,000,000
            JPY, as shown in "</span><span class="c7">Figure 12</span><span class="c9">" and "</span><span
            class="c7">Figure 13</span><span class="c19 c9">".</span></p>
    <p class="c4 c8"><span class="c19 c9"><br></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 322.67px;"><img
                alt="" src="${image4}"
                style="width: 601.70px; height: 322.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 12: Multiple Payment Setup</span><span
            class="c3">】</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 324.00px;"><img
                alt="" src="${image6}"
                style="width: 601.70px; height: 324.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 13: Display of the multiple payment amounts set
            up</span><span class="c3">】</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <h3 class="c17 c36" id="h.2ndgltcl6nv1"><span class="c24">Step 4: Order Source Settings</span></h3>
    <p class="c16"><span>By default, the source is set to 'website', as shown in the image below. You can manually
            change it.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 324.00px;"><img
                alt="" src="${image14}"
                style="width: 601.70px; height: 324.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c6"><span class="c13 c40">【Figure: Order Source Settings】</span></p>
    <p class="c8 c16"><span class="c19 c20"></span></p>
    <p class="c16 c8"><span class="c19 c20"></span></p>
    <p class="c4"><span class="c9">Confirm the above information and click 'Complete Order Creation' as shown in
            '</span><span class="c7">Figure 14</span><span class="c9">'.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 313.33px;"><img
                alt="" src="${image15}"
                style="width: 601.70px; height: 313.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 14: Secondary Confirmation to Create
            Order</span><span class="c3">】</span></p>
    <p class="c0 c8"><span class="c12"></span></p>
    <p class="c27"><span class="c19 c9">After successful submission, there will be a prompt saying "Order creation
            successful," along with two pieces of information, as shown in "Figure 15":</span></p>
    <ul class="c32 lst-kix_l81gyr3r1f63-0 start">
        <li class="c23 li-bullet-0"><span class="c19 c9">Redirect to order details page: For staff to view the
                customer's order details.</span></li>
        <li class="c23 li-bullet-0"><span class="c9">Link section: Can be copied and provided to the customer for quick
                access to the website to proceed with payment.</span></li>
    </ul>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 316.00px;"><img
                alt="" src="${image13}"
                style="width: 601.70px; height: 316.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 15: Order Creation Successful</span><span
            class="c3">】</span></p>
    <h3 class="c4 c17" id="h.9nyqrfsb3lb7"><span>Step 5: View Order Details</span></h3>
    <p class="c4"><span class="c26">5.1、Click 'Redirect to Order Details' to view the customer's order. This includes
            the order status, operations, basic information, recipient information, product details, cost breakdown,
            multiple payment details, points history, and operation history, as shown in 'Figure 16'.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 316.00px;"><img
                alt="" src="${image19}"
                style="width: 601.70px; height: 316.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 16: Secondary Confirmation to Create
            Order</span><span class="c3">】</span></p>
    <h2 class="c17 c42" id="h.9safa398hjv5"><span class="c24 c31">Other 1: How Customers Pay</span></h2>
    <p class="c4"><span class="c9">Copy the link provided under "Order Successfully Created" or click "Copy Order Link"
            on the customer's order page, as shown in "Figure 15". Send this link to the customer, who can then log in
            to their account to directly access the payment page. The page the customer sees, shown in "Figure 17", will
            display the current payment amount highlighted in yellow and the corresponding buttons below, especially in
            the case of multiple payments where splitting occurs.</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 303.78px; height: 678.50px;"><img
                alt="" src="${image1}"
                style="width: 303.78px; height: 678.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 17: Secondary Confirmation to Create
            Order</span><span class="c3">】</span></p>
    <p class="c0 c8"><span class="c12"></span></p>
    <p class="c4"><span class="c26">On the payment page, credit card is selected by default. It displays credit card
            information, order details (date, number, status), and shipping address information. If the customer wishes
            to change to another payment method, they can click the "arrow" as shown in "Figure 18". Available payment
            methods include: credit card, PayPal, Alipay, UnionPay, WeChat Pay, bank transfer, and cryptocurrency
            payment. Note: Available payment options may vary based on the inventory location of the items in the
            order.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 303.78px; height: 678.50px;"><img
                alt="" src="${image1}"
                style="width: 303.78px; height: 678.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 18: Secondary Confirmation to Create
            Order</span><span class="c3">】</span></p>
    <h2 class="c4 c17 c38" id="h.rwdbfw704zf9"><span class="c1"></span></h2>
    <h2 class="c4 c17 c38" id="h.aykuphw6n18i"><span class="c1"></span></h2>
    <h2 class="c4 c17 c38" id="h.je4qmai5uhqd"><span class="c1"></span></h2>
    <h2 class="c4 c17" id="h.nakzi6b311bx"><span class="c1">Other 2: What if the product hasn't been created when
            placing an order?</span></h2>
    <p class="c4"><span class="c19 c9">You can temporarily create a product on the Products - Product List page.
            Afterwards, you can contact the product manager (张) to provide additional information. Here are the specific
            steps:</span></p>
    <p class="c4 c8"><span class="c19 c9"></span></p>
    <p class="c4"><span class="c9">2.1 Open the Product List page as shown in "</span><span class="c7">Figure
            19</span><span class="c19 c9">".</span></p>
    <p class="c4 c8"><span class="c19 c9"></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 302.67px;"><img
                alt="" src="${image10}"
                style="width: 601.70px; height: 302.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 19: Product List Entry</span><span
            class="c3">】</span></p>
    <p class="c4"><span class="c9">2.2 Click 'Add' as shown in 'Figure 20'.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 318.67px;"><img
                alt="" src="${image12}"
                style="width: 601.70px; height: 318.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 20: Add Product Button</span><span
            class="c3">】</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c4"><span class="c19 c9">2.3 Enter the required information, including product number, category, price,
            and condition. The inventory location and currency will be automatically selected based on the product
            number. Once confirmed, you can proceed to create the product.</span></p>
    <p class="c27"><span class="c19 c9">If the customer requires a photo, you can temporarily upload one from the photo
            library. For any remaining information that needs to be supplemented, please contact the product manager (张)
            for further details.</span></p>
    <p class="c4 c8"><span class="c19 c9"></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 322.67px;"><img
                alt="" src="${image21}"
                style="width: 601.70px; height: 322.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 21: Add Product</span><span class="c10">】</span>
    </p>
    <h2 class="c4 c17" id="h.7aggdzj33oqt"><span class="c1">Other 3: What if the customer is not registered when placing
            an order?</span></h2>
    <p class="c4"><span class="c5">Communicate with the customer to register themselves on the website
            "https://ginzaxiaoma.com/index".</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c4"><span class="c5">If the customer needs assistance, backend support can help create a membership for
            them.</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Backend operation: In the sidebar, navigate to "Members" ---&gt; "Register New
            Regular Member" as shown in "Figure 22".</span></p>
    <p class="c4 c8"><span class="c5"></span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 258.67px;"><img
                alt="" src="${image3}"
                style="width: 601.70px; height: 258.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 22: Member Registration Entry</span><span
            class="c3">】</span></p>
    <p class="c4"><span class="c26">According to "</span><span class="c10">Figure 23</span><span class="c26">", fill in
            the required information marked with a red asterisk (*) for the customer's registration. After successful
            submission, you will receive a success notification.</span></p>
    <p class="c6"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 250.67px;"><img
                alt="" src="${image11}"
                style="width: 601.70px; height: 250.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c10">【</span><span class="c13">Figure 23: Member Registration</span><span
            class="c10">】</span></p>
    <p class="c16 c8"><span class="c19 c20"></span></p>
</body>

</html>`;
export default ja_Text;
