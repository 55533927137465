import { BaseRes } from 'types/base';
import {
  PmsProduct,
  ProductAttributeCode,
  ProductCategory,
  ProductCreateInfo,
  ProductUpdateInfo,
  PriceTagPayload,
  PriceTagInfo,
  ProductCateType,
  ProductCateListPayload,
  ProductCateUpdateNavStatusPayload,
  ProductCateUpdateShowStatusPayload,
  ProductUpdateInfoNewType,
} from 'types/pms';
import request, { ginzaxiaomaApiRequest } from '.';
import { PMS_PRODUCT_STOCK_STATUS } from 'commons/options';

export const getProductCategoryTree = () =>
  request.get<BaseRes<ProductCategory[]>>(
    '/pms/productCategory/list/withChildren'
  );

export const getProductAttributeCodeList = () =>
  request.get<
    BaseRes<
      {
        name: string;
        itemList: ProductAttributeCode[];
      }[]
    >
  >('/pms/productAttributeCode/list');

type GetProductListDto = {
  pageSize: number;
  pageNum: number;
  productCategoryId?: number;
  keyword?: string;
  stockPlace?: string;
  isFilterPromotion?: boolean;
  publishStatus?: number;
  transformPriceToJpyFlag?: number;
  productSn?: string;
  supportCrypto?: boolean;
  start?: string;
  end?: string;
  stockStatuses?: string[];
  stockPlaceList?: string[];
};

export const getProductList = (data: GetProductListDto) =>
  request.post<
    BaseRes<{
      pageNum: number;
      pageSize: number;
      total: number;
      list: PmsProduct[];
    }>
  >('/pms/product/list', data, {
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
    },
  });

export const getProductUpdateInfo = (id: number) => {
  return request.get<BaseRes<ProductUpdateInfo>>(
    `/pms/product/updateInfo/${id}`
  );
};

export const getProductUpdateInfoNew = (id: number) => {
  return ginzaxiaomaApiRequest.get<ProductUpdateInfoNewType>(
    `/admin/pms/product/${id}`
  );
};

export const updateProduct = (productUpdateInfo: ProductUpdateInfo) => {
  return request.post<BaseRes<{ data: number }>>(
    `/pms/product/update/${productUpdateInfo.id}`,
    productUpdateInfo
  );
};

export const createProduct = (productCreateInfo: ProductCreateInfo) => {
  return request.post<BaseRes<{ data: number }>>(
    '/pms/product/create',
    productCreateInfo
  );
};

// 创建支付链接
export const createProductSimple = (productCreateInfo: {
  expiredHours: string;
  name: string;
  price: string;
  productCategoryId: number;
  productSn: string;
}) => {
  return request.post<BaseRes<number>>(
    '/pms/product/create',
    productCreateInfo
  );
};

/** 价签列表 */
export const getPriceTagList = (data: PriceTagPayload) =>
  request.post<
    BaseRes<{
      pageNum: number;
      pageSize: number;
      total: number;
      list: PriceTagInfo[];
    }>
  >('/pms/productTag/page', data, {
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
    },
  });

/** 价签新增 */
export const createPriceTag = (payload: PriceTagInfo) => {
  return request.post<BaseRes<{ data: number }>>(
    '/pms/productTag/create',
    payload
  );
};

/** 编辑 */
export const updatePriceTag = (payload: PriceTagInfo) => {
  return request.put<BaseRes<{ data: number }>>(
    '/pms/productTag/update',
    payload
  );
};

/** 删除价签 */
export const deletePriceTag = (id: string) => {
  return request.delete<BaseRes<PriceTagInfo>>(`/pms/productTag/${id}`);
};

/** 详情 */
export const getPriceTagDetail = (id: string) => {
  return request.get<BaseRes<PriceTagInfo>>(`/pms/productTag/${id}`);
};

/** 刻印 */
export const getStampList = () => {
  return request.get<
    BaseRes<
      {
        name: string;
        itemList: ProductAttributeCode[];
      }[]
    >
  >(`/pms/productAttributeCode/list?name=stamp&categoryId=0&parentId=0`);
};

export const publishProduct = (idList: number[]) => {
  return ginzaxiaomaApiRequest.post('/search-product-es/publish-batch-auth', {
    idList,
  });
};

export const unPublishProduct = (idList: number[]) => {
  return ginzaxiaomaApiRequest.post(
    '/search-product-es/un-publish-batch-auth',
    {
      idList,
    }
  );
};

export const getProductCateList = ({
  id = 0,
  pageNum = 1,
  pageSize = 10,
}: ProductCateListPayload) => {
  return request.get<
    BaseRes<{
      pageNum: number;
      pageSize: number;
      total: number;
      list: ProductCateType[];
    }>
  >(`pms/productCategory/list/${id}`, { params: { pageNum, pageSize } });
};

export const getProductCateUpdateNavStatus = (
  params: ProductCateUpdateNavStatusPayload
) => {
  return request.post(`pms/productCategory/update/navStatus`, null, { params });
};

export const getProductCateUpdateShowStatus = (
  params: ProductCateUpdateShowStatusPayload
) => {
  return request.post(`pms/productCategory/update/showStatus`, null, {
    params,
  });
};

export const getProductCateOperation = (
  id: ProductCateType['id'],
  type: 'enable' | 'disable'
) => {
  return request.post(`pms/productCategory/${type}/${id}`, null);
};

export const getProductCate = (id: ProductCateType['id']) => {
  return request.get<BaseRes<ProductCateType>>(`pms/productCategory/${id}`);
};

export const getProductCateCreate = (data: ProductCateType) => {
  return request.post<BaseRes<ProductCateType>>(
    `pms/productCategory/create`,
    data
  );
};

export const getProductCateUpdate = (data: ProductCateType) => {
  return request.post<BaseRes<ProductCateType>>(
    `pms/productCategory/update/${data.id}`,
    data
  );
};

/** 翻译 */
export const omsTranslate = (data: any) => {
  return ginzaxiaomaApiRequest.post(`/admin/translate`, data);
};

export const getProductStockStatusStatistics = () => {
  return ginzaxiaomaApiRequest.get<
    {
      stockStatus: PmsProduct['stockStatus'];
      count: string;
    }[]
  >('/admin/pms/product-stock-status/statistics');
};

export const getProductStockStatusHistory = (productId: number) => {
  return ginzaxiaomaApiRequest.get<
    {
      id: number;
      productId: number;
      previousStockStatus?: string;
      currentStockStatus: string;
      transactionTime: string;
      note: string;
      createdBy: string;
    }[]
  >(`/admin/pms/product-stock-status/history/${productId}`);
};

export const updateStockStatus = (dto: {
  productIdList: number[];
  newStockStatus: PMS_PRODUCT_STOCK_STATUS;
  note?: string;
}) => {
  return ginzaxiaomaApiRequest.post(
    '/admin/pms/product/update-stock-status',
    dto
  );
};

export const getProductPriceComparison = () => {
  return ginzaxiaomaApiRequest.get<{
    mismatches: {
      dbPrice: number;
      esPrice: number;
      productId: number;
      productSn: string;
      currency: string;
    }[];
  }>('/admin/pms/product-price-comparison');
};
