import { Button, Input, Skeleton } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import {DeleteIcon} from "./assets/IMChatIcons.jsx"
import {
  useCreateOfflineMessageMutation,
  useDeleteOfflineMessageMutation,
  useGetOfflineMessagesQuery,
  useUpdateOfflineMessageMutation
} from "../../store/im-chat-stores/imManagerChatApi.js"
import { useState, useEffect } from "react"
import OfflineMessageModal from "./modals/OfflineMessageModal.jsx"
import {useTranslation} from "react-i18next"
import { useSelector } from 'react-redux';
const { TextArea } = Input

const ManageOfflineMessage = () => {
  const pluginKey = useSelector(state => state.imManagerSettings.pluginKey)
  const { data: offlineMessagesData, refetch: refetchOfflineMessage, isLoading } = useGetOfflineMessagesQuery(
    {pluginKey: pluginKey}
  )
  const [createOfflineMessage] = useCreateOfflineMessageMutation()
  const [updateOfflineMessage] = useUpdateOfflineMessageMutation()
  const [deleteOfflineMessage] = useDeleteOfflineMessageMutation()
  const [offlineMessageModal, setOfflineMessageModal] = useState(false)
  const [messages, setMessages] = useState({})
  const [editingStates, setEditingStates] = useState({})
  const {t} = useTranslation()

  useEffect(() => {
    if (offlineMessagesData) {
      const initialMessages = offlineMessagesData.reduce((acc, message) => {
        acc[message.id] = {
          buttonContent: message.buttonContent,
          replyMessage: message.replyMessage
        }
        return acc
      }, {})
      setMessages(prevMessages => ({...prevMessages, ...initialMessages}))
      setEditingStates({})
    }
  }, [offlineMessagesData])

  const handleInputChange = (id, field, value) => {
    setMessages(prevMessages => ({
      ...prevMessages,
      [id]: {
        ...prevMessages[id],
        [field]: value
      }
    }))
    setEditingStates(prevStates => ({
      ...prevStates,
      [id]: true
    }))
  }

  const handleOfflineMessageSave = async ({data}) => {
    try {
      const result = await createOfflineMessage({
        pluginKey: pluginKey,
        data: data
      }).unwrap()


      setMessages(prevMessages => ({
        ...prevMessages,
        [result.id]: {
          buttonContent: data.buttonContent,
          replyMessage: data.replyMessage
        }
      }))

      setOfflineMessageModal(false)
      refetchOfflineMessage()
    } catch (error) {
      console.error('Failed to create offline message: ', error)
    }
  }

  const handleUpdateOfflineMessage = async (id) => {
    try {
      await updateOfflineMessage({
        offlineResponseId: id,
        data: messages[id]
      })
      setEditingStates(prevStates => ({
        ...prevStates,
        [id]: false
      }))
      refetchOfflineMessage()
    } catch (error) {
      console.error('Failed to update offline message: ', error)
    }
  }

  const handleCancelEdit = (id) => {
    setMessages(prevMessages => ({
      ...prevMessages,
      [id]: {
        buttonContent: offlineMessagesData.find(m => m.id === id).buttonContent,
        replyMessage: offlineMessagesData.find(m => m.id === id).replyMessage
      }
    }))
    setEditingStates(prevStates => ({
      ...prevStates,
      [id]: false
    }))
  }

  const handleDeleteOfflineMessage = async (responseId) => {
    try {
      await deleteOfflineMessage({offlineResponseId: responseId})
      refetchOfflineMessage()
    } catch (error) {
      console.error('Failed to delete offline message: ', error)
    }
  }

  return (
    <>
      <div className="w-full p-3">
        {isLoading ? (
          [...Array(2)].map((_, index) => (
            <div key={index} className="bg-[#F5F6FC] p-3 rounded-lg mb-3">
              <Skeleton active paragraph={{ rows: 3 }} />
            </div>
          ))
        ) : (
          offlineMessagesData?.map((message) => (
            <div key={message.id} className="bg-[#F5F6FC] p-4 rounded-lg mb-3">
              <Input
                placeholder={t('enter_button_content')}
                className="p-[10px]"
                value={messages[message.id]?.buttonContent}
                onChange={(e) => handleInputChange(message.id, 'buttonContent', e.target.value)}
              />
              <TextArea
                placeholder={t('auto_reply_message')}
                rows={3}
                className="mt-2 p-[10px] bg-white border rounded-lg"
                value={messages[message.id]?.replyMessage}
                onChange={(e) => handleInputChange(message.id, 'replyMessage', e.target.value)}
              />
              <div className="mt-2 flex justify-end space-x-2">
                <Button
                  type="primary"
                  disabled={!editingStates[message.id]}
                  onClick={() => handleUpdateOfflineMessage(message.id)}
                >
                  {t('save')}
                </Button>
                <Button
                  disabled={!editingStates[message.id]}
                  onClick={() => handleCancelEdit(message.id)}
                >
                  {t('cancel')}
                </Button>
                <Button
                  danger
                  icon={<DeleteIcon/>}
                  onClick={() => handleDeleteOfflineMessage(message.id)}>
                </Button>
              </div>
            </div>
          ))
        )}
        <div className="mt-3">
          <Button type="text" icon={<PlusOutlined/>} onClick={() => setOfflineMessageModal(true)}>
            {t('add_response')}
          </Button>
        </div>
      </div>
      <OfflineMessageModal
        visible={offlineMessageModal}
        onCancel={() => setOfflineMessageModal(false)}
        onConfirm={(data) => handleOfflineMessageSave({data: {
            buttonContent: data.buttonContent,
            replyMessage: data.replyMessage
          }})}
      />
    </>
  )
}

export default ManageOfflineMessage