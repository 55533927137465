import {Avatar, Button, Dropdown, Form, Input, Menu, Select} from "antd"
import { EditOutlined, UserAddOutlined, LoadingOutlined, DeleteOutlined} from "@ant-design/icons"
import {UploadIcon} from "./assets/IMChatIcons.jsx"
import {useEffect, useState} from "react"
import {
  useCreateProfileMutation,
  useGetProfileInfoQuery,
  useUploadAttachmentMutation
} from "../../store/im-chat-stores/imManagerChatApi.js"
import countries from 'countries-and-timezones'
import {useTranslation} from "react-i18next"
import { useSelector } from 'react-redux';

export const ManagerProfile = () => {
  const [form] = Form.useForm()
  const [uploadAttachment] = useUploadAttachmentMutation()
  const [uploadingImage, setUploadingImage] = useState(false)
  const countryList = Object.values(countries.getAllCountries())
  const timeZoneList = Object.values(countries.getAllTimezones())
  const pluginKey = useSelector(state => state.imManagerSettings.pluginKey)
  const [isEditing, setIsEditing] = useState(false)
  const [updateManagerProfile] = useCreateProfileMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {data: managerProfileInfo, isFetching, error, refetch} = useGetProfileInfoQuery({pluginKey: pluginKey})

  const [profileInfo, setProfileInfo] = useState({})

  useEffect(() => {
    if(!isFetching && !error) {
      setProfileInfo({...managerProfileInfo})
    }
  }, [managerProfileInfo])
  const {t} = useTranslation()

  const menu = (
    <Menu
      style={{
        borderRadius: '10px'
      }}
    >
      <Menu.Item
        key="1"
        icon={<UploadIcon/>}
        style={{
          borderRadius: '8px'
        }}
        onClick={() => document.getElementById('profile-photo-upload').click()}
      >
        <p className="mt-3 ml-2">{t('upload_new_image')}</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
        style={{
          borderRadius: '8px'
        }}
        danger
        onClick={() => {
          setProfileInfo((prevState) => ({
            ...prevState,
            icon: null,
          }))
        }}
      >
        <p className="mt-3 ml-2">{t('delete_image')}</p>
      </Menu.Item>
    </Menu>
  )

  const handleFileUpload = async (e) => {
    setUploadingImage(true)
    setIsEditing(true)
    const file = e?.target?.files[0]
    const maxFileSize = 10 * 1024 * 1024
    if(file?.size <= maxFileSize) {
      let response = await uploadAttachment({file: file}).unwrap()
      setProfileInfo({
        ...profileInfo,
        icon: response.url,
      })
    }
    setUploadingImage(false)
  }

  const handleChange = (e) => {
    setIsEditing(true)
    const { name, value } = e.target
    setProfileInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectChange = (name, value) => {
    setIsEditing(true)
    setProfileInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const resetState = () => {
    setIsEditing(false)
    setIsSubmitting(false)
    if(!isFetching && !error) {
      setProfileInfo((prevState) => ({
        ...prevState,
        ...managerProfileInfo
      }))
    }
    refetch()
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await updateManagerProfile({
      pluginKey: pluginKey,
      data: profileInfo
    })
    setIsEditing(false)
    setIsSubmitting(false)
    refetch()
  }

  const timeZoneValue = (timeZone) => {
    return `${timeZone.name} (UTC${timeZone.utcOffset > 0 ? '+' : ''}${timeZone.utcOffset/60 === 0 ? '' : timeZone.utcOffset/60})`
  }

  return (
    <Form
      className="p-4" form={form} layout="vertical"
    >
      <Form.Item label={t('image')} className="mb-6">
        <div className="rounded-sm">
          <div className="relative w-24 h-24 rounded-lg flex items-center justify-center">
            <Avatar
              size={96}
              loading="lazy"
              src={uploadingImage ? null : profileInfo.icon}
              icon={uploadingImage ? <LoadingOutlined/> : <UserAddOutlined/>}
              className="rounded-2xl bg-gray-200"
            />
            <Dropdown
              overlay={menu}
              trigger={['click']}
              className="absolute -bottom-2 -right-2 bg-white h-[28px] w-[28px]"
            >
              <div className="flex justify-center items-center rounded-full border border-gray-300">
                <EditOutlined/>
              </div>
            </Dropdown>
          </div>
          <input
            id="profile-photo-upload"
            type="file"
            accept=".jpg, .jpeg, .png"
            className="invisible"
            onChange={handleFileUpload}
          />
        </div>
      </Form.Item>
      <Form.Item label={t('name')}>
        <Input
          placeholder={t('enter_name')}
          name="name"
          value={profileInfo?.name || ''}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item label={t('contact_number')}>
        <Input
          placeholder={t('enter_contact_number')}
          name="contactNumber"
          value={profileInfo?.contactNumber || ''}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item label={t('country')}>
        <Select
          value={profileInfo?.country || ''}
          onChange={(value) => handleSelectChange('country', value)}
        >
          {countryList?.map((country) => (
            <Select.Option
              key={country.id}
              value={country.name}
            >
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={t('timezone')}>
        <Select
          value={profileInfo?.timeZone || ''}
          onChange={(value) => handleSelectChange('timeZone', value)}
        >
          {timeZoneList?.map((timeZone) => (
            <Select.Option key={timeZone.name} value={timeZoneValue(timeZone)}>
              {timeZoneValue(timeZone)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="flex gap-2">
        <Button
          type="primary"
          disabled={!isEditing}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {t('save')}
        </Button>
        <Button
          onClick={resetState}
          disabled={!isEditing}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  )
}
