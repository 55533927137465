import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
  DatePicker,
  message,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getProductList,
  getProductPriceComparison,
  getProductStockStatusStatistics,
  publishProduct,
  unPublishProduct,
  updateStockStatus,
} from 'apis/pms';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  PRODUCT_SOURCE_TYPE_OPTION_LIST,
  PROMOTION_TYPE_MAP,
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
  CURRENCY_MAP,
  CURRENCY_MAP_TO_SHOP,
  PMS_PRODUCT_STOCK_STATUS_OPTION_LIST,
  PMS_PRODUCT_STOCK_STATUS,
} from 'commons/options';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18n';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { PageQuery, UnwrapPromise } from 'types/base';
import { PmsProduct, PriceTagInfo, ProductUpdateInfo } from 'types/pms';
import formatTime from 'utils/formatTime';
import setQueryParameters from 'utils/setQueryParameters';
import ImageSliceShow from 'components/image-slice-show';
import { useTodayRateMap } from 'apis/home';
import classNames from 'classnames';
import findCascaderOptionById from 'utils/findCascaderOptionById';
import useResource from 'commons/hooks/useResource';
import getCascaderFilter from 'utils/getCascaderFilter';
import ProductStockStatusHistory from './product-stock-status-history';
import LinkButton from 'components/link-button';
import { useToggle } from 'react-use';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import isProductMatchShop from 'utils/is-product-match-shop';
import useBrandList from 'commons/hooks/use-brand-list';
import getUpdateProductStockStatusOptions from 'utils/get-update-product-stock-status-options';

const { RangePicker } = DatePicker;

type SearchFormData = {
  productCategoryIds?: number[];
  keyword?: string;
  stockPlace?: string;
  isFilterPromotion?: boolean;
  stockStatus?: PMS_PRODUCT_STOCK_STATUS[];
  publishStatus?: number;
  supportCrypto: boolean;
  createTime: Dayjs[];
};

type ProductType = PmsProduct & ProductUpdateInfo;

const BATCH_OPTION_TYPE = {
  BATCH_PRINT: 'BATCH_PRINT', // 批量打印价签
  BATCH_STOCK: 'BATCH_STOCK', // 批量入库
  BATCH_FOR_SALE: 'BATCH_FOR_SALE', // 批量售卖
  BATCH_PUBLISH: 'BATCH_PUBLISH', // 批量上架网站
  BATCH_UN_PUBLISH: 'BATCH_UN_PUBLISH', // 批量下架网站
  BATCH_SALE: 'BATCH_SALE', // 批量卖出
};

const ProductList = () => {
  const [form] = Form.useForm<SearchFormData>();
  const { BrandList, DefaultBrand } = useBrandList();
  const stockStatusFormValue = Form.useWatch('stockStatus', form);
  const {
    productCategoryCascaderOptions,
    colorSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
  } = useAppSelector(selectGlobalInfo);
  const userInfo = useAppSelector(selectUserInfo);
  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<PmsProduct>();
  const [selectedRows, setSelectedRows] = useState<PmsProduct[]>([]);
  const viewPrint = useResource('product-list-view-print');
  const todayRateMap = useTodayRateMap();
  const [updateStockStatusData, setUpdateStockStatus] = useState<{
    productIdList: number[];
    newStockStatus: PMS_PRODUCT_STOCK_STATUS;
    note?: string;
  }>();

  const [productStockStatusStatistics, setProductStockStatusStatistics] =
    useState<UnwrapPromise<ReturnType<typeof getProductStockStatusStatistics>>>(
      []
    );
  useEffect(() => {
    getProductStockStatusStatistics().then((res) => {
      setProductStockStatusStatistics(res);
    });
  }, []);

  const [viewStockStatusProductId, setViewStockStatusProductId] = useState(0);

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const {
        productCategoryIds,
        keyword,
        publishStatus,
        stockPlace,
        isFilterPromotion,
        supportCrypto,
        createTime,
        stockStatus,
      } = form.getFieldsValue();

      let [start, end] = ['', ''];
      if (createTime) {
        [start, end] = [
          dayjs(createTime[0]).startOf('day').format(),
          dayjs(createTime[1]).endOf('day').format(),
        ];
      }

      setQueryParameters({
        pageNum,
        pageSize,
        keyword,
        stockPlace,
        isFilterPromotion,
        publishStatus,
        productCategoryIds,
        supportCrypto,
        start,
        end,
        stockStatus,
      });

      try {
        setLoading(true);

        const {
          data: { list, total },
        } = await getProductList({
          pageNum,
          pageSize,
          keyword,
          stockPlace,
          isFilterPromotion,
          publishStatus,
          productCategoryId: productCategoryIds
            ? productCategoryIds[productCategoryIds.length - 1]
            : undefined,
          // 这个接口默认返回的是转换为日元的价格，加上这个参数并且设置为 0，可以返回商品本来的价格和汇率
          transformPriceToJpyFlag: 0,
          supportCrypto,
          start,
          end,
          stockStatuses: stockStatus,
        });

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed.isFilterPromotion && parsed.isFilterPromotion === 'true') {
      // @ts-ignore
      parsed.isFilterPromotion = true;
    } else {
      // @ts-ignore
      parsed.isFilterPromotion = false;
    }

    // @ts-ignore
    parsed.publishStatus = parsed.publishStatus
      ? Number(parsed.publishStatus)
      : '';
    // @ts-ignore
    parsed.stockPlace = parsed.stockPlace || '';
    // @ts-ignore
    if (parsed.start && parsed.end) {
      // @ts-ignore
      parsed.createTime = [dayjs(parsed.start), dayjs(parsed.end)];
    }

    if (parsed.productCategoryIds) {
      parsed.productCategoryIds = parsed.productCategoryIds
        // @ts-ignore
        .split(',')
        // @ts-ignore
        .map((i) => +i);
    }

    if (parsed.stockStatus) {
      parsed.stockStatus = parsed.stockStatus
        // @ts-ignore
        .split(',');
    }

    form.setFieldsValue(parsed);

    const pageNum = parsed.pageNum ? +parsed.pageNum : 1;
    const pageSize = parsed.pageSize ? +parsed.pageSize : 10;
    setPageNum(pageNum);
    setPageSize(pageSize);

    getDataSource({ pageNum, pageSize });
  }, [form, getDataSource, setPageNum, setPageSize]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const onEdit = useCallback((id: PmsProduct['id']) => {
    // 为改变url 触发 beforeunload
    window.location.href = `/pms/product-edit/${id}`;
  }, []);

  const handlePublishStatusChange = useCallback(
    async (checked: boolean, id: PmsProduct['id']) => {
      if (checked) {
        await publishProduct([id]);
      } else {
        await unPublishProduct([id]);
      }

      getDataSource({ pageNum, pageSize });
    },
    [getDataSource, pageNum, pageSize]
  );

  /** 跳转打印 */
  const handleForwardPrint = useCallback(
    (data: ProductType[]) => {
      if (data.length > 24) {
        message.warning(i18n.t('supports_up_to_18_items'));
        return;
      }
      const newData: PriceTagInfo[] = [];
      data.forEach((d) => {
        const {
          id,
          name,
          productCategoryId,
          productSn,
          costPrice,
          price,
          sourceType,
          attrColor,
          attrMaterial,
          attrHardware,
          rank,
          attrStamp,
          currency,
          createdTime,
          brandName,
          referencePrice,
        } = d;
        // color
        const color: string[] = [];
        attrColor &&
          attrColor.split(',')?.forEach((d: string) => {
            const t = colorSelectOptions.find((dd) => dd.value === d);
            if (t) color.push(t.label);
          });
        // material
        const material: string[] = [];
        const target = findCascaderOptionById(
          productCategoryId,
          productCategoryCascaderOptions
        );
        const productCategoryIds =
          target?.treeIds?.split(',')?.map(Number) || [];
        const mateList =
          materialCascaderOptionsMap[productCategoryIds[0]] || [];
        attrMaterial &&
          attrMaterial.split(',')?.forEach((d: string) => {
            const t = findCascaderOptionById(d, mateList);
            if (t) material.push(t.label);
          });
        //
        const rankStr = rankSelectOptions.find((d) => d.value === rank)?.label;
        //
        const stampStr =
          stampSelectOptions.find((d) => d.value === attrStamp)?.label || '';
        //
        const hardware: string[] = [];
        attrHardware &&
          attrHardware.split(',')?.forEach((value) => {
            const t = hardwareSelectOptions.find((dd) => dd.value === value);
            if (t) hardware.push(t.label);
          });
        newData.push({
          id: `${id}`,
          name,
          productCategoryId: `${productCategoryId}`,
          productSn,
          price,
          costPrice: Number(costPrice) || 0,
          sourceType,
          color: color.toString(),
          material: material.toString(),
          hardware: hardware.toString(),
          rank: rankStr,
          shopId: CURRENCY_MAP_TO_SHOP[currency as 'JPY' | 'HKD' | 'SGD'],
          stamp: stampStr,
          createTime: createdTime,
          brandName,
          referencePrice,
        });
      });

      // 填充数据
      const arrayFill = Array.from({ length: 24 }, (_, i) => {
        if (newData[i]) {
          return { ...newData[i] };
        } else {
          return { id: i };
        }
      });

      localStorage.setItem('price-tag', JSON.stringify(arrayFill));
      window.open('/prints/price-tag');
    },
    [
      productCategoryCascaderOptions,
      materialCascaderOptionsMap,
      rankSelectOptions,
      stampSelectOptions,
      colorSelectOptions,
      hardwareSelectOptions,
    ]
  );

  const columns: ColumnsType<PmsProduct> = useMemo(() => {
    const columnList1: ColumnsType<PmsProduct> = [
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        dataIndex: 'productSn',
        key: 'productSn',
        render: (productSn: string, { id, description }: PmsProduct) => {
          return (
            <>
              <div>{id}</div>
              <div
                className="text-[#69b1ff] cursor-pointer"
                onClick={() => {
                  const host = window.location.host;
                  if (host === 'admin-shop.ginzaxiaoma.com') {
                    window.open(`//ginzaxiaoma.com/product/${id}`);
                  } else {
                    window.open(`//test-shop.ginzaxiaoma.com/product/${id}`);
                  }
                }}
              >
                {description?.trim()
                  ? `${productSn} (${description.trim()})`
                  : productSn}
              </div>
            </>
          );
        },
      },
      {
        dataIndex: 'albumPics',
        key: 'albumPics',
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        align: 'center',
        render: (albumPics: string) => {
          if (!albumPics) return <span>-</span>;
          let picList: string[] = albumPics.split(',');
          return <ImageSliceShow imgList={picList} endSliceNumber={1} />;
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: <Trans i18nKey={LOCALS.product_name} />,
        render: (name: string, product: PmsProduct) => {
          return (
            <div>
              <p>{name}</p>
              <p>
                <Trans i18nKey={LOCALS.brand} />：
                {findLabelByValue(product.brandName, BrandList, DefaultBrand)}
              </p>
            </div>
          );
        },
      },
      {
        key: 'recycleOrder',
        title: i18n.t(LOCALS.ZQDTJFXciM),
        render: ({ recycleOrderId, recycleOrderCode }: PmsProduct) => {
          if (!recycleOrderId) {
            return '-';
          }

          return (
            <LinkButton
              href={`/rrs/recycling-consignment-detail/${recycleOrderId}`}
            >
              {recycleOrderCode || recycleOrderId}
            </LinkButton>
          );
        },
      },
      {
        key: 'stockStatus',
        dataIndex: 'stockStatus',
        title: i18n.t(LOCALS.uMihwkqqts),
        render: (
          stockStatus: PmsProduct['stockStatus'],
          { id }: PmsProduct
        ) => {
          const options = getUpdateProductStockStatusOptions(stockStatus);

          return (
            <div className="flex items-center gap-2">
              <Select
                className="w-28"
                size="small"
                value={stockStatus}
                options={options}
                onChange={(e) => {
                  setUpdateStockStatus({
                    productIdList: [id],
                    newStockStatus: e,
                  });
                }}
              />
              <Button
                type="link"
                onClick={() => {
                  setViewStockStatusProductId(id);
                }}
              >
                {i18n.t(LOCALS.view)}
              </Button>
            </div>
          );
        },
      },
    ];

    const columnList2: ColumnsType<PmsProduct> = [
      {
        key: 'sourceType',
        dataIndex: 'sourceType',
        title: <Trans i18nKey={LOCALS.product_source} />,
        render: (sourceType: PmsProduct['sourceType']) => {
          return findLabelByValue(sourceType, PRODUCT_SOURCE_TYPE_OPTION_LIST);
        },
      },
      {
        dataIndex: 'stockPlace',
        key: 'stockPlace',
        title: <Trans i18nKey={LOCALS.stock_place} />,
        render: (stockPlace: PmsProduct['stockPlace']) => {
          return findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST);
        },
      },
      {
        dataIndex: 'price',
        key: 'price',
        title: <Trans i18nKey={LOCALS.price} />,
        render: (price: number, product: PmsProduct) => {
          const { promotionType } = product;

          if (promotionType === PROMOTION_TYPE_MAP.SPECIAL) {
            return (
              <div>
                <p
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {product.currency} {product.originalPrice.toLocaleString()}
                </p>
                <p
                  style={{
                    color: 'var(--color-danger)',
                  }}
                >
                  {product.currency} {product.price?.toLocaleString() || '-'}
                </p>
              </div>
            );
          }

          // 实际支付金额，按照当前汇率所转换的日元价格
          const rate = todayRateMap[product.currency];
          if (!rate) return '-';
          const JPY = Math.floor(product.price / rate) || '-';

          return (
            <>
              <div>
                {product.currency} {product.price?.toLocaleString() || '-'}
              </div>
              {product.currency !== CURRENCY_MAP.JPY && (
                <div>JPY {JPY.toLocaleString() || '-'}</div>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'createdTime',
        key: 'createdTime',
        title: <Trans i18nKey={LOCALS.created_time} />,
        render: (createdTime: PmsProduct['createdTime']) => {
          return <div>{formatTime(createdTime)}</div>;
        },
      },
      {
        dataIndex: 'entryTime',
        key: 'entryTime',
        title: i18n.t(LOCALS.juusvCbRWo),
        render: (entryTime: PmsProduct['entryTime']) => {
          return <div>{formatTime(entryTime)}</div>;
        },
      },
    ];

    const publishStatusColumn: ColumnsType<PmsProduct>[number] = {
      key: 'publishStatus',
      title: <Trans i18nKey={LOCALS.publish_status} />,
      render: (_: any, { publishStatus, id }: PmsProduct) => {
        return (
          <div className="mb-2 text-center">
            <Switch
              checked={publishStatus === 1}
              onChange={(checked) => {
                handlePublishStatusChange(checked, id);
              }}
            />
          </div>
        );
      },
    };

    const soldInfoColumn: ColumnsType<PmsProduct>[number] = {
      title: i18n.t(LOCALS.bxYXkMWIQe),
      key: 'soldInfo',
      render({ soldTime, orderId }: PmsProduct) {
        if (!orderId) {
          return '-';
        }

        return (
          <div>
            <LinkButton href={`/oms/order-view/${orderId}`}>
              {orderId}
            </LinkButton>
            <br />
            {formatTime(soldTime)}
          </div>
        );
      },
    };

    const optionColumn: ColumnsType<PmsProduct>[number] = {
      key: 'options',
      title: <Trans i18nKey={LOCALS.option} />,
      fixed: 'right',
      render: ({ id }: PmsProduct, record) => {
        return (
          <div>
            {viewPrint && (
              <Button
                type="link"
                onClick={() => handleForwardPrint([record as ProductType])}
              >
                <Trans i18nKey={LOCALS.print} />
              </Button>
            )}

            {isProductMatchShop({
              shopId: userInfo.shop,
              stockPlace: record.stockPlace,
            }) &&
              record.stockStatus === PMS_PRODUCT_STOCK_STATUS.FOR_SALE && (
                <Button
                  type="link"
                  onClick={() => {
                    window.location.href = `/oms/checkout-counter?productSn=${record.productSn}`;
                  }}
                >
                  <Trans i18nKey={LOCALS.checkout_counter} />
                </Button>
              )}

            <Button
              type="link"
              onClick={() => {
                onEdit(id);
              }}
            >
              <Trans i18nKey={LOCALS.edit} />
            </Button>
          </div>
        );
      },
    };

    return [
      ...columnList1,
      publishStatusColumn,
      ...columnList2,
      soldInfoColumn,
      optionColumn,
    ];
  }, [
    BrandList,
    DefaultBrand,
    handleForwardPrint,
    handlePublishStatusChange,
    onEdit,
    todayRateMap,
    userInfo.shop,
    viewPrint,
  ]);

  const batchOptionList = useMemo(() => {
    const options: {
      value: string;
      label: string;
    }[] = [];

    if (viewPrint) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_PRINT,
        label: i18n.t(LOCALS.batch_print),
      });
    }

    // 商品都是待入库状态
    if (
      selectedRows.every(
        (i) => i.stockStatus === PMS_PRODUCT_STOCK_STATUS.PENDING_ENTRY
      )
    ) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_STOCK,
        label: i18n.t(LOCALS.kUJWWIcHRk),
      });
    }

    // 商品都是已入库状态
    if (
      selectedRows.every(
        (i) => i.stockStatus === PMS_PRODUCT_STOCK_STATUS.STOCKED
      )
    ) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_FOR_SALE,
        label: i18n.t(LOCALS.PWWODvsPvK),
      });
    }

    // 商品都是已入库状态 & 下架中状态
    if (
      selectedRows.every(
        (i) => i.stockStatus === PMS_PRODUCT_STOCK_STATUS.FOR_SALE
      ) &&
      selectedRows.every((i) => i.publishStatus === 0)
    ) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_PUBLISH,
        label: i18n.t(LOCALS.rhCgCypWuW),
      });
    }

    // 商品都是已入库状态 & 上架中状态
    if (
      selectedRows.every(
        (i) => i.stockStatus === PMS_PRODUCT_STOCK_STATUS.FOR_SALE
      ) &&
      selectedRows.every((i) => i.publishStatus === 1)
    ) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_UN_PUBLISH,
        label: i18n.t(LOCALS.kWaTarLxzT),
      });
    }

    if (
      selectedRows.every(
        (i) =>
          i.stockStatus === PMS_PRODUCT_STOCK_STATUS.FOR_SALE &&
          isProductMatchShop({
            shopId: userInfo.shop,
            stockPlace: i.stockPlace,
          })
      )
    ) {
      options.push({
        value: BATCH_OPTION_TYPE.BATCH_SALE,
        label: i18n.t(LOCALS.checkout_counter),
      });
    }

    return options;
  }, [selectedRows, userInfo.shop, viewPrint]);
  const [batchOptionType, setBatchOptionType] = useState(undefined);

  const onClickBatchOption = useCallback(async () => {
    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_PRINT) {
      handleForwardPrint(selectedRows as ProductType[]);
      return;
    }

    const productIdList = selectedRows.map((i) => i.id);

    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_STOCK) {
      await updateStockStatus({
        productIdList,
        newStockStatus: PMS_PRODUCT_STOCK_STATUS.STOCKED,
        note: '后台批量操作商品入库',
      });
      window.location.reload();
      return;
    }

    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_FOR_SALE) {
      await updateStockStatus({
        productIdList,
        newStockStatus: PMS_PRODUCT_STOCK_STATUS.FOR_SALE,
        note: '后台批量操作商品售卖',
      });
      window.location.reload();
      return;
    }

    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_PUBLISH) {
      await publishProduct(productIdList);
      window.location.reload();
      return;
    }

    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_UN_PUBLISH) {
      await unPublishProduct(productIdList);
      window.location.reload();
      return;
    }

    if (batchOptionType === BATCH_OPTION_TYPE.BATCH_SALE) {
      window.location.href = `/oms/checkout-counter?productSn=${encodeURIComponent(
        selectedRows.map((i) => i.productSn).join('\n')
      )}`;
      return;
    }
  }, [batchOptionType, handleForwardPrint, selectedRows]);

  const [priceMissMatchOpen, togglePriceMissMatchOpen] = useToggle(false);
  const [priceMissMatchRecord, setPriceMissMatchRecord] = useState<
    UnwrapPromise<ReturnType<typeof getProductPriceComparison>>['mismatches']
  >([]);
  useEffect(() => {
    getProductPriceComparison().then((res) => {
      setPriceMissMatchRecord(res.mismatches);
    });
  }, []);

  return (
    <div>
      <Modal
        onCancel={() => {
          setUpdateStockStatus(undefined);
        }}
        onOk={() => {
          if (updateStockStatusData) {
            updateStockStatus(updateStockStatusData).then(() => {
              window.location.reload();
            });
          }
        }}
        title="变更商品库存状态"
        open={!!updateStockStatusData}
      >
        <Input.TextArea
          rows={5}
          placeholder={i18n.t(LOCALS.remark) || ''}
          value={updateStockStatusData?.note}
          onChange={(e) => {
            if (!updateStockStatusData) return;
            setUpdateStockStatus({
              ...updateStockStatusData,
              note: e.target.value,
            });
          }}
        ></Input.TextArea>
      </Modal>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item
          name="productCategoryIds"
          label={<Trans i18nKey={LOCALS.product_category}></Trans>}
        >
          <Cascader
            changeOnSelect
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={productCategoryCascaderOptions}
            showSearch={{
              filter: getCascaderFilter,
            }}
          />
        </Form.Item>

        <Form.Item
          name="keyword"
          label={<Trans i18nKey={LOCALS.keyword}></Trans>}
        >
          <Input.TextArea
            style={{ minWidth: 250 }}
            placeholder={i18n.t(LOCALS.please_enter) || ''}
          />
        </Form.Item>

        <Form.Item
          name="stockStatus"
          label={i18n.t(LOCALS.uMihwkqqts)}
          initialValue={[]}
          hidden
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: 200 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          >
            {PMS_PRODUCT_STOCK_STATUS_OPTION_LIST.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {i.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="publishStatus"
          label={<Trans i18nKey={LOCALS.publish_status}></Trans>}
          initialValue={''}
        >
          <Select
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          >
            <Select.Option value="">{i18n.t('all')}</Select.Option>
            {PUBLISH_STATUS_OPTION_LIST.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="stockPlace"
          label={<Trans i18nKey={LOCALS.stock_place} />}
          initialValue={''}
        >
          <Select
            style={{ minWidth: 200 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          >
            <Select.Option value="">{i18n.t('all')}</Select.Option>
            {STOCK_PLACE_OPTION_LIST.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="createTime"
          label={<Trans i18nKey={LOCALS.created_time} />}
        >
          <RangePicker
            className={classNames('w-full', {
              isMobile: 'w-[220px]',
            })}
          />
        </Form.Item>

        <Form.Item
          name="isFilterPromotion"
          valuePropName="checked"
          label={<Trans i18nKey={LOCALS.on_sale} />}
        >
          <Switch></Switch>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>
            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
            <Button
              onClick={() => {
                window.location.href = '/pms/product-add';
              }}
            >
              <Trans i18nKey={LOCALS.add} />
            </Button>
          </Space>
        </Form.Item>

        <Form.Item>
          <div className="items-center justify-center flex w-56">
            <Select
              value={batchOptionType}
              onChange={(e) => {
                setBatchOptionType(e);
              }}
              disabled={selectedRows.length === 0}
              placeholder={i18n.t(LOCALS.StzCTlyrrP)}
              options={batchOptionList}
            ></Select>
            <Button
              onClick={onClickBatchOption}
              type="primary"
              disabled={selectedRows.length === 0 || !batchOptionType}
            >
              {i18n.t(LOCALS.confirm)}
            </Button>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="default"
            danger={!!priceMissMatchRecord.length}
            onClick={togglePriceMissMatchOpen}
          >
            {i18n.t(LOCALS.bOYktSqeQU)}（{priceMissMatchRecord.length}）
          </Button>
        </Form.Item>
      </Form>

      <div className="grid grid-cols-2 lg:grid-cols-7 gap-4">
        {PMS_PRODUCT_STOCK_STATUS_OPTION_LIST.filter((i) => {
          return [
            PMS_PRODUCT_STOCK_STATUS.PENDING_ENTRY,
            PMS_PRODUCT_STOCK_STATUS.STOCKED,
            PMS_PRODUCT_STOCK_STATUS.FOR_SALE,
            PMS_PRODUCT_STOCK_STATUS.LENT_OUT,
            PMS_PRODUCT_STOCK_STATUS.RESERVED,
            PMS_PRODUCT_STOCK_STATUS.SOLD,
            PMS_PRODUCT_STOCK_STATUS.INVALID,
          ].includes(i.value);
        }).map(({ value, label }) => {
          const isChecked = stockStatusFormValue?.includes(value);

          return (
            <div
              key={value}
              className={classNames(
                'text-center py-2 cursor-pointer',
                isChecked ? 'bg-black text-white' : 'bg-gray-200'
              )}
              onClick={() => {
                if (isChecked) {
                  form.setFieldValue('stockStatus', []);
                } else {
                  form.setFieldValue('stockStatus', [value]);
                }
                onFinish();
              }}
            >
              {label}（
              {productStockStatusStatistics.find((i) => i.stockStatus === value)
                ?.count || 0}
              ）
            </div>
          );
        })}
      </div>
      <Table
        bordered
        tableLayout="fixed"
        rowSelection={{
          selectedRowKeys: selectedRows.map((d) => d.id) as React.Key[],
          onChange: (_, selectedRows) => setSelectedRows(selectedRows),
        }}
        pagination={{
          total,
          pageSize,
          current: pageNum,
          showTotal: (total) => `${i18n.t('total')} ${total} ${i18n.t('item')}`,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={columns}
      />

      <Modal
        title={i18n.t(LOCALS.rrooMUILfK)}
        closeIcon={false}
        width={'80%'}
        open={!!viewStockStatusProductId}
        footer={null}
        onCancel={() => {
          setViewStockStatusProductId(0);
        }}
      >
        <ProductStockStatusHistory productId={viewStockStatusProductId} />
      </Modal>

      <Modal
        title={i18n.t(LOCALS.bOYktSqeQU)}
        open={priceMissMatchOpen}
        onCancel={togglePriceMissMatchOpen}
        onOk={togglePriceMissMatchOpen}
        width={700}
      >
        <Table
          dataSource={priceMissMatchRecord}
          pagination={false}
          bordered
          columns={[
            {
              title: 'ID',
              dataIndex: 'productId',
              key: 'productId',
              render(productId: number) {
                return (
                  <a
                    href={`/pms/product-edit/${productId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {productId}
                  </a>
                );
              },
            },
            {
              title: i18n.t(LOCALS.product_sn),
              dataIndex: 'productSn',
              key: 'productSn',
            },
            {
              title: i18n.t(LOCALS.FrlyfzfGME),
              dataIndex: 'dbPrice',
              key: 'dbPrice',
              render(price: number, { currency }: { currency: string }) {
                return `${currency} ${price.toLocaleString()}`;
              },
            },
            {
              title: i18n.t(LOCALS.AHBGzxjtrR),
              dataIndex: 'esPrice',
              key: 'esPrice',
              render(price: number, { currency }: { currency: string }) {
                return `${currency} ${price.toLocaleString()}`;
              },
            },
          ]}
        ></Table>
      </Modal>
    </div>
  );
};

export default ProductList;
