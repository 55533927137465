import { OmsOrderMultiplePay, OmsOrderDetail } from 'types/oms';
import { setOmsOrderMultiplePayList } from 'apis/oms';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { useCallback, useMemo } from 'react';
import { Button, message, InputNumber, Table, Form } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CURRENCY_MAP, ORDER_STATUS_MAP, SHOP_MAP } from 'commons/options';
import splitAmount from 'utils/splitAmount';
import { ColumnType } from 'antd/es/table';
import styles from './index.module.scss';

const OmsOrderMultiplePayList = ({
  data,
  omsOrderDetail,
}: {
  data: OmsOrderMultiplePay[];
  omsOrderDetail: OmsOrderDetail;
}) => {
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState(data);

  const currency = useMemo(() => {
    return omsOrderDetail.orderItemList[0].actualCurrency || CURRENCY_MAP.JPY;
  }, [omsOrderDetail.orderItemList]);

  const columns: ColumnType<any>[] = [
    {
      title: <Trans i18nKey={LOCALS.sortId} />,
      dataIndex: 'sortId',
      key: 'sortId',
      width: 80,
    },
    {
      title: <Trans i18nKey={LOCALS['pay_amount']} />,
      dataIndex: 'needPayAmount',
      key: 'needPayAmount',
      width: 200,
      render: (needPayAmount: number, { currency }) => {
        return needPayAmount?.toLocaleString() + ` ${currency}`;
      },
    },
    {
      title: <Trans i18nKey={LOCALS.orderPayId} />,
      dataIndex: 'omsOrderPayId',
      key: 'omsOrderPayId',
      width: 200,
    },
  ];

  async function formHandler() {
    await setOmsOrderMultiplePayList(
      parseInt(id || ''),
      dataSource.filter((item) => !item.omsOrderPayId)
    );

    message.success('Success!');

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const autoPaySetting = useCallback(
    ({ num, firstMoney }: { num: number; firstMoney: number }) => {
      if (!firstMoney) {
        return;
      }

      if (num > 5 || num < 2) {
        message.warning({
          content: '支付笔数建议范围2~5之间',
        });
        return;
      }

      // 计算还剩余多少需要多比支付
      let paymentMoney = 0; // 已经支付的钱
      const paymentList: OmsOrderMultiplePay[] = [];
      for (const row of data) {
        if (row.omsOrderPayId && row.needPayAmount) {
          paymentMoney = paymentMoney + row.needPayAmount;
          paymentList.push(row);
        }
      }

      const startSortId = paymentList.length + 1;

      if (omsOrderDetail.payAmountActualCurrency === null) return;
      splitAmount(
        omsOrderDetail.payAmountActualCurrency - paymentMoney,
        num,
        firstMoney
      ).forEach((needPayAmount, index) => {
        paymentList.push({
          needPayAmount,
          currency,
          sortId: startSortId + index,
        });
      });

      setDataSource(paymentList);
    },
    [omsOrderDetail.payAmountActualCurrency, data, currency]
  );

  // 店铺订单，没有多笔支付功能
  if (
    [SHOP_MAP.GINZA, SHOP_MAP.HONGKONG, SHOP_MAP.SINGAPORE].includes(
      omsOrderDetail.createdFrom
    )
  ) {
    return null;
  }

  return (
    <div className="mb-3">
      {(omsOrderDetail.status === ORDER_STATUS_MAP.TO_BE_PAID ||
        !!dataSource.length) && (
        <div className={styles.title}>
          <Trans i18nKey={LOCALS.MultiplePayList} />
        </div>
      )}

      {omsOrderDetail.status === ORDER_STATUS_MAP.TO_BE_PAID && (
        <Form
          layout="inline"
          onFinish={(data) => {
            autoPaySetting(data);
          }}
        >
          <Form.Item
            name="num"
            label={
              <label className="text-xs">
                {i18n.t(LOCALS.pay_count)}
                <br />
                {`（${i18n.t(LOCALS.transaction_range)}）`}
              </label>
            }
            required
            rules={[{ required: true }]}
            initialValue={2}
          >
            <InputNumber min={2} max={5} className="w-44"></InputNumber>
          </Form.Item>
          <Form.Item
            name="firstMoney"
            label={<label className='text-xs'>{i18n.t(LOCALS.first_amount)}</label>}
            required
            rules={[{ required: true }]}
          >
            <InputNumber className="w-44" suffix={currency}></InputNumber>
          </Form.Item>

          <Form.Item>
            <Button className="mr-2" htmlType="submit">
              <Trans i18nKey={LOCALS.Auto} />
            </Button>

            <Button
              disabled={!dataSource.length}
              onClick={formHandler}
              type="primary"
              htmlType="button"
            >
              {i18n.t(LOCALS.submit)}
            </Button>
          </Form.Item>
        </Form>
      )}

      {(omsOrderDetail.status === ORDER_STATUS_MAP.TO_BE_PAID ||
        !!dataSource.length) && (
        <Table
          size="small"
          bordered
          rowKey={'sortId'}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      )}
    </div>
  );
};
export default OmsOrderMultiplePayList;
