import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {DocumentsIcon, PhotosIcon} from "./assets/IMChatIcons"
import {useTranslation} from "react-i18next";

const AttachmentUploadComponent = ({opened, onClose, handleAttachmentUpload}) => {
  const modalRef = useRef(null)
  const {t} = useTranslation()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (opened) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [opened, onClose])

  const handleFileChange = (e) => {
    e.preventDefault()
    handleAttachmentUpload(e.target.files, e.target.id)
    onClose()
  }

  return (
    <>
      {opened && (
        <div
          id="default-modal"
          tabIndex={-1}
          aria-modal={false}
          role="dialog"
          className="mb-5 ml-5 absolute inset-0 flex items-end justify-start bg-transparent ease-in-out"
        >
          <div ref={modalRef} className="p-2 mb-[75px] bg-white shadow-lg w-auto rounded-[10px]">
            <div>
              <button
                type="button"
                onClick={() => document.getElementById('document-upload').click()}
                className="p-2 flex w-full bg-transparent">
                <div className="p-2 rounded-full bg-[#F7F7F7] flex justify-center items-center">
                  <DocumentsIcon/>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-bold text-start">{t('documents')}</p>
                  <p className="text-start text-xs">{t('share_files_description')}</p>
                </div>
              </button>
              <hr className="p-0"/>
              <button
                type="button"
                onClick={() => document.getElementById('photo-upload').click()}
                className="p-2 flex w-full bg-transparent">
                <div className="p-2 rounded-full bg-[#F7F7F7] flex justify-center items-center">
                  <PhotosIcon/>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-bold text-start">{t('photos')}</p>
                  <p className="text-start text-xs">{t('share_photos_description')}</p>
                </div>
              </button>

              <input
                id="document-upload"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                className="hidden"
                onChange={handleFileChange}
              />

              <input
                id="photo-upload"
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AttachmentUploadComponent.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAttachmentUpload: PropTypes.func.isRequired
}

export default AttachmentUploadComponent
