import image1 from 'assets/images/sop/checkout_counter_sop/pc_cn/image1.png';
import image2 from 'assets/images/sop/checkout_counter_sop/pc_cn/image2.png';
import image3 from 'assets/images/sop/checkout_counter_sop/pc_cn/image3.png';
import image4 from 'assets/images/sop/checkout_counter_sop/pc_cn/image4.png';
import image5 from 'assets/images/sop/checkout_counter_sop/pc_cn/image5.png';
import image6 from 'assets/images/sop/checkout_counter_sop/pc_cn/image6.png';
import image7 from 'assets/images/sop/checkout_counter_sop/pc_cn/image7.png';
import image8 from 'assets/images/sop/checkout_counter_sop/pc_cn/image8.png';
import image9 from 'assets/images/sop/checkout_counter_sop/pc_cn/image9.png';
import image10 from 'assets/images/sop/checkout_counter_sop/pc_cn/image10.png';
import image11 from 'assets/images/sop/checkout_counter_sop/pc_cn/image11.png';
import image12 from 'assets/images/sop/checkout_counter_sop/pc_cn/image12.png';
import image13 from 'assets/images/sop/checkout_counter_sop/pc_cn/image13.png';
import image14 from 'assets/images/sop/checkout_counter_sop/pc_cn/image14.png';
import image15 from 'assets/images/sop/checkout_counter_sop/pc_cn/image15.png';
import image16 from 'assets/images/sop/checkout_counter_sop/pc_cn/image16.png';

const zh_CN_Text = `<htm>
<style type="text/css">
    ul.lst-kix_w30vkn8clxbd-8 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-7 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-4 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-3 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-6 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-5 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-6 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-7 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-8 {
        list-style-type: none
    }

    .lst-kix_97jl0t3cz26e-4>li:before {
        content: "0025cb   "
    }

    .lst-kix_97jl0t3cz26e-6>li:before {
        content: "0025cf   "
    }

    .lst-kix_97jl0t3cz26e-3>li:before {
        content: "0025cf   "
    }

    .lst-kix_97jl0t3cz26e-7>li:before {
        content: "0025cb   "
    }

    .lst-kix_97jl0t3cz26e-0>li:before {
        content: "0025cf   "
    }

    .lst-kix_97jl0t3cz26e-2>li:before {
        content: "0025a0   "
    }

    .lst-kix_97jl0t3cz26e-8>li:before {
        content: "0025a0   "
    }

    .lst-kix_97jl0t3cz26e-1>li:before {
        content: "0025cb   "
    }

    ul.lst-kix_97jl0t3cz26e-0 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-1 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-2 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-3 {
        list-style-type: none
    }

    .lst-kix_w30vkn8clxbd-7>li:before {
        content: "0025cb   "
    }

    .lst-kix_w30vkn8clxbd-8>li:before {
        content: "0025a0   "
    }

    ul.lst-kix_97jl0t3cz26e-4 {
        list-style-type: none
    }

    ul.lst-kix_97jl0t3cz26e-5 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-0 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-2 {
        list-style-type: none
    }

    ul.lst-kix_w30vkn8clxbd-1 {
        list-style-type: none
    }

    .lst-kix_97jl0t3cz26e-5>li:before {
        content: "0025a0   "
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        margin-right: 41.6pt
    }

    .c29 {
        padding-top: 16pt;
        padding-bottom: 4pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        margin-right: 41.6pt
    }

    .c8 {
        padding-top: 0pt;
        padding-bottom: 4pt;
        line-height: 1.75;
        orphans: 2;
        widows: 2;
        text-align: left;
        margin-right: 41.6pt
    }

    .c4 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center;
        margin-right: 41.6pt
    }

    .c11 {
        padding-top: 0pt;
        padding-bottom: 4pt;
        line-height: 1.75;
        orphans: 2;
        widows: 2;
        text-align: center;
        margin-right: 41.6pt
    }

    .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "STFangsong";
        font-style: normal
    }

    .c26 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c7 {
        color: #ff0000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal
    }

    .c9 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c23 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c2 {
        font-family: "STFangsong";
        color: #ff0000;
        font-weight: 400
    }

    .c24 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c34 {
        background-color: #ffffff;
        max-width: 523.4pt;
        padding: 72pt 0pt 72pt 72pt
    }

    .c1 {
        font-size: 12pt;
        font-family: "STFangsong";
        font-weight: 400
    }

    .c14 {
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c25 {
        color: #434343;
        font-weight: 700;
        font-family: "Arial"
    }

    .c6 {
        font-weight: 400;
        font-family: "STFangsong"
    }

    .c16 {
        background-color: #ffff00;
        font-size: 12pt
    }

    .c5 {
        font-size: 12pt;
        color: #ff0000
    }

    .c15 {
        page-break-after: avoid;
        height: 16pt
    }

    .c32 {
        font-weight: 700;
        font-family: "Arial"
    }

    .c33 {
        margin-left: -72pt;
        text-indent: 72pt
    }

    .c3 {
        font-weight: 700;
        font-family: "STFangsong"
    }

    .c30 {
        color: inherit;
        text-decoration: inherit
    }

    .c27 {
        font-weight: 400;
        font-family: "Arial"
    }

    .c17 {
        color: #ff0000
    }

    .c19 {
        font-size: 16pt
    }

    .c31 {
        color: #434343
    }

    .c35 {
        font-size: 12pt
    }

    .c10 {
        height: 11pt
    }

    .c28 {
        font-size: 26pt
    }

    .c22 {
        font-size: 11pt
    }

    .c12 {
        font-size: 14pt
    }

    .c18 {
        text-indent: 36pt
    }

    .c20 {
        page-break-after: avoid
    }

    .c21 {
        background-color: #ffff00
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
</style>

<body class="c34 doc-content">

    <div>
        <p class="c10 c26"><span class="c9 c27 c22"></span></p>
    </div>
    <p class="c11 c20 title" id="h.ekowdo8wpaon"><span class="c6">收银台如何创建</span><span class="c2">销售订单</span></p>
    <p class="c8 c10"><span class="c0"></span></p>
    <p class="c8"><span class="c3 c12">应用场景说明</span><span class="c6 c12">：</span><span class="c1">应用于</span><span
            class="c9 c1">门店为消费者创建销售订单。</span></p>
    <p class="c8 c10"><span class="c9 c1"></span></p>
    <h2 class="c8 c20" id="h.2n57sw45oszw"><span class="c3">创建订单步骤</span></h2>
    <p class="c8"><span class="c6">1.1、打开后台：</span><span class="c23 c6"><a class="c30"
                href="https://www.google.com/url?q=https://admin-shop.ginzaxiaoma.com/%25EF%25BC%2589%25EF%25BC%258C&amp;sa=D&amp;source=editors&amp;ust=1718777761719345&amp;usg=AOvVaw0Ts4OvYQWPAn4sHjhsQMtJ">https://admin-shop.ginzaxiaoma.com/</a></span><span
            class="c6">，如果无账号、无权限请联络IT部门</span></p>
    <p class="c8"><span class="c6">1.2、在侧边栏</span><span class="c3 c17">「订单-收银台」</span><span
            class="c6">打开收银台页面如下图</span><span class="c3 c17">「图1」</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 638.11px; height: 321.80px;"><img
                alt="" src="${image14}"
                style="width: 638.11px; height: 321.80px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c2">【</span><span class="c5 c3">图1</span><span class="c2">：侧边栏-收银台】</span></p>
    <p class="c11 c10"><span class="c7 c6"></span></p>
    <p class="c4 c10"><span class="c7 c32"></span></p>
    <h3 class="c20 c29" id="h.1jplioamg656"><span class="c14 c12 c25">第1步：选择会员</span></h3>
    <p class="c8"><span class="c1">1</span><span class="c1">.1、输入需要创建订单客人的</span><span
            class="c5 c3">手机号/邮箱</span><span
            class="c1">，需要确保客人已注册为会员。已经成为会员的客人，输入邮箱前缀/手机号码会在下拉框中出现客人邮箱，直接选择即可。如下图</span><span
            class="c5 c3">「图5」</span><span class="c9 c3 c35">。</span></p>
    <p class="c8"><span class="c1">1.2、下图</span><span class="c5 c3">「图6」</span><span
            class="c9 c1">为选择会员后所展示的会员信息</span>
    </p>
    <p class="c8"><span class="c1">1.3、</span><span class="c3 c35">特殊说明</span><span
            class="c1">：如果客户不想注册会員，</span><span class="c24 c1">不填写会员也可以提交订单</span><span class="c9 c1">。</span></p>
    <p class="c8"><span class="c3 c16">注意：</span><span class="c1 c21">如果输入手机号码/邮箱没有查询到用户信息怎么办？可参考</span><span
            class="c1 c21 c24"><a class="c30" href="#h.7aggdzj33oqt">「其他2：创建订单时客人没注册怎么办」</a></span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 639.34px; height: 321.50px;"><img
                alt="" src="${image12}"
                style="width: 639.34px; height: 321.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c2">【</span><span class="c5 c3">图5</span><span class="c7 c6">：搜索会员】</span></p>
    <p class="c11 c33"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 646.85px; height: 322.50px;"><img
                alt="" src="${image10}"
                style="width: 646.85px; height: 322.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c3 c7">【图6：会员信息展示】</span></p>
    <h3 class="c8 c20" id="h.nxdb2475wrws"><span class="c14 c3 c12 c31">第2步：选择商品</span></h3>
    <p class="c8"><span class="c1">2</span><span
            class="c1">.1、输入客人需要下单的商品商品编号查找商品，下拉框会出现商品的基本信息。包括商品编号、币种、价格、在库地、上架状态（1为上架，0为下架）。如下图</span><span
            class="c5 c3">「图7」</span><span class="c1">、</span><span class="c14 c5 c3">「图8」</span></p>
    <p class="c8"><span class="c1">2</span><span class="c1">.2、假设搜索不到商品，代表商品没有被创建，可以参考文末解决方案</span><span
            class="c1 c23"><a class="c30" href="#h.nakzi6b311bx">「其他2：创建订单时候商品没创建怎么办？」。</a></span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 641.32px; height: 322.50px;"><img
                alt="" src="${image7}"
                style="width: 641.32px; height: 322.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图7</span><span class="c7 c6">：商品选择】</span></p>
    <p class="c4"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 645.50px; height: 322.75px;"><img
                alt="" src="${image13}"
                style="width: 645.50px; height: 322.75px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c7 c6">【图8：选择商品】</span></p>
    <p class="c8"><span class="c1">2</span><span class="c1">.3、商品选择好后如下</span><span class="c5 c3">「图9」</span><span
            class="c9 c1">状态：</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 643.50px; height: 319.91px;"><img
                alt="" src="${image6}"
                style="width: 643.50px; height: 319.91px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图9</span><span class="c7 c6">：选择好商品的展示示例】</span></p>
    <p class="c4 c10"><span class="c7 c6"></span></p>
    <p class="c4 c10"><span class="c0"></span></p>
    <p class="c13"><span class="c0">其他说明</span></p>
    <p class="c13"><span class="c6">1、店铺：</span></p>
    <p class="c13 c18"><span>1.1、当前登录的员工账户如果是管理员等没有门店归属的角色，第一步则需要手动选择具体门店</span></p>
    <p class="c13 c18"><span>1.</span><span>2、当前登录的员工账户如果有门店归属，则门店默认为当前员工归属的门店，且不可更改</span></p>
    <p class="c10 c13"><span class="c0"></span></p>
    <p class="c13"><span class="c0">2、担当者：当前登录系统的账号所归属的员工，不可更改</span></p>
    <p class="c13"><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <h3 class="c8 c20" id="h.9ch3jvrdwp4v"><span class="c14 c3 c31 c12">第3步：支付设置</span></h3>
    <p class="c8"><span class="c6">3</span><span class="c6">.1、支付设置模块，</span><span
            class="c3 c17">可以改价、设置收款方式及金额</span><span class="c3 c17">、可设置为订金及非订金</span><span class="c0">。</span></p>
    <p class="c8"><span class="c6">3.2、改价：如下图</span><span class="c3 c17">「图11」</span><span
            class="c0">所示，在改价弹窗中可以设置可抵扣的积分、优惠券以及设置优惠金额</span></p>
    <p class="c8"><span class="c6">3.3、收款方式：如下图</span><span class="c3 c17">「图13」、「图14」</span><span
            class="c0">收款方式具体分为现金收款、银行转账、信用卡、支付宝、其他。</span></p>
    <p class="c8"><span
            class="c0">3.5、订金/非订金：此处设置针对的是订单“类型”选择订金时，那么当前订单的类型为订金订单，订金订单则可以在创建订单时只支付部分金额，尾款可跟客户协商一致后在约定的时间内进行收取。</span>
    </p>
    <p class="c8"><span class="c3 c21">注意：</span><span class="c6 c21">在提交订单前请</span><span
            class="c6 c21">确认清楚订单金额、剩余金额以及币种。如</span><span class="c9 c3 c21 c22">「图10」</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 641.72px; height: 320.86px;"><img
                alt="" src="${image5}"
                style="width: 641.72px; height: 320.86px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c14 c5 c3">【图10：支付设置】</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 643.58px; height: 340.50px;"><img
                alt="" src="${image8}"
                style="width: 643.58px; height: 340.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c14 c5 c3">【图11：改价】</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 642.91px; height: 336.19px;"><img
                alt="" src="${image16}"
                style="width: 642.91px; height: 336.19px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c14 c5 c3">【图13：支付方式设置】</span></p>
    <p class="c11 c10"><span class="c14 c5 c3"></span></p>
    <p class="c11 c10"><span class="c14 c5 c3"></span></p>
    <p class="c11 c10"><span class="c5 c3 c14"></span></p>
    <p class="c8"><span class="c1">3.6、支付成功后将自动跳转到小票预览及打印页面，如下图</span><span class="c14 c5 c3">「图14」</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 625.25px; height: 339.50px;"><img
                alt="" src="${image4}"
                style="width: 625.25px; height: 339.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c11"><span class="c5 c3">【图15：小票预览及】</span></p>
    <p class="c11 c10"><span class="c7 c6 c21"></span></p>
    <p class="c11 c10"><span class="c0"></span></p>
    <p class="c4 c10"><span class="c9 c3 c12"></span></p>
    <h2 class="c8 c15" id="h.aykuphw6n18i"><span class="c9 c3 c12"></span></h2>
    <h2 class="c8 c15" id="h.je4qmai5uhqd"><span class="c9 c3 c12"></span></h2>
    <h2 class="c8 c20" id="h.nakzi6b311bx"><span class="c9 c3 c12">其他1：创建订单时候商品没创建怎么办？</span></h2>
    <p class="c8"><span class="c1 c9">可在商品-商品列表页面，临时创建一个商品，之后可以联络出品负责人（张）进行信息补充。具体操作如下：</span></p>
    <p class="c8"><span class="c1">1.1 打开商品列表页面</span><span class="c14 c5 c3">「图15」</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 332.00px;"><img
                alt="" src="${image2}"
                style="width: 601.70px; height: 332.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c3 c5">图15</span><span class="c7 c6">：商品列表入口】</span></p>
    <p class="c8"><span class="c1">1</span><span class="c1">.2 点击</span><span class="c5 c3">「添加」</span><span
            class="c1">如</span><span class="c14 c5 c3">「图16」</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 332.00px;"><img
                alt="" src="${image15}"
                style="width: 601.70px; height: 332.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图16</span><span class="c7 c6">：添加商品按钮】</span></p>
    <p class="c8 c10"><span class="c0"></span></p>
    <p class="c8 c10"><span class="c0"></span></p>
    <p class="c8"><span class="c9 c1">1.3 输入必填信息</span></p>
    <p class="c8"><span class="c9 c1">包括商品编号、分类、价格、成色。库存地和币种会自动根据编号进行选择，确认一下即可。</span></p>
    <p class="c8"><span class="c9 c1">填写完成这些就可以创建好商品。如客人需要照片可在照片群临时找一张照片上传。</span></p>
    <p class="c8"><span class="c1">剩余的信息如果需要补充，可以联络出品负责人（张）进行补充。</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 606.77px; height: 605.90px;"><img
                alt="" src="${image11}"
                style="width: 606.77px; height: 605.90px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图17</span><span class="c2">：添加商品】</span></p>
    <h2 class="c8 c20" id="h.7aggdzj33oqt"><span class="c3 c12">其他2：创建订单时客人没注册怎么办？</span></h2>
    <p class="c8"><span class="c6">与客人沟通请客人自行在网站</span><span
            class="c3 c17">「https://ginzaxiaoma.com/index」</span><span class="c0">上进行注册</span></p>
    <p class="c8"><span class="c6">若</span><span class="c0">客人不会操作，后台可帮助客人创建会员</span></p>
    <p class="c8"><span class="c6">后台操作：在侧边栏找到</span><span class="c3 c17">「会员」---&gt;「注册新规会员」</span><span
            class="c6">如</span><span class="c7 c3">「图18」。也可以如「图19」直接在创建订单页面点击“添加”</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 639.65px; height: 376.39px;"><img
                alt="" src="${image9}"
                style="width: 639.65px; height: 376.39px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图18</span><span class="c7 c6">：创建会员入口】</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 639.65px; height: 334.48px;"><img
                alt="" src="${image3}"
                style="width: 639.65px; height: 334.48px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c2">【</span><span class="c5 c3">图19</span><span class="c7 c6">：创建会员入口】</span></p>
    <p class="c4"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 653.64px; height: 341.50px;"><img
                alt="" src="${image1}"
                style="width: 653.64px; height: 341.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c4"><span class="c7 c3">【图20：填写会员信息】</span></p>
</body>
</htm>`;
export default zh_CN_Text;
