import { CustomerSession } from './CustomerSession';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SkeletonLoader } from './loader/SkeletonLoader';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CustomerSessionList = () => {
  const authToken = useSelector((state) => state.imManagerSettings.authToken);
  const baseUrl = useSelector((state) => state.imManagerSettings.baseUrl);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [customerSessions, setCustomerSessions] = useState([]);

  const fetchData = useCallback(
    async (page) => {
      if (!baseUrl) return;
      if (!authToken) return;

      try {
        const response = await fetch(
          `${baseUrl}/im-chat/manager/im-chat-list?pageNum=${page}&status=1&pageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const customerSessionData = await response.json();
        setTotal(customerSessionData.total);

        if (customerSessionData.list.length > 0) {
          setCustomerSessions((prevSessions) => [
            ...prevSessions,
            ...customerSessionData?.list,
          ]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    },
    [baseUrl, authToken]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const fetchNext = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  }, [fetchData, page]);

  return (
    <div
      id="scrollableDiv2"
      className="px-1 overflow-y-scroll !h-[90%] im-chat-scrollbar scroll-smooth"
    >
      <InfiniteScroll
        dataLength={customerSessions.length}
        next={fetchNext}
        hasMore={total === null || total > customerSessions.length}
        loader={<SkeletonLoader />}
        scrollableTarget="scrollableDiv2"
      >
        {customerSessions.map((session) => {
          return <CustomerSession key={session.id} session={session} />;
        })}
      </InfiniteScroll>
    </div>
  );
};
