import { Col, Row } from 'antd'
import CustomerInbox from "../../components/im-chat-admin-components/CustomerInbox"
import {Sessions} from "../../components/im-chat-admin-components/Sessions.jsx"
import "../../components/im-chat-admin-components/assets/im_chat_styles.css"
import { useDispatch, useSelector } from 'react-redux';
import {CustomerDetails} from "../../components/im-chat-admin-components/CustomerDetails.jsx"
import {Messages} from "../../components/im-chat-admin-components/Messages.jsx"
import { useTranslation } from 'react-i18next'
import {useEffect} from "react"
import PropTypes from "prop-types"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ja from 'javascript-time-ago/locale/ja'
import zh from 'javascript-time-ago/locale/zh'
import {
  resetAuthToken,
  resetBaseUrl,
  resetLocale,
  resetPluginKey,
  storeAuthToken,
  storeBaseUrl,
  storeLocale,
  storePluginKey
} from '../../store/im-chat-stores/imManagerSettingsSlice';
import { NoSessionSelected } from '../../components/im-chat-admin-components/NoSessionSelected';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ja)
TimeAgo.addLocale(zh)

const IMChatInbox = ({authToken, pluginKey, baseUrl, locale}) => {
  const { selectedCustomer, selectedSession } = useSelector(state => state.imManagerSettings)
  // const { i18n } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAuthToken())
    dispatch(resetBaseUrl())
    dispatch(resetLocale())
    dispatch(resetPluginKey())

    if(authToken) {
      dispatch(storeBaseUrl(baseUrl))
      dispatch(storeAuthToken(authToken))
      dispatch(storePluginKey(pluginKey))
      dispatch(storeLocale(locale))
    }

    // i18n.changeLanguage(locale)
  }, [baseUrl, locale, pluginKey, authToken, dispatch])

  return (
    <Row className='-m-4 overflow-hidden h-[91vh]'>
      <Col
        span={5}
        className="bg-[#EBEDF7] h-[100%] pr-[1px]"
      >
        <CustomerInbox/>
      </Col>
      {
        selectedCustomer && (
          <Col
            span={5}
            className="bg-[#EBEDF7] border-x-4 border-[#DADDEB] h-[100%]"
          >
            <Sessions/>
          </Col>
        )
      }
      {
        selectedSession && (
          <>
            <Col span={9} className="h-[100%]">
              <Messages/>
            </Col>
            <Col span={5} className="h-[100%]">
              <CustomerDetails/>
            </Col>
          </>
        )
      }
      {
        !selectedCustomer ? (
          <Col
            span={19}
            className="bg-[#EBEDF7] h-[100%]"
          >
            <NoSessionSelected />
          </Col>
        ) : !selectedSession ? (
          <Col
            span={14}
            className="bg-[#EBEDF7] h-[100%]"
          >
            <NoSessionSelected />
          </Col>
        ) : null
      }
    </Row>
  )
}

export default IMChatInbox

IMChatInbox.propTypes = {
  authToken: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  pluginKey: PropTypes.string.isRequired,
}

