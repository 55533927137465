import { DownloadIcon, FileIconManager, FileIconUser } from './assets/IMChatIcons.jsx';
import PropTypes from "prop-types"
import {handleDownload} from "./helpers/downloadHelper.js"

export const AttachmentMessage = ({personType, originalName, sizeString, imMessageBlockList}) => (
  <div className={`flex items-center gap-2 ${personType === "user" ? "flex-row" : "flex-row-reverse"}`}>
    <div
      className={
        `gap-2 max-w-[70%] flex justify-start items-center p-3 rounded-lg ${personType === "user" ? "bg-gray-100 text-black" : "bg-blue-500 text-white"}`
      }
    >
      <div className="w-10">
        { personType === "user" ? <FileIconUser/> : <FileIconManager/>}
      </div>
      <div>
        <p
          className={`text-[14px] mb-2 font-bold break-all ${personType === "user" ? "text-black" : "text-white"}`}
        >
          {originalName}
        </p>
        <p
          className={`text-[12px] mb-0 font-medium ${personType === "user" ? "text-gray-700" : "text-white"}`}
        >
          {sizeString}
        </p>
      </div>
    </div>
    <button
      onClick={() => handleDownload(imMessageBlockList)}
      className="relative bg-blue-500 rounded-full w-[28px] h-[28px] flex items-center justify-center text-xl cursor-pointer">
      <DownloadIcon/>
    </button>
  </div>
)

AttachmentMessage.propTypes = {
  personType: PropTypes.string.isRequired,
  originalName: PropTypes.string.isRequired,
  sizeString: PropTypes.string.isRequired,
  imMessageBlockList: PropTypes.array.isRequired
}