import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import useBrandList from 'commons/hooks/use-brand-list';

export enum DempyouType {
  // 買取伝票（お客様控え）
  recycle_customer = 'recycle_customer',
  // 買取伝票
  recycle_store = 'recycle_store',

  // 委託伝票（お客様控え）
  sell_customer = 'sell_customer',
  // 委託伝票
  sell_store = 'sell_store',
}

export const DempyouTypeNameMap = {
  [DempyouType.recycle_customer]: '買取伝票（お客様控え）',
  [DempyouType.recycle_store]: '買取伝票',
  [DempyouType.sell_customer]: '委託伝票（お客様控え）',
  [DempyouType.sell_store]: '委託伝票',
};

export type DempyouPrintDto = {
  code: string;
  date: string;
  payType: string;
  staff: string;
  userInfo: string;
  dempyouType: DempyouType;
  totalPrice: string | number;
  prints?: string; // 是否打印
  productList: {
    productName: string;
    productPrice: string | number;
    guestRemarks: string;
    code: string;
    productSn: string;
    brandName: string;
  }[];
};

const DempyouPrint = () => {
  const [state, setState] = useState<DempyouPrintDto[]>([]);
  const { SourceBrandList } = useBrandList();
  useEffect(() => {
    const body = decodeURIComponent(
      queryString.parse(window.location.search).body as string
    );
    const bodyParse = JSON.parse(body) as DempyouPrintDto[];
    setState(bodyParse);
    if (bodyParse[0].prints) {
      setTimeout(() => {
        window.print();
        window.close();
      }, 666);
    }
  }, []);

  const A4Render = useCallback(
    (data: DempyouPrintDto, i: number) => {
      const len = state[0].productList.length;
      // 多个商品不显示伝票番号
      const headList = () => {
        let list = [
          ['伝票番号', data.code],
          ['受付日', data.date],
          ['担当者', data.staff],
          ['ご清算区分', data.payType],
        ];
        if (len > 1) {
          list.shift();
        }
        return list;
      };

      return (
        <div
          key={i}
          className={classNames(
            'w-[594.3pt] h-[840.51pt] bg-white py-4 px-10',
            styles.dempyou
          )}
        >
          <div
            className="bg-black text-white px-1 mb-12"
            // https://blog.csdn.net/weixin_45122120/article/details/103269099
            // https://developer.mozilla.org/zh-CN/docs/Web/CSS/print-color-adjust
            style={{ printColorAdjust: 'exact' }}
          >
            ASU BRAND
          </div>

          <div className="mb-6">
            <div className="flex items-center relative">
              <div>
                {headList().map(([label, value], index) => {
                  return (
                    <div key={index} className="border-b mb-1">
                      <span className="italic w-20 inline-block font-semibold">
                        {label}
                      </span>
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>

              <h1 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                {DempyouTypeNameMap[data.dempyouType]}
              </h1>

              {[DempyouType.recycle_store, DempyouType.sell_store].includes(
                data.dempyouType
              ) && (
                <div className="absolute right-4 -top-4 border px-4 py-2">
                  買取方法: 店頭
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center mb-4">
            <div className="border p-px basis-[70%] shrink-0">
              <div className="border p-2">
                {[0, 1, 2, 3].map((index) => {
                  const text = data.userInfo.split('\n')[index] || '';
                  return (
                    <div key={index} className="border-b mb-2 h-4">
                      {text}
                    </div>
                  );
                })}
              </div>
            </div>

            {[DempyouType.recycle_customer, DempyouType.sell_customer].includes(
              data.dempyouType
            ) && (
              <div className="ml-8">
                ASU BRAND 株式会社
                <br />
                GINZA XIAOMA
                <br />
                東京都中央区銀座7-6-11
                <br />
                ミクニ銀座 7 ビル1階
                <br />
                TEL 03-6264-5267
                <br />
                登録番号:T6011101072905
              </div>
            )}
          </div>

          <div
            className={
              [DempyouType.recycle_store, DempyouType.sell_store].includes(
                data.dempyouType
              )
                ? 'h-[485pt]'
                : 'h-[510pt]'
            }
          >
            <table className="w-full h-full">
              <thead>
                <tr
                  className="bg-black text-white"
                  style={{ printColorAdjust: 'exact' }}
                >
                  <th className="p-2 w-10">{len === 1 ? '' : '注文番号'}</th>
                  <th className="p-2 w-32">ブランド</th>
                  <th className="p-2">商品名</th>
                  <th className="p-2 w-32">金額(税込)</th>
                </tr>
              </thead>
              <tbody>
                {data.productList.map((d, i) => (
                  <tr className="h-8">
                    <td className="p-2">{len === 1 ? i + 1 : d.code}</td>
                    <td className="p-2">
                      {/* 仅显示日文 */}
                      {SourceBrandList.find((dd) => dd.value === d.brandName)
                        ?.nameJa || SourceBrandList[0].nameJa}
                    </td>
                    <td className="p-2">
                      {[
                        DempyouType.recycle_store,
                        DempyouType.sell_store,
                      ].includes(data.dempyouType) && <>{d.productSn}:</>}
                      {d.productName}
                      <div className="border-b my-1" />
                      {d.guestRemarks}
                    </td>
                    <td className="p-2 text-right">
                      {Number(d.productPrice).toLocaleString()}
                    </td>
                  </tr>
                ))}
                {Array.from({ length: 12 - data.productList.length }).map(
                  (value, index) => {
                    return (
                      <tr className="h-8" key={index}>
                        <td className="p-2"></td>
                        <td className="p-2"></td>
                        <td className="p-2"></td>
                        <td className="p-2"></td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            <div className="border-l border-r px-2 py-1">
              <div className="flex justify-between">
                <div className="text-[10px]">
                  {[
                    DempyouType.recycle_customer,
                    DempyouType.recycle_store,
                  ].includes(data.dempyouType) && (
                    <>
                      ご契約後のお買戻しはお受付できませんのでご了承ください。
                      <br />
                      ご契約後、商品が不正商品と発覚した場合、全額返金いただく場合があります。
                    </>
                  )}

                  {[DempyouType.sell_customer, DempyouType.sell_store].includes(
                    data.dempyouType
                  ) && (
                    <>
                      ○ 本商品は、お預かり日より１か月以上お預かりするものとし、
                      <br />
                      １か月未満での委託販売中止は基本出来ないものとします（要ご相談）
                      <br />
                      ○
                      本商品の売却が成立した場合、決済確定後にお知らせいたします。
                      <br />
                      お知らせ後はご相談によりお振込みまたは現金にて表記の金額をお支払いいたします。
                    </>
                  )}
                </div>

                <div className="basis-1/3">
                  {[
                    ['小計', Number(data.totalPrice).toLocaleString()],
                    [
                      '消費税10%対象 内税額',
                      Math.floor(Number(data.totalPrice) / 11).toLocaleString(),
                    ],
                    ['総合計', Number(data.totalPrice).toLocaleString()],
                  ].map(([title, value], index) => {
                    return (
                      <div key={index} className="flex justify-between mb-1">
                        <span>{title}</span>
                        <span>{value}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {[DempyouType.recycle_store, DempyouType.sell_store].includes(
                data.dempyouType
              ) && (
                <div className="mt-2">
                  <div
                    className={classNames(
                      'mb-1 w-1/2 flex justify-between',
                      styles.borderBottomDouble
                    )}
                  >
                    <span>名前</span>
                    <span>印</span>
                  </div>
                  <div className="text-[11px]">
                    <span>
                      適格請求書（インボイス）発行事業者ではありません。
                    </span>
                    <span className="ml-2">口はい</span>
                    <span className="ml-2">口いいえ</span>
                    <span className="ml-4">適格請求畫発行事業者</span>
                    <span className="ml-2">登録番号：</span>
                  </div>
                </div>
              )}
            </div>

            <div
              className="bg-black text-white text-lg px-1"
              style={{ printColorAdjust: 'exact' }}
            >
              ASU BRAND
            </div>
          </div>
        </div>
      );
    },
    [state]
  );

  return <>{state.map((d, i) => A4Render(d, i))}</>;
};

export default DempyouPrint;
