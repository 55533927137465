import image1 from 'assets/images/sop/order-create-sop/pc_cn/image1.png';
import image2 from 'assets/images/sop/order-create-sop/pc_cn/image2.png';
import image3 from 'assets/images/sop/order-create-sop/pc_cn/image3.png';
import image4 from 'assets/images/sop/order-create-sop/pc_cn/image4.png';
import image5 from 'assets/images/sop/order-create-sop/pc_cn/image5.png';
import image6 from 'assets/images/sop/order-create-sop/pc_cn/image6.png';
import image7 from 'assets/images/sop/order-create-sop/pc_cn/image7.png';
import image8 from 'assets/images/sop/order-create-sop/pc_cn/image8.png';
import image9 from 'assets/images/sop/order-create-sop/pc_cn/image9.png';
import image10 from 'assets/images/sop/order-create-sop/pc_cn/image10.png';
import image11 from 'assets/images/sop/order-create-sop/pc_cn/image11.png';
import image12 from 'assets/images/sop/order-create-sop/pc_cn/image12.png';
import image13 from 'assets/images/sop/order-create-sop/pc_cn/image13.png';
import image14 from 'assets/images/sop/order-create-sop/pc_cn/image14.png';
import image15 from 'assets/images/sop/order-create-sop/pc_cn/image15.png';
import image16 from 'assets/images/sop/order-create-sop/pc_cn/image16.png';
import image17 from 'assets/images/sop/order-create-sop/pc_cn/image17.png';
import image18 from 'assets/images/sop/order-create-sop/pc_cn/image18.png';
import image19 from 'assets/images/sop/order-create-sop/pc_cn/image19.png';
import image20 from 'assets/images/sop/order-create-sop/pc_cn/image20.png';
import image21 from 'assets/images/sop/order-create-sop/pc_cn/image21.png';
import image22 from 'assets/images/sop/order-create-sop/pc_cn/image22.png';
import image23 from 'assets/images/sop/order-create-sop/pc_cn/image23.png';
import image24 from 'assets/images/sop/order-create-sop/pc_cn/image24.png';

const zh_CN_Text = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_w30vkn8clxbd-8 {
            list-style-type: none
        }

        .lst-kix_w30vkn8clxbd-1>li:before {
            content: ""
        }

        .lst-kix_w30vkn8clxbd-2>li:before {
            content: ""
        }

        ul.lst-kix_w30vkn8clxbd-7 {
            list-style-type: none
        }

        ul.lst-kix_w30vkn8clxbd-4 {
            list-style-type: none
        }

        .lst-kix_w30vkn8clxbd-0>li:before {
            content: ""
        }

        .lst-kix_w30vkn8clxbd-3>li:before {
            content: ""
        }

        ul.lst-kix_w30vkn8clxbd-3 {
            list-style-type: none
        }

        .lst-kix_w30vkn8clxbd-4>li:before {
            content: ""
        }

        ul.lst-kix_w30vkn8clxbd-6 {
            list-style-type: none
        }

        ul.lst-kix_w30vkn8clxbd-5 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-6 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-7 {
            list-style-type: none
        }

        .lst-kix_w30vkn8clxbd-5>li:before {
            content: ""
        }

        .lst-kix_w30vkn8clxbd-6>li:before {
            content: ""
        }

        ul.lst-kix_97jl0t3cz26e-8 {
            list-style-type: none
        }

        .lst-kix_97jl0t3cz26e-4>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-6>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-3>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-7>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-0>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-2>li:before {
            content: ""
        }

        .lst-kix_97jl0t3cz26e-8>li:before {
            content: ""
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        .lst-kix_97jl0t3cz26e-1>li:before {
            content: ""
        }

        ul.lst-kix_97jl0t3cz26e-0 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-1 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-2 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-3 {
            list-style-type: none
        }

        .lst-kix_w30vkn8clxbd-7>li:before {
            content: ""
        }

        .lst-kix_w30vkn8clxbd-8>li:before {
            content: ""
        }

        ul.lst-kix_97jl0t3cz26e-4 {
            list-style-type: none
        }

        ul.lst-kix_97jl0t3cz26e-5 {
            list-style-type: none
        }

        ul.lst-kix_w30vkn8clxbd-0 {
            list-style-type: none
        }

        ul.lst-kix_w30vkn8clxbd-2 {
            list-style-type: none
        }

        ul.lst-kix_w30vkn8clxbd-1 {
            list-style-type: none
        }

        .lst-kix_97jl0t3cz26e-5>li:before {
            content: ""
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c6 {
            padding-top: 0pt;
            text-indent: 36pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: right;
            height: 11pt
        }

        .c4 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c2 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            color: #ff0000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "STFangsong";
            font-style: normal
        }

        .c21 {
            padding-top: 0pt;
            padding-bottom: 6pt;
            line-height: 1.75;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c29 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c11 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c18 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c33 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.75;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c13 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-style: normal
        }

        .c7 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-style: normal
        }

        .c12 {
            color: #434343;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-style: normal
        }

        .c5 {
            font-size: 12pt;
            font-family: "STFangsong";
            color: #ff0000;
            font-weight: 700
        }

        .c14 {
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-style: normal
        }

        .c32 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .c37 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c15 {
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c36 {
            font-weight: 700;
            font-family: "Arial"
        }

        .c35 {
            color: #000000;
            font-size: 26pt
        }

        .c17 {
            color: #000000;
            font-size: 14pt
        }

        .c10 {
            font-family: "STFangsong";
            font-weight: 700
        }

        .c3 {
            font-weight: 400;
            font-family: "STFangsong"
        }

        .c16 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .c26 {
            color: inherit;
            text-decoration: inherit
        }

        .c28 {
            color: #000000;
            font-size: 16pt
        }

        .c34 {
            padding: 0;
            margin: 0
        }

        .c27 {
            font-weight: 400;
            font-family: "Arial"
        }

        .c8 {
            font-size: 12pt
        }

        .c20 {
            height: 11pt
        }

        .c25 {
            color: #999999
        }

        .c30 {
            page-break-after: avoid
        }

        .c9 {
            color: #ff0000
        }

        .c31 {
            text-indent: 36pt
        }

        .c22 {
            font-size: 14pt
        }

        .c19 {
            background-color: #ffff00
        }

        .c23 {
            height: 16pt
        }

        .c24 {
            color: #9900ff
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c37 doc-content">
    <p class="c0 c30 title" id="h.ekowdo8wpaon"><span class="c3">如何创建</span><span class="c3 c9">销售订单</span></p>
    <p class="c6"><span class="c13 c3"></span></p>
    <p class="c11"><span class="c10 c22">应用场景说明：</span><span class="c7 c3">后台创建订单用于工作人员帮助客人创建线上订单</span></p>
    <p class="c4"><span class="c7 c3"></span></p>
    <h2 class="c2" id="h.2n57sw45oszw"><span class="c10">创建订单步骤</span></h2>
    <p class="c11"><span class="c3">1.1、打开后台：</span><span class="c32 c3"><a class="c26"
                href="https://www.google.com/url?q=https://admin-shop.ginzaxiaoma.com/%25EF%25BC%2589%25EF%25BC%258C&amp;sa=D&amp;source=editors&amp;ust=1718784064962971&amp;usg=AOvVaw3u1fniS4ZUdzD8Y2IzbLGl">https://admin-shop.ginzaxiaoma.com/</a></span><span
            class="c3">，如果无账号、无权限请联络IT部门</span></p>
    <p class="c11"><span class="c3">1.2、在侧边栏</span><span class="c10 c9">「订单-创建订单」</span><span
            class="c3">打开创建订单页面如下图</span><span class="c9 c10">「图1」</span><span class="c10">、</span><span
            class="c10 c9">「图2」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 194.67px;"><img
                alt="" src="${image18}"
                style="width: 601.70px; height: 194.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图1</span><span class="c3 c9">：侧边栏-创建订单】</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 594.65px; height: 265.00px;"><img
                alt="" src="${image19}"
                style="width: 594.65px; height: 265.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图2</span><span class="c14 c3 c9">：创建订单页面】</span></p>
    <h3 class="c2" id="h.xolgmqb30uka"><span class="c12 c3">第1步：会员选择</span></h3>
    <p class="c11"><span class="c3 c8">2.1、输入需要创建订单客人的</span><span class="c5">手机号/邮箱</span><span
            class="c3 c8">，需要确保客人已注册为会员。已经成为会员的客人，输入邮箱前缀/手机号码会在下拉框中出现客人邮箱，直接选择即可。如下图</span><span
            class="c5">「图3」</span><span class="c10 c8">、</span><span class="c5">「图4」</span><span
            class="c10 c8">、</span><span class="c5">「图5」</span><span class="c7 c10">。</span></p>
    <p class="c11"><span class="c3 c8">2.2、 会员选择好之后选择客户的</span><span class="c5">收货地址</span><span
            class="c3 c8">，如下图</span><span class="c5">「图6」</span></p>
    <p class="c11"><span class="c7 c3">2.3、特殊说明：如果遇到客户不想注册用户，不填写会员也可以提交订单。</span></p>
    <p class="c11"><span class="c3 c8">如果输入手机号码/邮箱没有查询到用户信息怎么办？可参考</span><span class="c3 c8 c32"><a class="c26"
                href="#h.7aggdzj33oqt">「其他3：创建订单时客人没注册怎么办」</a></span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 602.00px; height: 277.03px;"><img
                alt="" src="${image15}"
                style="width: 602.00px; height: 277.03px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图3</span><span class="c14 c3 c9">：会员选择模块】</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 308.00px;"><img
                alt="" src="${image12}"
                style="width: 601.70px; height: 308.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图4</span><span class="c3 c9 c14">：输入手机号码查找会员】</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 276.00px;"><img
                alt="" src="${image5}"
                style="width: 601.70px; height: 276.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图5</span><span class="c14 c3 c9">：输入邮箱查找会员】</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 317.33px;"><img
                alt="" src="${image17}"
                style="width: 601.70px; height: 317.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c3 c9">【</span><span class="c5">图6</span><span class="c14 c3 c9">：选择收货地址】</span></p>
    <p class="c0 c20"><span class="c13 c3"></span></p>
    <h3 class="c2" id="h.nxdb2475wrws"><span class="c10">第2步：商品选择</span></h3>
    <p class="c11"><span class="c7 c3">3.1、输入客人需要下单的商品商品编号查找商品，下拉框会出现商品的基本信息。包括商品编号、币种、价格、在库地、上架状态（1为上架，0为下架）。</span>
    </p>
    <p class="c11"><span class="c3 c8">3.2、假设搜索不到商品，代表商品没有被创建，可以参考文末解决方案</span><span class="c32 c3 c8"><a class="c26"
                href="#h.nakzi6b311bx">「其他2：创建订单时候商品没创建怎么办？」。</a></span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 316.00px;"><img
                alt="" src="${image8}"
                style="width: 601.70px; height: 316.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图7</span><span class="c14 c3 c9">：商品选择】</span></p>
    <p class="c11"><span class="c3 c8">3.3、商品选择好为如下</span><span class="c5">「图8」</span><span class="c7 c3">状态：</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 602.00px; height: 305.50px;"><img
                alt="" src="${image10}"
                style="width: 602.00px; height: 305.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图8</span><span class="c14 c3 c9">：选择好商品的展示示例】</span></p>
    <p class="c18 c20"><span class="c14 c3 c24"></span></p>
    <h3 class="c2" id="h.9ch3jvrdwp4v"><span class="c12 c10">第3步：支付设置</span></h3>
    <p class="c11"><span class="c3">4.1、支付设置模块，</span><span class="c10 c9">可以使用积分（日币）和优惠金额（在库地币种）</span><span
            class="c13 c3">。积分可以在上面「会员选择」的环节直接查看剩余积分。输入对应的积分和优惠金额，也可以直接输入优惠券Code。</span></p>
    <p class="c11"><span class="c13 c3">支付状态：待支付、已支付。当选择“待支付”底部才显示“多笔支付”按钮。</span></p>
    <p class="c11"><span class="c10 c19">注意：</span><span class="c3 c19">在提交订单前请</span><span
            class="c3 c19">确认清楚优惠金额、订单金额对应的币种和金额。如</span><span class="c13 c10 c19">「图9」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 289.33px;"><img
                alt="" src="${image16}"
                style="width: 601.70px; height: 289.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c5">【图9：支付设置】</span></p>
    <p class="c11"><span class="c3 c8">4.2、如需要多笔支付，可点</span><span class="c5">「设置多笔支付」</span><span
            class="c10 c8">如</span><span class="c5">「图10」</span><span class="c3 c8">创建多笔支付订单</span><span
            class="c5">「图11」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 313.33px;"><img
                alt="" src="${image11}"
                style="width: 601.70px; height: 313.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图10</span><span class="c14 c3 c9">：设置多笔支付按钮】</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 314.67px;"><img
                alt="" src="${image1}"
                style="width: 601.70px; height: 314.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图11</span><span class="c14 c3 c9">：设置多笔支付】</span></p>
    <p class="c18 c20"><span class="c14 c3 c24"></span></p>
    <p class="c11"><span class="c7 c3">可以创建2～5笔进行分开支付。2笔的情况，会用总金额减去首笔金额，分拆为2笔。</span></p>
    <p class="c11"><span class="c7 c3">3～5笔的情况，会用总金额减去首笔金额，剩余金额平均分拆为3～5笔。</span></p>
    <p class="c11"><span class="c3 c8">以下以3笔，首笔金额1000000日币进行示例，如</span><span class="c5">「图12」</span><span
            class="c10 c8">、</span><span class="c5">「图13」</span><span class="c3 c7"><br></span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 314.67px;"><img
                alt="" src="${image4}"
                style="width: 601.70px; height: 314.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图12</span><span class="c14 c3 c9">：设置多笔支付】</span></p>
    <p class="c4"><span class="c13 c3"></span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 346.67px;"><img
                alt="" src="${image3}"
                style="width: 601.70px; height: 346.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图13</span><span
            class="c14 c3 c9">：设置好多笔支付展示的多笔金额列表】</span></p>
    <p class="c4"><span class="c13 c3"></span></p>
    <h3 class="c30 c33" id="h.2ndgltcl6nv1"><span class="c12 c36">第4步：订单来源设置</span></h3>
    <p class="c29"><span class="c13 c27">如下图默认来源为网站，可手动更改</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 293.33px;"><img
                alt="" src="${image13}"
                style="width: 601.70px; height: 293.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c0"><span class="c5 c15">【图：订单来源设置】</span></p>
    <p class="c29 c20"><span class="c13 c27"></span></p>
    <p class="c20 c29"><span class="c13 c27"></span></p>
    <p class="c11"><span class="c3 c8">确认好上述信息，即可点击完成创建订单，如</span><span class="c5 c15">「图14」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 592.50px; height: 342.51px;"><img
                alt="" src="${image23}"
                style="width: 592.50px; height: 342.51px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图14</span><span class="c14 c3 c9">：二次确认是否创建订单】</span>
    </p>
    <p class="c18 c20"><span class="c14 c3 c24"></span></p>
    <p class="c11"><span class="c3 c8">提交成功后会提示</span><span class="c5">「创建订单成功」</span><span
            class="c3 c8">，同时产生2段信息。如</span><span class="c5 c15">「图15」</span></p>
    <ul class="c34 lst-kix_w30vkn8clxbd-0 start">
        <li class="c11 c16 li-bullet-0"><span class="c14 c3 c25">跳转订单详情页：营业员可以使用，用于查看客人订单详情。</span></li>
        <li class="c11 c16 li-bullet-0"><span class="c3 c25">链接部分：可以复制给客人，客人可以快速登陆网站进行支付。</span></li>
    </ul>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 348.00px;"><img
                alt="" src="${image6}"
                style="width: 601.70px; height: 348.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图15</span><span class="c14 c3 c9">：创建订单成功】</span></p>
    <h3 class="c2" id="h.9nyqrfsb3lb7"><span class="c12 c27">第5步：查看订单详情</span></h3>
    <p class="c11"><span class="c3">5.1、点击</span><span class="c10 c9">「跳转订单详情页」</span><span
            class="c3">，可以查看客人订单。包括订单的状态、操作、基础信息、收件人信息、商品信息、费用信息、多笔支付情况、积分历史、操作历史。如</span><span class="c1">「图16」</span>
    </p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 555.06px; height: 757.50px;"><img
                alt="" src="${image7}"
                style="width: 555.06px; height: 757.50px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图16</span><span class="c14 c3 c9">：二次确认是否创建订单】</span>
    </p>
    <h2 class="c21" id="h.9safa398hjv5"><span class="c15 c17 c10">其他1：客人如何支付</span></h2>
    <p class="c11"><span class="c3 c8">复制创建订单成功下面的链接，或在客人订单页面点</span><span class="c5">「复制订单链接」</span><span
            class="c3 c8">如</span><span class="c5">「图15」</span><span class="c7 c3">。</span></p>
    <p class="c11"><span class="c3 c8">发给客人，客人登陆账号可以直接进入支付页面。客人看到的页面如</span><span class="c5">「图17」</span><span
            class="c7 c3">，如果是多笔支付会进行拆分，黄色高亮位置和下方按钮是当前支付的这一笔金额</span></p>
    <p class="c4"><span class="c13 c3"></span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 297.53px; height: 513.66px;"><img
                alt="" src="${image21}"
                style="width: 297.53px; height: 513.66px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图17</span><span class="c14 c3 c9">：二次确认是否创建订单】</span>
    </p>
    <p class="c18 c20"><span class="c14 c3 c24"></span></p>
    <p class="c11"><span
            class="c3">支付页面默认选中信用卡，展示的信息为信用卡信息、订单信息（日期、编号、状态）、收货地址信息，如果客人希望更换其他支付方式，可点击下图中的“箭头”如</span><span
            class="c10 c9">「图18」</span><span class="c3">，可用的支付方式有：信用卡、paypal、alipay、银联、wechat、银行转账、虚拟币支付</span><span
            class="c14 c3 c9 c19">「注意：根据订单中商品的库存地不同支付选项会有所不同」。</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 604.00px;"><img
                alt="" src="${image20}"
                style="width: 601.70px; height: 604.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图18</span><span class="c14 c3 c9">：二次确认是否创建订单】</span>
    </p>
    <h2 class="c2 c23" id="h.rwdbfw704zf9"><span class="c15 c17 c10"></span></h2>
    <h2 class="c2 c23" id="h.aykuphw6n18i"><span class="c15 c17 c10"></span></h2>
    <h2 class="c2 c23" id="h.je4qmai5uhqd"><span class="c15 c17 c10"></span></h2>
    <h2 class="c2" id="h.nakzi6b311bx"><span class="c15 c10 c17">其他2：创建订单时候商品没创建怎么办？</span></h2>
    <p class="c11 c31"><span class="c7 c3">可在商品-商品列表页面，临时创建一个商品，之后可以联络出品负责人（张）进行信息补充。具体操作如下：</span></p>
    <p class="c11"><span class="c3 c8">2.1 打开商品列表页面</span><span class="c5 c15">「图19」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 332.00px;"><img
                alt="" src="${image2}"
                style="width: 601.70px; height: 332.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图19</span><span class="c14 c3 c9">：商品列表入口】</span></p>
    <p class="c11"><span class="c3 c8">2.2 点击</span><span class="c5">「添加」</span><span class="c3 c8">如</span><span
            class="c5 c15">「图20」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 332.00px;"><img
                alt="" src="${image22}"
                style="width: 601.70px; height: 332.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图20</span><span class="c14 c3 c9">：添加商品按钮】</span></p>
    <p class="c4"><span class="c13 c3"></span></p>
    <p class="c4"><span class="c13 c3"></span></p>
    <p class="c11"><span class="c7 c3">2.3 输入必填信息</span></p>
    <p class="c11"><span class="c7 c3">包括商品编号、分类、价格、成色。库存地和币种会自动根据编号进行选择，确认一下即可。</span></p>
    <p class="c11"><span class="c7 c3">填写完成这些就可以创建好商品。如客人需要照片可在照片群临时找一张照片上传。</span></p>
    <p class="c11"><span class="c3 c8">剩余的信息如果需要补充，可以联络出品负责人（张）进行补充。</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 601.33px;"><img
                alt="" src="${image24}"
                style="width: 601.70px; height: 601.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图21</span><span class="c3 c9">：添加商品】</span></p>
    <h2 class="c2" id="h.7aggdzj33oqt"><span class="c10 c22">其他3：创建订单时客人没注册怎么办？</span></h2>
    <p class="c11"><span class="c3">与客人沟通请客人自行在网站</span><span class="c10 c9">「https://ginzaxiaoma.com/index」</span><span
            class="c13 c3">上进行注册</span></p>
    <p class="c11"><span class="c3">若</span><span class="c13 c3">客人不会操作，后台可帮助客人创建会员</span></p>
    <p class="c11"><span class="c3">后台操作：在侧边栏找到</span><span class="c10 c9">「会员」---&gt;「注册新规会员」</span><span
            class="c3">如</span><span class="c1">「图22」</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 529.50px; height: 312.41px;"><img
                alt="" src="${image9}"
                style="width: 529.50px; height: 312.41px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图22</span><span class="c14 c3 c9">：创建会员入口】</span></p>
    <p class="c11"><span class="c3">根据</span><span class="c10 c9">「图23」</span><span class="c3">打红色“</span><span
            class="c3 c9">*</span><span class="c13 c3">”的项填写客人的注册信息，提交成功后将提示成功</span></p>
    <p class="c0"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 522.50px; height: 317.66px;"><img
                alt="" src="${image14}"
                style="width: 522.50px; height: 317.66px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""></span></p>
    <p class="c18"><span class="c3 c9">【</span><span class="c5">图23</span><span class="c3 c9">：创建会员】</span></p>
</body>

</html>`;
export default zh_CN_Text;
