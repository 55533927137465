import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CustomerSessionList } from './CustomerSessionList';
import { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import {
  insertMessage,
  resetAllSession,
  resetCurrentSession,
} from '../../store/im-chat-stores/imManagerSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useReadChatMutation } from '../../store/im-chat-stores/imManagerChatApi';

const { Title } = Typography;

const CustomerInbox = () => {
  const { t } = useTranslation();
  const pluginKey = useSelector((state) => state.imManagerSettings.pluginKey);
  const baseUrl = useSelector((state) => state.imManagerSettings.baseUrl);
  const authToken = useSelector((state) => state.imManagerSettings.authToken);
  const session = useSelector(
    (state) => state.imManagerSettings.selectedSession
  );
  const resetAllSessionList = useSelector(
    (state) => state.imManagerSettings.resetAllSessionList
  );
  const dispatch = useDispatch();
  const [readChat] = useReadChatMutation();
  const [socket, setSocket] = useState(null);

  const handleSessionRead = useCallback(() => {
    readChat({ session: session });
  }, [readChat, session]);

  const initializeSocket = useCallback(() => {
    if (socket) return;
    if (!baseUrl) return;
    if (!authToken) return;
    if (!pluginKey) return;

    const _socket = io(`${baseUrl}/im-chat`, { transports: ['websocket'] });

    const handleAuthenticated = (data) => {
      console.log('authenticated', data);
    };

    const handleError = (error) => {
      console.log('socket_connection_error', error);
    };

    const handleMessageCreated = (new_message) => {
      handleSessionRead();
      dispatch(insertMessage([new_message]));
      // dispatch(resetAllSession());
      // dispatch(resetCurrentSession());
    };

    _socket.on('authenticated', handleAuthenticated);
    _socket.on('error', (data) => handleError(data));
    _socket.on('message_created', handleMessageCreated);

    setSocket(_socket);

    _socket.emit('authentication', {
      personType: 'manager',
      token: authToken,
      imChannelPluginKey: pluginKey,
    });

    return () => {
      _socket.disconnect();
      setSocket(null);
    };
  }, [authToken, baseUrl, dispatch, handleSessionRead, pluginKey, socket]);

  useEffect(() => {
    initializeSocket();
  }, [initializeSocket]);

  return (
    <div className="flex justify-center flex-col h-[100%]">
      <Title className="!h-[8%] !p-2 !flex !items-center !m-0 !cursor-default !text-[20px]">
        {t('inbox')}
      </Title>
      <CustomerSessionList key={resetAllSessionList} />
    </div>
  );
};

export default CustomerInbox;
