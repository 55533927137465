import request from '.';
import { BaseRes } from 'types/base';
import {
  UserInfo,
  UserLoginRequestDto,
  UserLoginResponseDto,
} from 'types/user';

export const getUserInfo = () => {
  return request.get<BaseRes<UserInfo>>('/sys/user/info');
};

export function userLogin(data: UserLoginRequestDto) {
  return request.post<BaseRes<UserLoginResponseDto>>('/sys/user/login', data);
}
