import Avatar from "react-avatar"
import {ImageMessages} from "./ImageMessages.jsx"
import {AttachmentMessage} from "./AttachmentMessage.jsx"
import {TextMessage} from "./TextMessage.jsx"
import PropTypes from "prop-types"

export const MessageBody = ({message, customer}) => {
  const {personType, imMessageBlockList} = message || {}
  const {type, value, contentType, originalName, sizeString} = imMessageBlockList?.[0] || {}

  return (
    <div className={`flex items-end ${personType === "user" ? "flex-row" : "flex-row-reverse"}`}>
      {
        personType === "user" && (
          <Avatar
            name={customer.userName || 'Unknown User'}
            round={true}
            size={32}
            className="w-10 h-10 rounded-full mr-2"/>
        )
      }
      {
        type === 'text' && (
          <TextMessage personType={personType} value={value} />
        )
      }
      {
        type === 'file' && contentType?.[0] === 'a' && (
          <AttachmentMessage
            imMessageBlockList={imMessageBlockList}
            personType={personType}
            originalName={originalName}
            sizeString={sizeString}
          />
        )
      }
      {
        type === 'file' && contentType?.[0] === 'i' && (
          <ImageMessages
            personType={personType}
            imMessageBlockList={imMessageBlockList}
          />
        )
      }
    </div>
  )
}

MessageBody.propTypes = {
  message: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
}
