import { Card, Divider, Typography } from 'antd';
import { useGetCustomerDetailsQuery } from "../../store/im-chat-stores/imManagerChatApi"
import { useSelector } from "react-redux"
import { SkeletonLoader } from "./loader/SkeletonLoader.jsx"
import {useTranslation} from "react-i18next"
import { CustomerTags } from './CustomerTags';
const { Text, Title } = Typography

export const CustomerDetails = () => {
  const session = useSelector(state => state.imManagerSettings.selectedSession)
  const { data: customerDetails, isFetching } = useGetCustomerDetailsQuery({
    session: session
  }, {
    skip: !session?.id
  })

  const {t} = useTranslation()

  return (
    <>
      {isFetching ? (
        <SkeletonLoader />
      ) : (
        <Card
          className="bg-[#EBEDF7] font-bold h-[100%] "
        >
          <Title className="!mt-2 !mb-8 !flex !items-center !cursor-default !text-[20px]">
            {t('customer_details')}
          </Title>

          <div className="flex flex-wrap !h-[90%]">
            <div className="flex w-full">
              <Text className="w-1/5 text-[12px] text-grey-50 font-medium leading-[18px] cursor-default">{t('email')}</Text>
              <Text className="w-4/5 text-black text-right text-[12px]">{customerDetails?.email}</Text>
            </div>
            <Divider className="my-4" />

            <div className="flex w-full">
              <Text className="w-1/5 text-[12px] text-grey-50 font-medium leading-[18px] cursor-default">Tags</Text>
              <CustomerTags customerDetails = {customerDetails} />
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 font-medium leading-[18px] cursor-default">{t('first_name')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.firstName}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('last_name')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.lastName}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('gender')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.gender === 1 ? t('male') : t('female')}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('phone')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.phone}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('country_code')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.countryCode}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('country')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.country}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('city')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.city}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('birthday')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.birthday}</Text>
            </div>
            <Divider className="my-4" />
            <div className="flex w-full">
              <Text className="w-2/5 text-[12px] text-grey-50 leading-[18px] cursor-default">{t('detail_address')}</Text>
              <Text className="w-3/5 text-black text-right text-[12px]">{customerDetails?.detailAddress}</Text>
            </div>
            <Divider className="my-4" />
          </div>
        </Card>
      )}
    </>
  )
}
