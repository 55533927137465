import PropTypes from "prop-types";

export const TextMessage = ({ personType, value }) => {
  return (
    <div
      className={`whitespace-pre-line max-w-[70%] break-words p-3 rounded-lg ${personType === "user" ? "bg-gray-100 text-black" : "bg-blue-500 text-white"}`}>
      <p className="whitespace-pre-wrap text-sm mb-0">{value}</p>
    </div>
  )
}

TextMessage.propTypes = {
  personType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}