import image1 from 'assets/images/sop/checkout_counter_sop/pc_cn/image1.png';
import image2 from 'assets/images/sop/checkout_counter_sop/pc_cn/image2.png';
import image3 from 'assets/images/sop/checkout_counter_sop/pc_cn/image3.png';
import image4 from 'assets/images/sop/checkout_counter_sop/pc_cn/image4.png';
import image5 from 'assets/images/sop/checkout_counter_sop/pc_cn/image5.png';
import image6 from 'assets/images/sop/checkout_counter_sop/pc_cn/image6.png';
import image7 from 'assets/images/sop/checkout_counter_sop/pc_cn/image7.png';
import image8 from 'assets/images/sop/checkout_counter_sop/pc_cn/image8.png';
import image9 from 'assets/images/sop/checkout_counter_sop/pc_cn/image9.png';
import image10 from 'assets/images/sop/checkout_counter_sop/pc_cn/image10.png';
import image11 from 'assets/images/sop/checkout_counter_sop/pc_cn/image11.png';
import image12 from 'assets/images/sop/checkout_counter_sop/pc_cn/image12.png';
import image13 from 'assets/images/sop/checkout_counter_sop/pc_cn/image13.png';
import image14 from 'assets/images/sop/checkout_counter_sop/pc_cn/image14.png';
import image15 from 'assets/images/sop/checkout_counter_sop/pc_cn/image15.png';
import image16 from 'assets/images/sop/checkout_counter_sop/pc_cn/image16.png';
import { useRef, useEffect, useState, useMemo } from 'react';
import { Drawer } from 'antd';
import zh_CN_Text from './i18-pc/zh-CN';
import ja_Text from './i18-pc/ja';

import { LANGUAGE_MAP } from 'commons/options';
import { getLocalStorageLanguage } from 'commons';

const CheckoutCounterSop = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const language = getLocalStorageLanguage();

  const text = useMemo(() => {
    if (language === LANGUAGE_MAP.ZH_CN) {
      return zh_CN_Text;
    } else if (language === LANGUAGE_MAP.JA) {
      return ja_Text;
    }
    return '';
  }, [language]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'IMG') {
        setUrl((target as HTMLImageElement).src);
        setIsModalOpen(true);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleClick);
    }

    // 清理事件监听器
    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div>
      <div
        className="m-auto w-[21cm]"
        ref={containerRef}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></div>

      <Drawer
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={'100%'}
      >
        <div className="w-full h-full">
          <img src={url} />
        </div>
      </Drawer>
    </div>
  );
};

export default CheckoutCounterSop;
