export const handleDownload = async (messageList) => {
  messageList?.forEach(message => {
    const link = document.createElement('a')
    link.href = message.url
    link.download = message.originalName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}
