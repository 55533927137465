import { Collapse } from "antd"
import {useEffect} from "react"
import ProfileHeader from "../../components/im-chat-admin-components/ProfileHeader.jsx"
import GreetingsHeader from "../../components/im-chat-admin-components//GreetingsHeader"
import OfflineResponseHeader from "../../components/im-chat-admin-components//OfflineResponseHeader"
import BusinessHourHeader from "../../components/im-chat-admin-components//BusinessHourHeader.jsx"
import {ManagerProfile} from "../../components/im-chat-admin-components//ManagerProfile.jsx"
import {ManageGreeting} from "../../components/im-chat-admin-components//ManageGreeting.jsx"
import ManageOfflineMessage from "../../components/im-chat-admin-components//ManageOfflineMessage.jsx"
import PropTypes from "prop-types"
import BusinessHour from "../../components/im-chat-admin-components/BusinessHour.jsx";
import {
  resetAuthToken,
  resetBaseUrl,
  resetLocale,
  resetPluginKey,
  storeAuthToken,
  storeBaseUrl,
  storeLocale,
  storePluginKey
} from '../../store/im-chat-stores/imManagerSettingsSlice';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';

const IMChatSettings = ({authToken, pluginKey, baseUrl, locale}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAuthToken())
    dispatch(resetBaseUrl())
    dispatch(resetLocale())
    dispatch(resetPluginKey())

    if(authToken) {
      dispatch(storeBaseUrl(baseUrl))
      dispatch(storeAuthToken(authToken))
      dispatch(storePluginKey(pluginKey))
      dispatch(storeLocale(locale))
    }

    // i18n.changeLanguage(locale)
  }, [baseUrl, locale, pluginKey, authToken])

  return (
    <>
      <div className="-m-4 w-1/2 overflow-auto" style={{height: 'calc(100vh - 74px)'}}>
        <Collapse
          ghost
          accordion
          className="flex flex-col"
          destroyInactivePanel={true}
        >
          <Collapse.Panel
            className="bg-[#EBEDF7] m-4 rounded-xl"
            header={<ProfileHeader/>}
            showArrow={false}
            key="1">
            <ManagerProfile/>
          </Collapse.Panel>
          <Collapse.Panel
            className="bg-[#EBEDF7] m-4 rounded-xl"
            header={<GreetingsHeader/>}
            showArrow={false}
            key="2">
            <ManageGreeting/>
          </Collapse.Panel>

          <Collapse.Panel
            className="bg-[#EBEDF7] m-4 rounded-xl"
            header={<OfflineResponseHeader/>}
            showArrow={false}
            key="3"
          >
            <ManageOfflineMessage/>
          </Collapse.Panel>

          <Collapse.Panel
            className="bg-[#EBEDF7] m-4"
            style={{borderRadius: '12px'}}
            header={<BusinessHourHeader/>} showArrow={false}
            key="4"
          >
            <BusinessHour/>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  )
}

export default IMChatSettings

IMChatSettings.propTypes = {
  authToken: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  pluginKey: PropTypes.string.isRequired,
}
