import { OmsOrderDetail } from 'types/oms';
import { Trans } from 'react-i18next';
import LOCALS from 'commons/locals';
import { Descriptions } from 'antd';
import styles from './index.module.scss';

type OmsOrderPaymentInfoProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OmsOrderPaymentInfo = ({
  omsOrderDetail: {
    totalAmount,
    payAmount,
    integrationAmount,
    promotionAmount,
    promotionAmountActualCurrency,
    couponAmount,
    totalAmountActualCurrency,
    payAmountActualCurrency,
    freightAmountActualCurrency,
    orderItemList: [{ actualCurrency }],
  },
}: OmsOrderPaymentInfoProps) => {
  return (
    <div className="mb-3">
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.payment_info} />
      </div>
      <Descriptions bordered size="small">
        <Descriptions.Item
          label={<Trans i18nKey={LOCALS.order_total_amount} />}
        >
          {actualCurrency && totalAmountActualCurrency !== null
            ? `${actualCurrency} ${totalAmountActualCurrency.toLocaleString()}`
            : `JPY ${totalAmount.toLocaleString()}`}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.amount_to_be_paid} />}>
          {actualCurrency && payAmountActualCurrency !== null
            ? `${actualCurrency} ${payAmountActualCurrency.toLocaleString()}`
            : `JPY ${payAmount.toLocaleString()}`}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.points_deduction} />}>
          -JPY {integrationAmount.toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.coupon} />}>
          -JPY {couponAmount}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.discount_amounts} />}>
          {actualCurrency &&
          promotionAmountActualCurrency !== null &&
          promotionAmountActualCurrency !== undefined
            ? `${actualCurrency} ${promotionAmountActualCurrency.toLocaleString()}`
            : `-JPY ${promotionAmount.toLocaleString()}`}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.freight} />}>
          {actualCurrency} {freightAmountActualCurrency}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OmsOrderPaymentInfo;
