import { useTranslation } from "react-i18next"
import { Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import BusinessHourModal from "./modals/BusinessHourModal.jsx";
import HolidayModal from "./modals/HolidayModal.jsx";
import { useEffect, useState } from "react";
import {useCreateProfileMutation, useGetProfileInfoQuery} from "../../store/im-chat-stores/imManagerChatApi.js";
import BusinessHoursSkeletonLoader from "./loader/BusinessHoursSkeletonLoader.jsx";
import { useSelector } from 'react-redux';

const BusinessHour = () => {
  const { t } = useTranslation()
  const pluginKey = useSelector(state => state.imManagerSettings.pluginKey);
  const { data: profileData, isLoading: profileLoading } = useGetProfileInfoQuery({pluginKey: pluginKey});
  const [updateManagerProfile] = useCreateProfileMutation();
  const [businessHourModal, setBusinessHourModal] = useState(false)
  const [holidayModal, setHolidayModal] = useState(false);
  const [imChannelProfile, setImChannelProfile] = useState(null);
  const [businessHours, setBusinessHours] = useState([]);
  const [holidays, setHolidays] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  useEffect(() => {
    if (profileData) {
      setImChannelProfile(profileData);
      setBusinessHours(profileData.businessHours || []);
      setHolidays(profileData.holidays || {});
      setOriginalData({
        businessHours: profileData.businessHours || [],
        holidays: profileData.holidays || {}
      });
    }
  }, [profileData]);

  const addBusinessHour = (newBusinessHour) => {
    setBusinessHours(prevHours => {
      const updatedHours = [...prevHours, newBusinessHour];
      setIsEditing(true);
      return updatedHours;
    });
    setBusinessHourModal(false);
  };

  const deleteBusinessHour = (index) => {
    setBusinessHours(prevHours => {
      const updatedHours = prevHours.filter((_, i) => i !== index);
      setIsEditing(true);
      return updatedHours;
    });
  };

  const addHolidays = (newHolidays) => {
    setHolidays({ start_time: newHolidays.start_time?.format("YYYY-MM-DD"), end_time: newHolidays.end_time?.format("YYYY-MM-DD") });
    setIsEditing(true);
    setHolidayModal(false);
  };

  const deleteHolidays = () => {
    setHolidays({});
    setIsEditing(true);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateManagerProfile({
        pluginKey: pluginKey,
        data: {
          ...imChannelProfile,
          businessHours: businessHours,
          holidays: holidays
        }
      });
      setIsEditing(false);
      setOriginalData({ businessHours, holidays });
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetState = () => {
    setBusinessHours(originalData.businessHours);
    setHolidays(originalData.holidays);
    setIsEditing(false);
  };

  if (profileLoading) {
    return <BusinessHoursSkeletonLoader/>;
  }

  return (
    <>
      <div className="px-3">
        <div className="mt-4 flex justify-between items-center">
          <div className="">
            <p className="text-lg font-semibold">{t('set_business_hour')}</p>
            <p className="text-xs">{t('at_least_one_business_hour')}</p>
          </div>
          <Button
            onClick={() => setBusinessHourModal(true)}
            icon={<PlusOutlined/>}
            className="w-[164px] h-[42px] bg-transparent"
          >
            {t('add_day_time')}
          </Button>
        </div>
        <div className="w-[316px] mt-3">
          <div className="space-y-3">
            {businessHours.map((businessHour, index) => (
              <div key={index}
                   className="bg-[#EBEDF7] border-solid border border-[#DADDEB] rounded-lg p-3 flex justify-between items-center">
                <div>
                  <p className="font-medium">{businessHour?.working_days.join(", ")}</p>
                  <p className="text-gray-600">{`${businessHour.start_time} - ${businessHour.end_time}`}</p>
                </div>
                <Button
                  type="text"
                  icon={<DeleteOutlined className="text-red-500" />}
                  className="text-gray-500 hover:text-red-500"
                  onClick={() => deleteBusinessHour(index)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="">
            <p className="text-lg font-semibold">{t('set_holiday')}</p>
            <p className="text-xs">
              {t('changes_not_possible_today')}
            </p>
          </div>
          <Button
            icon={<PlusOutlined/>}
            onClick={() => setHolidayModal(true)}
            className="w-[164px] h-[42px] bg-transparent"
          >
            {t('add_holiday')}
          </Button>
        </div>

        <div className="w-[316px] mt-3">
          <div className="space-y-3">
            {holidays && Object.keys(holidays).length > 0 && (
              <div className="w-[316px] mt-3">
                <div className="space-y-3">
                  <div className="bg-[#EBEDF7] border-solid border border-[#DADDEB] rounded-lg p-3 flex justify-between items-center">
                    <div>
                      <p className="font-medium">{`${holidays.start_time} - ${holidays.end_time}`}</p>
                    </div>
                    <Button
                      type="text"
                      icon={<DeleteOutlined className="text-red-500"/>}
                      className="text-gray-500 hover:text-red-500"
                      onClick={() => deleteHolidays()}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-3 flex gap-2">
          <Button
            type="primary"
            disabled={!isEditing}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {t('save')}
          </Button>
          <Button
            onClick={resetState}
            disabled={!isEditing}
          >
            {t('cancel')}
          </Button>
        </div>

        <BusinessHourModal
          timeZone={imChannelProfile?.timeZone}
          visible={businessHourModal}
          onCancel={() => setBusinessHourModal(false)}
          onConfirm={addBusinessHour}
        />
        <HolidayModal
          visible={holidayModal}
          onConfirm={addHolidays}
          onCancel={() => setHolidayModal(false)}
        />
      </div>
    </>
  )
}

export default BusinessHour
