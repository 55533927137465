import OrderCreateSopMOB from './mob';
import OrderCreateSopPC from './pc';
import { useState } from 'react';
import { Radio } from 'antd';
const CheckoutCounterSop = () => {
  const [mode, setMode] = useState('pc');

  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center mb-4">
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => setMode(e.target.value)}
          value={mode}
        >
          <Radio value={'pc'} className="w-[200px] text-center">
            PC
          </Radio>
          <Radio value={'mob'} className="w-[200px] text-center">
            MOB
          </Radio>
        </Radio.Group>
      </div>
      {mode === 'pc' ? <OrderCreateSopPC /> : <OrderCreateSopMOB />}
    </div>
  );
};

export default CheckoutCounterSop;
